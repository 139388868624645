import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsCrud from '../../../Utils/ClsCrud'

import { StatusForm } from '../../../Utils/ClsCrud'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import { UnidadeDeterminacaoConversaoInterface, UnidadeDeterminacaoInterface } from '../../../ImportBackend/Interfaces/UnidadeDeterminacaoInterfaces'
import DataTableCrudJSON from '../../../DevComponents/DataTableCrudJSON'
import UnidadeDeterminacaoConversaoForm, { calcularExemplo } from './UnidadeDeterminacaoConversaoForm'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { UnidadeDeterminacaoPosCalculoType, UnidadeDeterminacaoTipoInformacaoType, UnidadeDeterminacaoTipoInformacaoTypes, VALIDACAO_MASCARA } from '../../../ImportBackend/types/ConstantesDataTypes'
import ComboBox from '../../../DevComponents/ComboBox'
import JoditEditor from 'jodit-react'
import { CONFIG_EDITOR_UNIDADE_DESCRICAO } from './MateriaisDeterminacoesForm'

interface PesquisaInterface {
  descricao: string
}

export default function UnidadesDeterminacao () {

  const ResetDadosUnidadeDeterminacaoConversao: UnidadeDeterminacaoConversaoInterface = {
    calculo: '',
    unidade: '',
    tipoInformacao: UnidadeDeterminacaoTipoInformacaoType.NUMERO,
    casasDecimais: 2,
    posCalculo: UnidadeDeterminacaoPosCalculoType.ARREDONDAR,
    descricaoLaudo: ''
  }

  const CabecalhoUnidadeDeterminacaoConversao: Array<DataTableCabecalhoInterface> = [
    { cabecalho: 'Unidade', campo: 'unidade' },
    // { cabecalho: 'Laudo', campo: 'descricaoLaudo' },
    {
      campo: 'tipoInformacao',
      cabecalho: 'Tipo',
      alinhamento: 'left',
      format: ( rs: string ) => UnidadeDeterminacaoTipoInformacaoTypes.find( v => v.idUnidadeDeterminacaoTipoInformacao === rs )?.descricao
    },
    { cabecalho: 'Cálculo', campo: 'calculo' },
    {
      campo: 'casasDecimais',
      cabecalho: 'Casas Decimais',
      alinhamento: 'right'
    },
    {
      campo: 'posCalculo',
      cabecalho: 'Pós Cálculo',
      alinhamento: 'left',
      format: ( v: string ) => v === UnidadeDeterminacaoPosCalculoType.ARREDONDAR ? 'Arredondar' : 'Truncar'
    },
    {
      cabecalho: 'Exemplo (100)',
      campo: 'calculo',
      format: ( _v: string, rs: UnidadeDeterminacaoConversaoInterface ) => calcularExemplo( rs, 100 )

    }
  ]

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'unidade',
      cabecalho: 'Unidade',
      alinhamento: 'left'
    },
    { cabecalho: 'Laudo', campo: 'descricaoLaudo' },
    {
      campo: 'tipoInformacao',
      cabecalho: 'Tipo',
      alinhamento: 'left',
      // format: ( rs: string ) => UnidadeDeterminacaoTipoInformacaoTypes[UnidadeDeterminacaoTipoInformacaoTypes.findIndex( ( v: any ) => rs === v )].descricao
    },
    {
      campo: 'casasDecimais',
      cabecalho: 'Casas Decimais',
      alinhamento: 'right'
    }
  ]

  const ResetDados: UnidadeDeterminacaoInterface = {
    idUnidadeDeterminacao: 0,
    unidade: '',
    casasDecimais: 0,
    tipoInformacao: UnidadeDeterminacaoTipoInformacaoType.NUMERO,
    conversoes: [],
    descricaoLaudo: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Unidade',
    [StatusForm.Excluindo]: 'Exclusão de Unidade Não Utilizada',
    [StatusForm.Pesquisando]: 'Unidade de Medidas são utilizadas para as Determinações',
    [StatusForm.Editando]: 'Alteração de Dados de Unidade',
    [StatusForm.Exibindo]: 'Dados de Unidade'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<UnidadeDeterminacaoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<UnidadeDeterminacaoInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'tipoInformacao', dados, erros, retorno )

    if ( retorno && dados.tipoInformacao === UnidadeDeterminacaoTipoInformacaoType.NUMERO ) {
      retorno = clsValidacao.naoVazio( 'casasDecimais', dados, erros, retorno )
    }

    retorno = clsValidacao.naoVazio( 'unidade', dados, erros, retorno )

    // retorno = clsValidacao.validarComRegEx( VALIDACAO_MASCARA, 'mascara', dados, erros, retorno, false, 'Expressão inválida!', 'Forneça uma expressão!' )

    setErros( erros )

    return retorno
  }

  const clsCrud: ClsCrud<UnidadeDeterminacaoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateUnidadeDeterminacao',
      excluirMutation: 'delUnidadeDeterminacao',
      campoId: 'idUnidadeDeterminacao',
      camposPesquisa: '{idUnidadeDeterminacao unidade casasDecimais tipoInformacao descricaoLaudo}',
      pesquisaQuery: 'getUnidadesDeterminacao',
      pesquisaPorId: 'getUnidadeDeterminacaoPorId',
      camposPesquisaPorId: '{idUnidadeDeterminacao unidade casasDecimais tipoInformacao descricaoLaudo conversoes {unidade calculo casasDecimais tipoInformacao descricaoLaudo posCalculo}}'
    },
    {
      confirmando: 'Atualizando Unidade',
      erroCadastro: 'Erro ao Cadastrar Unidade',
      erroExclusao: 'Erro ao Excluir Unidade',
      erroPesquisa: 'Erro ao Pesquisar Unidade',
      pesquisando: 'Pesquisando Dados de Unidades...',
      sucessoCadastro: 'Unidade Cadastrada com sucesso!',
      atualizacaoSucesso: 'Unidade Atualizada com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Unidade Excluída com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Unidade...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const onChangeUnidadeDeterminacaoConversao = ( conversoes: Array<UnidadeDeterminacaoConversaoInterface> ) => {
    setDados( { ...dados, conversoes: conversoes } )
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Unidades de Medidas de Determinações
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>
              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='unidade'
                  label='Unidade'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={25}
                />

              </Grid>

                {/*
              <Grid item xs={12}>

                <Typography variant="body2" sx={{ mt: 3 }} gutterBottom>
                  Símbolo para Laudo
                </Typography>

                <JoditEditor
                  value={dados.descricaoLaudo}
                  onBlur={( rs ) => { setDados( { ...dados, descricaoLaudo: rs } ) }}
                  config={CONFIG_EDITOR_UNIDADE_DESCRICAO}
                />

              </Grid>
              */}

              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricaoLaudo'
                  label='Laudo (impressão)'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={255}
                />

              </Grid>

              <Grid item xs={6}>

                <ComboBox
                  campoDescricao='descricao'
                  campoID='idUnidadeDeterminacaoTipoInformacao'
                  opcoes={UnidadeDeterminacaoTipoInformacaoTypes}
                  dados={dados}
                  field='tipoInformacao'
                  label='Tipo da Informação'
                  setState={setDados}
                  onChange={( v: any ) => setDados( { ...dados, tipoInformacao: v.idUnidadeDeterminacaoTipoInformacao } )}
                  disabled={statusForm === StatusForm.Excluindo}
                />

              </Grid>

              <Grid item xs={6} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='casasDecimais'
                  label='Casas Decimais'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo || dados.tipoInformacao !== UnidadeDeterminacaoTipoInformacaoType.NUMERO}
                  erros={erros}
                  tipo='number'
                  maxLength={2}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <DataTableCrudJSON<UnidadeDeterminacaoConversaoInterface>
                  dadosIniciaisRegistro={ResetDadosUnidadeDeterminacaoConversao}
                  cabecalho={CabecalhoUnidadeDeterminacaoConversao}
                  dados={dados.conversoes}
                  ComponenteInputCrud={UnidadeDeterminacaoConversaoForm}
                  tituloCrud='Conversões'
                  onChange={onChangeUnidadeDeterminacaoConversao}
                /*
                acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: ( _rs: ClienteContatoInterface, index: number ) => btExcluirClienteContato( index ) },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: ( _rs: ClienteContatoInterface, index: number ) => btAlterarClienteContato( index ) }
                ]}
                */
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <ExibirJSONDev exibir={EMDESENVOLVIMENTO} oque={['Dados: ', dados, 'Erros: ', erros]} />

          </Grid>
        </Paper>

      </Container>
    </>
  )
}