import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { TabelaPrecoAnaliseInterface, TabelaPrecoDeterminacaoEspecialInterface, TabelaPrecoDeterminacaoMaterialInterface, TabelaPrecoInterface } from '../../../ImportBackend/Interfaces/TabelaPrecoInterfaces'
import ClsCrud, { StatusForm } from '../../../Utils/ClsCrud'

import ClsValidacao from '../../../Utils/ClsValidacao'
import TabelaPrecosAnalises from './TabelaPrecosAnalises'
import TabelaPrecosDeterminacoesMateriais from './TabelaPrecosDeterminacoesMateriais'
import TabelaPrecosDeterminacoesEspeciais from './TabelaPrecosDeterminacoesEspeciais'

interface PesquisaInterface {
  descricao: string
}

export default function TabelaPrecos () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'ativa',
      cabecalho: 'Ativa',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    }
  ]

  const ResetDados: TabelaPrecoInterface = {
    idTabelaPreco: 0,
    descricao: '',
    ativa: true,
    Analises: [],
    DeterminacoesEspeciais: [],
    DeterminacoesMateriais: []
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Tabela de Preços',
    [StatusForm.Excluindo]: 'Exclusão de Tabela de Preços Não Utilizada',
    [StatusForm.Pesquisando]: 'Tabelas são utilizadas para precificação',
    [StatusForm.Editando]: 'Alteração de Dados de Tabelas de Preços',
    [StatusForm.Exibindo]: 'Dados da Tabela de Preços'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<TabelaPrecoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<TabelaPrecoInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )

    setErros( erros )

    return retorno
  }

  // Final Edicao Tabela de Preços Determinacao

  const clsCrud: ClsCrud<TabelaPrecoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateTabelaPreco',
      excluirMutation: 'delTabelaPreco',
      campoId: 'idTabelaPreco',
      camposPesquisa: '{idTabelaPreco descricao ativa}',
      pesquisaQuery: 'getTabelaPrecos',
      pesquisaPorId: 'getTabelaPrecoPorId',
      camposPesquisaPorId: '{idTabelaPreco descricao ativa DeterminacoesEspeciais { idDeterminacao valor idTabelaPreco } Analises { idAnalise valor idTabelaPreco } DeterminacoesMateriais { idMaterial valorIndividual valorAdicional idTabelaPreco valorAdicionalSemFormula } }'
    },
    {
      confirmando: 'Atualizando Tabela de Preços',
      erroCadastro: 'Erro ao Cadastrar Tabela de Preços',
      erroExclusao: 'Erro ao Excluir Tabela de Preços',
      erroPesquisa: 'Erro ao Pesquisar Tabela de Preços',
      pesquisando: 'Pesquisando Dados de Tabelas de Preços...',
      sucessoCadastro: 'Tabela de Preços cadastrada com sucesso!',
      atualizacaoSucesso: 'Tabela de Preços atualizada com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Tabela de Preços excluída com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Tabela de Preços...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Tabela de Preços
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>
              <Grid item xs={12} sm={8}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <TabelaPrecosAnalises
                  idTabelaPreco={dados.idTabelaPreco}
                  statusForm={statusForm}
                  onChange={( Analises: Array<TabelaPrecoAnaliseInterface> ) => setDados( { ...dados, Analises: Analises } )}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <TabelaPrecosDeterminacoesMateriais
                  idTabelaPreco={dados.idTabelaPreco}
                  statusForm={statusForm}
                  onChange={( TabelaPrecosDeterminacoesMateriais: Array<TabelaPrecoDeterminacaoMaterialInterface> ) => setDados( { ...dados, DeterminacoesMateriais: TabelaPrecosDeterminacoesMateriais } )}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <TabelaPrecosDeterminacoesEspeciais
                  idTabelaPreco={dados.idTabelaPreco}
                  statusForm={statusForm}
                  onChange={( Determinacoes: Array<TabelaPrecoDeterminacaoEspecialInterface> ) => setDados( { ...dados, DeterminacoesEspeciais: Determinacoes } )}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable
                  dados={rsPesquisa}
                  cabecalho={Cabecalho}
                  acoes={[
                    { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                    { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }
                  ]} />
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
      {/*
      <br />
      <h3>Dados Da Tabela de Preços...</h3>
      {JSON.stringify( dados )}
    */}
    </>
  )
}