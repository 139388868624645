import { DeterminacaoInterface } from "../../../ImportBackend/Interfaces/DeterminacaoInterfaces";
import { LeituraInterface } from "../../../ImportBackend/Interfaces/LeituraInterfaces";
import { UnidadeDeterminacaoInterface } from "../../../ImportBackend/Interfaces/UnidadeDeterminacaoInterfaces";
import { TipoInformacaoDeterminacaoType, UnidadeDeterminacaoPosCalculoType, UnidadeDeterminacaoTipoInformacaoType } from "../../../ImportBackend/types/ConstantesDataTypes";
import ClsFormatacao from "../../../Utils/ClsFormatacao";

export default class ClsCalcularResultado {

  private clsFormatacao: ClsFormatacao = new ClsFormatacao()

  public calcular (
    rsLeitura: Record<string, string | number | boolean>,
    rsParametros: Record<string, number>,
    rsDeterminacao: DeterminacaoInterface,
    rsUnidadeDeterminacao: UnidadeDeterminacaoInterface
  ): { digitacao: Record<string, number | boolean | string>, calculoExecutadoComSucesso: boolean } {

    let podeCalcular: boolean = true
    let rsVariaveis: Record<string, string | number | boolean> = {}

    const clsFormatacao: ClsFormatacao = new ClsFormatacao()

    for ( const [key, value] of Object.entries( rsParametros ) ) {
      rsLeitura[key] = value
      rsVariaveis[key] = value
    }

    rsDeterminacao.calculo.forEach( rsCalculo => {

      if ( rsCalculo.tipoInformacao !== TipoInformacaoDeterminacaoType.CALCULO && typeof rsLeitura[rsCalculo.variavel] === 'undefined' ) {
        podeCalcular = false
      }

      if ( typeof rsLeitura[rsCalculo.variavel] !== 'undefined' )
        rsVariaveis[rsCalculo.variavel] = rsLeitura[rsCalculo.variavel]

    } )


    if ( podeCalcular ) {

      rsDeterminacao.calculo.forEach( rsCalculo => {

        if ( rsCalculo.tipoInformacao === TipoInformacaoDeterminacaoType.CALCULO ) {

          const expressao: string = this.substituirVariaveisNoCalculo( rsCalculo.conteudo, rsVariaveis )

          try {


            // eslint-disable-next-line no-eval
            let resultado = eval( expressao )

            // console.log( 'Resultado: ', resultado )

            if ( typeof resultado === 'number' && resultado !== Infinity && !isNaN( resultado ) ) {
              rsLeitura[rsCalculo.variavel] = clsFormatacao.currency( resultado, rsUnidadeDeterminacao.casasDecimais )
            } else {
              rsLeitura[rsCalculo.variavel] = 0
              podeCalcular = false
            }

          } catch ( e: any ) {

            console.log( 'Erro no Cálculo: ', expressao, e.message )

            console.log( 'DEBUG Cálculo: ' )
            console.log( 'leitura:', JSON.stringify( rsLeitura, null, 4 ) )
            console.log( 'Parâmetros:', JSON.stringify( rsParametros, null, 4 ) )
            console.log( 'Determinacao:', JSON.stringify( rsDeterminacao, null, 4 ) )
            console.log( 'Unidade Determinacao:', JSON.stringify( rsUnidadeDeterminacao, null, 4 ) )

            // Seta Erro no Cálculo....
            podeCalcular = false
            rsLeitura[rsCalculo.variavel] = 0
          }

        }

      } )

    }

    return { digitacao: rsLeitura, calculoExecutadoComSucesso: podeCalcular }

  }

  private substituirVariaveisNoCalculo ( calculo: string, rsVariaveis: Record<string, string | number | boolean> ): string {

    for ( const [key, value] of Object.entries( rsVariaveis ) ) {

      let valor: string = ''

      if ( typeof value === 'number' || ( typeof value === 'string' && !Number.isNaN( parseFloat( value ) ) ) ) {
        valor = '('.concat( value.toString() ).concat( ')' )
      }

      calculo = calculo.replaceAll( '[['.concat( key, ']]' ), valor )

    }

    return calculo

  }

  public converterResultadoUnidade (
    leitura: LeituraInterface,
    rsUnidadeDeterminacao: UnidadeDeterminacaoInterface
  ): { resultado: string, unidadeResultado: string, conversaoRealizadaComSucesso: boolean } {

    // console.log( JSON.stringify( leitura, null, 4 ), JSON.stringify( rsUnidadeDeterminacao, null, 4 ) )

    let { casasDecimais, tipoInformacao, unidade } = { ...rsUnidadeDeterminacao }
    let calculo: string = ''
    let conversaoRealizadaComSucesso: boolean = typeof leitura !== 'undefined' && typeof leitura.digitacao !== 'undefined' && typeof leitura.digitacao.RESULTADO !== 'undefined'
    let resultado: string = conversaoRealizadaComSucesso ? leitura.digitacao.RESULTADO.toString() : ''

    resultado = this.clsFormatacao.nBRtoUS( resultado ).toString()

    // console.log('resultado pos fixed: ',resultado)

    const indiceConversao: number = rsUnidadeDeterminacao.conversoes.findIndex( rsConversao =>
      rsConversao.unidade === leitura.unidadeResultado
    )

    if ( conversaoRealizadaComSucesso ) {

      if ( indiceConversao >= 0 ) {
        casasDecimais = rsUnidadeDeterminacao.conversoes[indiceConversao].casasDecimais
        tipoInformacao = rsUnidadeDeterminacao.conversoes[indiceConversao].tipoInformacao
        unidade = rsUnidadeDeterminacao.conversoes[indiceConversao].unidade
        calculo = rsUnidadeDeterminacao.conversoes[indiceConversao].calculo
      }

      if ( calculo.length > 0 ) {

        // console.log( '\n'.repeat( 5 ), 'Dentro do Converter Resultado Unidade....' )
        
        const formula: string = this.substituirVariaveisNoCalculo( calculo, { RESULTADO: resultado } )

        try {

          // eslint-disable-next-line no-eval
          let tmpResultado = eval( formula ) as number

          // console.log( 'tmpResultado', tmpResultado )

          if ( indiceConversao >= 0 ) {
            tmpResultado = this.ArredondarOuTruncar( tmpResultado, rsUnidadeDeterminacao.conversoes[indiceConversao].posCalculo, casasDecimais )
          }

          // console.log( 'tmpResultado', tmpResultado )

          resultado = tmpResultado.toString()
          // console.log( 'resultado', resultado )

        } catch ( e ) {

          console.log( 'Resultado', resultado )
          console.log( 'Erro na Fórmula: ', formula )
          console.log( 'Erro: ', e )

          conversaoRealizadaComSucesso = false

        }

      }

      if ( tipoInformacao === UnidadeDeterminacaoTipoInformacaoType.NUMERO ) {
        resultado = this.clsFormatacao.currency( parseFloat( resultado ), casasDecimais )
      }

    }

    return {
      resultado: resultado,
      unidadeResultado: unidade,
      conversaoRealizadaComSucesso: conversaoRealizadaComSucesso
    }
  }

  private ArredondarOuTruncar ( resultado: number, tipo: UnidadeDeterminacaoPosCalculoType, casasDecimais: number ): number {

    const fator: number = parseInt( '1'.concat( '0'.repeat( casasDecimais ) ) )

    resultado = resultado * fator

    if ( tipo === UnidadeDeterminacaoPosCalculoType.ARREDONDAR ) {
      resultado = Math.round( resultado ) / fator
    } else {
      resultado = Math.trunc( resultado ) / fator
    }

    return resultado

  }

}