import React from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DeterminacaoCalculoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import LeituraCalculoComponent from './LeituraCalculoComponent'

interface PropsInterface {
  parametros: Array<DeterminacaoCalculoInterface>
  nome: string
  onAlterarParametro: ( propriedade: string, novoValor: number | string | boolean ) => void
  onClose: () => void
}

export default function LeituraParametros ( { parametros, nome, onAlterarParametro, onClose }: PropsInterface ) {

  const btFechar = () => {
    onClose()
  }

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Determinação: {nome}
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mb: 3 }}>

              {parametros.map( rsDeterminacaoCalculo =>
                <LeituraCalculoComponent
                  key={'idParametro'.concat( rsDeterminacaoCalculo.variavel )}
                  exibirLabel={true}
                  calculo={rsDeterminacaoCalculo}
                  onAlterar={( propriedade: string, novoValor: number | string | boolean, encerrarEdicao: boolean ) => onAlterarParametro( propriedade, novoValor )}
                  id={'idParametro'.concat( rsDeterminacaoCalculo.variavel )}
                  value={0}
                />

              )}

            </Grid>

          </Grid>
        </Paper>

      </Container>

    </>
  )

}