import React, { useEffect, useState } from "react";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TesteBox_Teste_Leitura from "./TesteBox_Teste_Leitura";
import JoditEditor from 'jodit-react';
import { Box, Button, Dialog, Grid, Icon, Typography, useTheme, CircularProgress, Container } from '@mui/material'
import ExibirJSONDev from "../DevComponents/ExibirJSONDev";
import axios from "axios";
import { ENDPOINT } from "../ImportBackend/Config/emDesenvolvimento";

export default function TextBox () {

  const [rsTexto, setRsTexto] = useState<string>( '' )

  const btDownload = () => {

    axios.post( ENDPOINT.concat( 'teste' ), {
      /*
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      // responseType: 'blob', // important
      */
    } ).then( ( response ) => {

      const linkSource = `data:application/pdf;base64,${response.data}`

      const downloadLink = document.createElement( 'a' )
      document.body.appendChild( downloadLink )

      downloadLink.href = linkSource
      downloadLink.target = '_self'
      downloadLink.download = 'teste'
      downloadLink.click()
      document.body.removeChild( downloadLink )
    } ).catch( e => {
      console.log( 'Erro: ', e )
    } )

  }

  const CONFIG_EDITOR = {
    // buttons: [], 
    language: 'pt_br',
    spellcheck: false,
    i18n: {
      // pt_br: pt_br
    },
    statusbar: false,
    removeButtons: ['image', 'video', 'about', 'file'],
    placeholder: 'Observação para Laudo...',
    enter: "div"
  }

  return ( <>

    <Grid container>
      <Grid item xs={8}>
        <JoditEditor value={rsTexto} onBlur={( rs ) => setRsTexto( rs )} config={CONFIG_EDITOR as any} />
      </Grid>

      <Grid item xs={4}>
        <ExibirJSONDev oque={['Texto', rsTexto]} />
      </Grid>

      <Grid item xs={12}>
        <Button onClick={() => btDownload()}>Download</Button>
      </Grid>
    </Grid>
  </> )

  /*

  const [conteudo, setConteudo] = useState( EditorState.createEmpty() )

  const onChange = ( rs: EditorState ) => {
    console.log( conteudo )
    console.log( rs.getCurrentContent() )
    setConteudo( rs )
  }

  return (
    <>
      <Editor
        editorState={conteudo}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </>
  )
  */
}