import React, { useEffect, useState } from 'react'

import { Button, Container, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import { PropsInterfaceDetalheJSON, StatusForm } from '../../DevComponents/DataTableCrudJSON'

import ClsValidacao from '../../Utils/ClsValidacao'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import InputText from '../../DevComponents/InputText'
import Condicional from '../../Layout/Condicional'
import { CanalNotificacaoType, CanalNotificacaoTypes } from '../../ImportBackend/types/ConstantesDataTypes'
import { ClienteContatoInterface } from '../../ImportBackend/Interfaces/ClienteInterfaces'
import ComboBox from '../../DevComponents/ComboBox'

export default function ClientesContatosForm ( {
  onConfirmarExclusao,
  onConfirmarEdicaoInclusao,
  onCancelar,
  open,
  statusForm,
  registro,
  titulo
}: PropsInterfaceDetalheJSON<ClienteContatoInterface> ) {

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Contato',
    [StatusForm.Excluindo]: 'Exclusão de Contato',
    [StatusForm.Exibindo]: 'Contato',
    [StatusForm.Editando]: 'Alteração de Dados Contato',
    [StatusForm.Inicial]: 'Dados de Contato',
  }

  const [dados, setDados] = useState<ClienteContatoInterface>( { ...registro } )

  useEffect( () => {
    setDados( { ...registro } )
  }, [registro] )

  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'tipo', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )

    if ( retorno ) {
      if ( dados.tipo === CanalNotificacaoType.WHATSAPP || dados.tipo === CanalNotificacaoType.TELEFONE ) {
        retorno = clsValidacao.eTelefone( 'numeroEmail', dados, erros, retorno )
      } else {
        retorno = clsValidacao.eEmail( 'numeroEmail', dados, erros, retorno )
      }
    } else {
      retorno = clsValidacao.naoVazio( 'numeroEmail', dados, erros, retorno )
    }

    setErros( erros )

    return retorno

  }

  const btConfirmar = () => {

    if ( validarDados() ) {
      onConfirmarEdicaoInclusao( dados )
    }

  }

  return (
    <Dialog open={open} onClose={onCancelar}>

      <Container maxWidth="md" sx={{ my: 3 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                {titulo}
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => onCancelar()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>


          <Grid item xs={12}>
            <ComboBox
              campoDescricao='descricao'
              campoID='idCanal'
              opcoes={CanalNotificacaoTypes}
              dados={dados}
              erros={erros}
              field='tipo'
              label='Tipo'
              setState={setDados}
              onChange={( v: any ) => setDados( { ...dados, tipo: v.idCanal } )}
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12} >
            <InputText
              dados={dados}
              field='numeroEmail'
              label={CanalNotificacaoTypes.find( v => v.idCanal === dados.tipo )?.descricao as string}
              maxLength={255}
              setState={setDados}
              erros={erros}
              mask={dados.tipo === CanalNotificacaoType.EMAIL ? undefined : 'tel'}
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={dados}
              field='nome'
              label='Nome'
              maxLength={50}
              setState={setDados}
              erros={erros}
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={dados}
              field='observacao'
              label='Observacao'
              maxLength={50}
              setState={setDados}
              erros={erros}
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={dados}
              field='notificarFaturamento'
              label='Faturamento'
              setState={setDados}
              erros={erros}
              tipo='checkbox'
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={dados}
              field='enviarResultado'
              label='Resultado'
              setState={setDados}
              erros={erros}
              tipo='checkbox'
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={dados}
              field='notificarRecebimentoAmostra'
              label='Recebimento de Amostras'
              setState={setDados}
              erros={erros}
              tipo='checkbox'
              disabled={statusForm === StatusForm.Excluindo}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>

            <Condicional condicao={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => onConfirmarExclusao()}>Confirmar</Button>
            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Excluindo}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            </Condicional>

            <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

          </Grid>

        </Paper>
      </Container>

    </Dialog>
  )

}