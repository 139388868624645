import React, { useEffect, useState } from 'react'

import { Button, Container, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import { PropsInterfaceDetalheJSON, StatusForm } from '../../../DevComponents/DataTableCrudJSON'

import ClsValidacao from '../../../Utils/ClsValidacao'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import InputText from '../../../DevComponents/InputText'
import Condicional from '../../../Layout/Condicional'
import { UnidadeDeterminacaoConversaoInterface } from '../../../ImportBackend/Interfaces/UnidadeDeterminacaoInterfaces'
import { UnidadeDeterminacaoPosCalculoTypes, UnidadeDeterminacaoTipoInformacaoType, UnidadeDeterminacaoTipoInformacaoTypes, VALIDACAO_MASCARA } from '../../../ImportBackend/types/ConstantesDataTypes'
import ComboBox from '../../../DevComponents/ComboBox'
import JoditEditor from 'jodit-react'
import { CONFIG_EDITOR_UNIDADE_DESCRICAO } from './MateriaisDeterminacoesForm'

export const calcularExemplo = ( rs: UnidadeDeterminacaoConversaoInterface, valor: number ): string => {

  let retorno: string = ''
  try {

    // eslint-disable-next-line no-eval
    const result = eval( rs.calculo.replace( '[[RESULTADO]]', valor.toString() ) )
    retorno = result.toString().concat( ' ', rs.unidade )

  } catch ( error: any ) {
    retorno = error.message
  }

  return retorno
}

export const validarCalculoExemplo = ( rs: UnidadeDeterminacaoConversaoInterface, valor: number ): boolean => {

  let retorno: boolean = false
  try {

    // eslint-disable-next-line no-eval
    const result = eval( rs.calculo.replace( '[[RESULTADO]]', valor.toString() ) )
    retorno = typeof result === 'number'

  } catch ( _error: any ) {
    retorno = false
  }

  return retorno
}

export default function UnidadeDeterminacaoConversaoForm ( {
  onConfirmarExclusao,
  onConfirmarEdicaoInclusao,
  onCancelar,
  open,
  statusForm,
  registro,
  titulo
}: PropsInterfaceDetalheJSON<UnidadeDeterminacaoConversaoInterface> ) {

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Conversão',
    [StatusForm.Excluindo]: 'Exclusão de Conversão',
    [StatusForm.Exibindo]: 'Conversão',
    [StatusForm.Editando]: 'Alteração de Dados de Conversão',
    [StatusForm.Inicial]: 'Dados de Conversão',
  }

  const [dados, setDados] = useState<UnidadeDeterminacaoConversaoInterface>( { ...registro } )

  useEffect( () => {
    setDados( { ...registro } )
  }, [registro] )

  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'calculo', dados, erros, retorno )

    if ( retorno ) {
      if ( !validarCalculoExemplo( dados, 100 ) ) {
        retorno = false
        erros.calculo = 'Expressão inválida!'
      }
    }

    retorno = clsValidacao.naoVazio( 'unidade', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'casasDecimais', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'tipoInformacao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'posCalculo', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btConfirmar = () => {

    if ( validarDados() ) {
      onConfirmarEdicaoInclusao( dados )
    }

  }

  return (
    <Dialog open={open} onClose={onCancelar}>

      <Container maxWidth="md" sx={{ my: 3 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>

          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                {titulo}
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => onCancelar()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container>

            <Grid item xs={12}>
              <InputText
                dados={dados}
                field='unidade'
                label='Unidade de Medida'
                maxLength={25}
                setState={setDados}
                erros={erros}
                disabled={statusForm === StatusForm.Excluindo}
              />
            </Grid>

              {/*
            <Grid item xs={12}>

              <Typography variant="body2" sx={{ mt: 3 }} gutterBottom>
                Símbolo para Laudo
              </Typography>

              <JoditEditor
                value={dados.descricaoLaudo}
                onBlur={( rs ) => { setDados( { ...dados, descricaoLaudo: rs } ) }}
                config={CONFIG_EDITOR_UNIDADE_DESCRICAO}
              />

            </Grid>
            */}

            <Grid item xs={12}>
              <InputText
                dados={dados}
                field='descricaoLaudo'
                label='Laudo (impressão)'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                maxLength={255}
              />
            </Grid>

            <Grid item xs={12}>
              <InputText
                dados={dados}
                field='calculo'
                label='Cálculo'
                maxLength={255}
                setState={setDados}
                erros={erros}
                disabled={statusForm === StatusForm.Excluindo}
              />
            </Grid>

            <Grid item xs={6}>

              <ComboBox
                campoDescricao='descricao'
                campoID='idUnidadeDeterminacaoTipoInformacao'
                opcoes={UnidadeDeterminacaoTipoInformacaoTypes}
                dados={dados}
                field='tipoInformacao'
                label='Tipo da Informação'
                setState={setDados}
                onChange={( v: any ) => setDados( { ...dados, tipoInformacao: v.idUnidadeDeterminacaoTipoInformacao } )}
                disabled={statusForm === StatusForm.Excluindo}
              />

            </Grid>

            <Grid item xs={6} sx={{ pl: 1 }}>

              <InputText
                dados={dados}
                field='casasDecimais'
                label='Casas Decimais'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo || dados.tipoInformacao !== UnidadeDeterminacaoTipoInformacaoType.NUMERO}
                erros={erros}
                tipo='number'
                maxLength={2}
              />

            </Grid>

            <Grid item xs={12}>

              <ComboBox
                campoDescricao='descricao'
                campoID='idUnidadeDeterminacaoPosCalculo'
                opcoes={UnidadeDeterminacaoPosCalculoTypes}
                dados={dados}
                field='posCalculo'
                label='Pós Cálculo'
                setState={setDados}
                onChange={( v: any ) => setDados( { ...dados, posCalculo: v.idUnidadeDeterminacaoPosCalculo } )}
                disabled={statusForm === StatusForm.Excluindo || dados.tipoInformacao === UnidadeDeterminacaoTipoInformacaoType.TEXTO}
              />

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>

              <Condicional condicao={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}>
                <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => onConfirmarExclusao()}>Confirmar</Button>
              </Condicional>

              <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
              </Condicional>

              <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

            </Grid>

          </Grid>

        </Paper>
      </Container>

    </Dialog>
  )

}