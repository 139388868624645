import React, { useContext, useState, useEffect } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { StatusForm } from '../../../Utils/ClsCrud'
import InputText from '../../../DevComponents/InputText'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { GetDeterminacoesMateriaisTabelaPrecosTypeInterface, TabelaPrecoDeterminacaoMaterialInterface } from '../../../ImportBackend/Interfaces/TabelaPrecoInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

enum StatusFormAnalise {
  Inicial,
  Editando
}

export interface PropsInterface {
  idTabelaPreco: number | undefined
  statusForm: StatusForm
  onChange: ( TabelaPrecosDeterminacoesMateriais: Array<TabelaPrecoDeterminacaoMaterialInterface> ) => void
}

export default function TabelaPrecosDeterminacoesMateriais ( { idTabelaPreco, statusForm, onChange }: PropsInterface ) {

  const [statusFormAnalise, setStatusFormMaterial] = useState<StatusFormAnalise>( StatusFormAnalise.Inicial )

  const clsFormatcao: ClsFormatacao = new ClsFormatacao()
  const [rsDeterminacoesMateriais, setRsDeterminacoesMateriais] = useState<Array<GetDeterminacoesMateriaisTabelaPrecosTypeInterface>>( [] )

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi: BackEndAPI = new BackEndAPI()

  const pesquisarDeterminacoesMateriaisDaTabelaPrecos = () => {

    if ( idTabelaPreco && idTabelaPreco > 0 ) {

      const query = `
        getDeterminacoesMateriaisTabelaPrecos(idTabelaPreco: ${idTabelaPreco}) {
          idMaterial
          descricao
          valorAdicional
          valorIndividual
          valorAdicionalSemFormula
        }
      `

      clsApi.query<Array<GetDeterminacoesMateriaisTabelaPrecosTypeInterface>>( query, 'getDeterminacoesMateriaisTabelaPrecos', 'Pesquisando Analises...', contexto ).then( rsDeterminacoesMateriais => {
        setRsDeterminacoesMateriais( rsDeterminacoesMateriais )
      } )

    } else {
      setRsDeterminacoesMateriais( [] )
    }


  }

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Material',
      campo: 'descricao'
    },
    {
      cabecalho: 'Valor Adicional',
      campo: 'valorAdicional',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatcao.currency( v )
    },
    {
      cabecalho: 'Valor Individual',
      campo: 'valorIndividual',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatcao.currency( v )
    },
    {
      cabecalho: 'Adicional Sem Fórmula',
      campo: 'valorAdicionalSemFormula',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatcao.currency( v )
    }
  ]

  const btEditarMaterial = ( oque: GetDeterminacoesMateriaisTabelaPrecosTypeInterface ) => {
    setDados( oque )
    setStatusFormMaterial( StatusFormAnalise.Editando )
  }

  const btCancelarEdicaoMaterial = () => {
    setStatusFormMaterial( StatusFormAnalise.Inicial )
    setDados( ResetDados )
  }

  const btConfirmarInclusaoEdicaoMaterial = () => {

    if ( validarDados() ) {

      const indexEdicao: number = rsDeterminacoesMateriais.findIndex( v => ( dados.idMaterial === v.idMaterial ) )

      const newTabelaPrecosDeterminacoesMateriais: Array<TabelaPrecoDeterminacaoMaterialInterface> = []
      const newRsDeterminacoesMateriais = [...rsDeterminacoesMateriais]

      newRsDeterminacoesMateriais[indexEdicao].valorAdicional = dados.valorAdicional
      newRsDeterminacoesMateriais[indexEdicao].valorIndividual = dados.valorIndividual
      newRsDeterminacoesMateriais[indexEdicao].valorAdicionalSemFormula = dados.valorAdicionalSemFormula

      newRsDeterminacoesMateriais.forEach( el => {
        newTabelaPrecosDeterminacoesMateriais.push( {
          idMaterial: el.idMaterial,
          valorAdicional: el.valorAdicional,
          valorIndividual: el.valorIndividual,
          idTabelaPreco: 0,
          valorAdicionalSemFormula: el.valorAdicionalSemFormula
        } )
      } )

      setStatusFormMaterial( StatusFormAnalise.Inicial )

      onChange( newTabelaPrecosDeterminacoesMateriais )

      setRsDeterminacoesMateriais( newRsDeterminacoesMateriais )

      setDados( ResetDados )

    }

  }

  const ResetDados: GetDeterminacoesMateriaisTabelaPrecosTypeInterface = {
    idTabelaPrecoDeterminacaoMaterial: 0,
    idMaterial: 0,
    descricao: '',
    valorAdicional: 0,
    valorIndividual: 0,
    valorAdicionalSemFormula: 0
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idMaterial', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valorAdicional', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valorIndividual', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const [dados, setDados] = useState<GetDeterminacoesMateriaisTabelaPrecosTypeInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  useEffect( () => {
    pesquisarDeterminacoesMateriaisDaTabelaPrecos()

    //eslint-disable-next-line
  }, [idTabelaPreco] )

  const fecharEdicao = () => setStatusFormMaterial( StatusFormAnalise.Inicial )

  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography component="h6" variant="h6" align="left">
            Valores por Material
            <Typography variant="body2" gutterBottom>
              Valores individuais e adicionais determinados por material
            </Typography>
          </Typography>
        </Grid>

        <Dialog
          fullWidth
          open={statusFormAnalise === StatusFormAnalise.Editando}
          onClose={fecharEdicao}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>Altere o Valor</DialogTitle>
          <DialogContent>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='descricao'
                label='Material'
                setState={setDados}
                disabled
                erros={erros}
              />
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='valorAdicional'
                label='Valor Adicional'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='valorIndividual'
                label='Valor Individual'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='valorAdicionalSemFormula'
                label='Adiconal Sem Fórmula'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12} alignSelf='center' sx={{ pl: 1, textAlign: 'center', mt: 3 }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarInclusaoEdicaoMaterial()}>Confirmar</Button>
              <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelarEdicaoMaterial()}>Cancelar</Button>
            </Grid>

          </DialogContent>

        </Dialog>

      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <DataTable dados={rsDeterminacoesMateriais} cabecalho={cabecalho}
          acoes={statusForm !== StatusForm.Excluindo ? [
            { icone: 'create', toolTip: '', onAcionador: btEditarMaterial },
          ] : []}
        />
      </Grid>

    </>
  )

}