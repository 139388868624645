import React from 'react'
import { Box, Button, CardContent, Grid, Typography } from '@mui/material'

interface PropsInterface {
  qtdTotal: number
  qtdTotalSelecionada: number
  capacidadeProducaoDiaria: number
  qtdProgramada: number
  onBtGerarMapaProducao: () => void
}

export default function GerarMapasResumo ( { qtdTotal, qtdTotalSelecionada, capacidadeProducaoDiaria, qtdProgramada, onBtGerarMapaProducao }: PropsInterface ) {

  const Cartao = ( { titulo, numero }: { titulo: string, numero: number } ) => {

    return (
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {titulo}
        </Typography>
        <Typography variant="h5" component="span">
          {numero}
        </Typography>
      </CardContent>
    )

  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3, mb: 3 }}>
            <Cartao titulo="Programados" numero={qtdProgramada} />
            <Cartao titulo="Pendentes" numero={qtdTotal} />
            <Cartao titulo="Selecionados" numero={qtdTotalSelecionada} />
            <Cartao titulo="Capacidade" numero={capacidadeProducaoDiaria} />
            <Cartao titulo="Saldo" numero={capacidadeProducaoDiaria - ( qtdProgramada + qtdTotalSelecionada )} />
            <Button variant='contained' onClick={() => onBtGerarMapaProducao()} disabled={qtdTotalSelecionada === 0}>Gerar Mapa</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )

}