import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsCrud from '../../../Utils/ClsCrud'

import { StatusForm } from '../../../Utils/ClsCrud'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import { TipoMapaProducaoInterface } from '../../../ImportBackend/Interfaces/TipoMapaProducaoInterfaces'

interface PesquisaInterface {
  descricao: string
}

export default function TiposMapaProducao () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'capacidadeProducaoDiaria',
      cabecalho: 'Produção Diária',
      alinhamento: 'right'
    },
    {
      campo: 'qtdAmostrasPorBandeja',
      cabecalho: 'Amostras Por Bandeja',
      alinhamento: 'right'
    }
  ]

  const ResetDados: TipoMapaProducaoInterface = {
    idTipoMapaProducao: 0,
    descricao: '',
    capacidadeProducaoDiaria: 0,
    qtdAmostrasPorBandeja: 0
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Tipo de Mapa de Produção',
    [StatusForm.Excluindo]: 'Exclusão de Tipo de Mapa de Produção Não Utilizado',
    [StatusForm.Pesquisando]: 'Tipos de Mapas de Produção são utilizados para separar as amostras',
    [StatusForm.Editando]: 'Alteração de Dados de Tipo de Mapa de Produção',
    [StatusForm.Exibindo]: 'Dados de Tipo de Mapa de Produção'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<TipoMapaProducaoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<TipoMapaProducaoInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )

    setErros( erros )

    return retorno
  }

  const clsCrud: ClsCrud<TipoMapaProducaoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateTipoMapaProducao',
      excluirMutation: 'delTipoMapaProducao',
      campoId: 'idTipoMapaProducao',
      camposPesquisa: '{idTipoMapaProducao descricao capacidadeProducaoDiaria qtdAmostrasPorBandeja}',
      pesquisaQuery: 'getTiposMapaProducao',
      pesquisaPorId: 'getTipoMapaProducaoPorId',
      camposPesquisaPorId: '{idTipoMapaProducao descricao capacidadeProducaoDiaria qtdAmostrasPorBandeja}'
    },
    {
      confirmando: 'Atualizando Tipo de Mapa de Produção',
      erroCadastro: 'Erro ao Cadastrar Tipo de Mapa de Produção',
      erroExclusao: 'Erro ao Excluir Tipo de Mapa de Produção',
      erroPesquisa: 'Erro ao Pesquisar Tipo de Mapa de Produção',
      pesquisando: 'Pesquisando Dados de Tipos de Mapas de Produção...',
      sucessoCadastro: 'Tipo de Mapa de Produção Cadastrado com sucesso!',
      atualizacaoSucesso: 'Tipo de Mapa de Produção Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Tipo de Mapa de Produção Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Tipos de Mapa de Produção...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Tipos de Mapas de Produção
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>
              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={12} md={6}>

                <InputText
                  dados={dados}
                  field='capacidadeProducaoDiaria'
                  label='Capacidade Produção Diária'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  tipo='number'
                  mask='000'
                  maxLength={3}
                />

              </Grid>

              <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>

                <InputText
                  dados={dados}
                  field='qtdAmostrasPorBandeja'
                  label='Amostras Por Bandeja'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  tipo='number'
                  mask='000'
                  maxLength={3}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              {JSON.stringify( dados )}
              {JSON.stringify( erros )}
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}