import React, { useState } from 'react'
import { PermissoesInterface } from '../ImportBackend/Interfaces/SistemaModuloPermissaoInterfaces'

export interface LoginStateInterface {
  token: string
  nome: string
  logado: boolean
  idFilialAtual: number
  idUsuario: number
  permissoes: Array<PermissoesInterface>
}

export default function useLoginState () {

  const [loginState, setLoginState] =
    useState<LoginStateInterface>( {
      token: '',
      nome: '',
      idUsuario: 0,
      logado: false,
      idFilialAtual: 0,
      permissoes: []
    } )

  return { loginState, setLoginState }

}