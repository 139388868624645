import { Box, Grid, Paper, Typography, IconButton } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ComboBox from '../../DevComponents/ComboBox'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { FilialInterface } from '../../ImportBackend/Interfaces/FilialInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import CloseIcon from '@mui/icons-material/Close'

export default function SetFilial () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto
  const { loginState, setLoginState } = contexto

  const [disable, setDisable] = useState( false )

  const [rsFiliais, setRsFiliais] = useState<Array<FilialInterface>>( [] )

  const clsApi: BackEndAPI = new BackEndAPI()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const setFilial = ( rsFilial: FilialInterface ) => {

    const idFilial: number = rsFilial && rsFilial.idFilial && rsFilial.idFilial !== 0 ? rsFilial.idFilial : 0

    if ( idFilial !== 0 ) {

      setDisable( true )

      const mutation = `
        setUsuarioFilial(idFilial: ${idFilial}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'setUsuarioFilial', 'Alterando Filial...', contexto ).then( rs => {

        if ( rs.ok ) {
          setLoginState( { ...loginState, idFilialAtual: idFilial } )
          setDados( { idFilial: idFilial } )
          setDisable( false )
        } else {
          setDisable( false )
          setLoginState( { ...loginState, idFilialAtual: 0 } )
        }

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Alterar Filial do Usuário!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

        setDisable( false )

      } )

    }

  }

  const pesquisarUsuarioFiliais = () => {
    const query = `
      getUsuarioFilialsPermitidasPorAcesso {
        idFilial
        descricao
      }
    `

    clsApi.query<Array<FilialInterface>>( query, 'getUsuarioFilialsPermitidasPorAcesso', 'Recebendo Filiais...', contexto ).then( rsFiliais => {

      setRsFiliais( rsFiliais )

      if ( rsFiliais.length === 1 ) {
        setFilial( rsFiliais[0] )
      }

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Filiais de Usuários!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )

  }

  useEffect( () => {
    
    pesquisarUsuarioFiliais()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const [dados, setDados] = useState( { idFilial: 0 } )

  return (
    <>

      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='50vh'
      >
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Paper>

            <Box
              sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}
            >
              <Grid>
                <Grid item textAlign='right'>
                  <IconButton onClick={() => btFechar()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>


                <Grid item textAlign='center'>
                  <Typography variant="h5" fontFamily='sans-serif' fontWeight='bolder' color="primary.main">
                    Selecione uma Filial
                  </Typography>
                </Grid>

                <Grid item xs={12}>

                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idFilial'
                    opcoes={rsFiliais}
                    dados={dados}
                    field='idFilial'
                    label='Acessar Dados de:'
                    setState={setDados}
                    onChange={( v: FilialInterface ) => setFilial( v )}
                    disabled={disable}
                  />

                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>

    </>
  )

}