import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { CondicaoPagamentoInterface } from '../../ImportBackend/Interfaces/CondicaoPagamentoInterfaces'
import ClsCrud from '../../Utils/ClsCrud'

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao'
import ComboBox from '../../DevComponents/ComboBox'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { TipoPagamentoType, TipoPagamentoTypes } from '../../ImportBackend/types/ConstantesDataTypes'

interface PesquisaInterface {
  descricao: string
}

export default function CondicoesPagamentos () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'prazo',
      cabecalho: 'Prazo',
      alinhamento: 'right'
    },
    {
      campo: 'tipoPagamento',
      cabecalho: 'Tipo',
      alinhamento: 'left',
      //@ts-ignore
      format: ( oque ) => TipoPagamentoTypes.find( ( v ) => v.idTipoPagamento === oque ).descricao
    }
  ]

  const ResetDados: CondicaoPagamentoInterface = {
    idCondicaoPagamento: 0,
    descricao: '',
    tipoPagamento: TipoPagamentoType.ANTECIPADO,
    prazo: 0
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Condição de Pagamento',
    [StatusForm.Excluindo]: 'Exclusão de Condição de Pagamento Não Utilizada',
    [StatusForm.Pesquisando]: 'Condições de Pagamentos são utilizadas para Faturamento',
    [StatusForm.Editando]: 'Alteração de Dados de Condição de Pagamento',
    [StatusForm.Exibindo]: 'Dados de Condição de Pagamento'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<CondicaoPagamentoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<CondicaoPagamentoInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'tipoPagamento', dados, erros, retorno )

    setErros( erros )

    if ( retorno ) {
      if ( dados.tipoPagamento === TipoPagamentoType.ANTECIPADO && dados.prazo > 0 ) {
        retorno = false
        erros.prazo = 'Campo deve ser \'0\' em caso de Pagamento Antecipado.'
      }
    }

    return retorno
  }

  const clsCrud: ClsCrud<CondicaoPagamentoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateCondicaoPagamento',
      excluirMutation: 'delCondicaoPagamento',
      campoId: 'idCondicaoPagamento',
      camposPesquisa: '{idCondicaoPagamento descricao prazo tipoPagamento}',
      pesquisaQuery: 'getCondicoesPagamentos',
      pesquisaPorId: 'getCondicaoPagamentoPorId',
      camposPesquisaPorId: '{idCondicaoPagamento descricao prazo tipoPagamento}'
    },
    {
      confirmando: 'Atualizando Condição de Pagamento',
      erroCadastro: 'Erro ao Cadastrar Condição de Pagamento',
      erroExclusao: 'Erro ao Excluir Condição de Pagamento',
      erroPesquisa: 'Erro ao Pesquisar Condição de Pagamento',
      pesquisando: 'Pesquisando Dados de Condições de Pagamentos...',
      sucessoCadastro: 'Condição de Pagamento Cadastrada com sucesso!',
      atualizacaoSucesso: 'Condição de Pagamento Atualizada com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Condição de Pagamento Excluída com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Condição de Pagamento...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Condições de Pagamentos
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>
              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={6}>

                <InputText
                  dados={dados}
                  field='prazo'
                  label='Prazo'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  tipo='number'
                  mask='000'
                  maxLength={3}
                />

              </Grid>

              <Grid item xs={6} sx={{ pl: 2 }}>

                <ComboBox
                  campoDescricao='descricao'
                  campoID='idTipoPagamento'
                  opcoes={TipoPagamentoTypes}
                  dados={dados}
                  field='tipoPagamento'
                  label='Tipo do Pagamento'
                  setState={setDados}
                  onChange={( v: any ) => setDados( { ...dados, tipoPagamento: v.idTipoPagamento } )}
                  disabled={statusForm === StatusForm.Excluindo}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              {JSON.stringify( dados )}
              {JSON.stringify( erros )}
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}