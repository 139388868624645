import React, { useState } from 'react'
import { MenuOpcoesInterface } from '../Layout/MenuCls'


export interface LayoutStateInterface {
  descricaoFilialAtual: string
  opcaoNavegacaoInferior: number
  exibirMenu: boolean
  opcoesMenu: Array<MenuOpcoesInterface>
}

export default function useLayoutState () {

  const [layoutState, setLayoutState] =
    useState<LayoutStateInterface>( {
      descricaoFilialAtual: '',
      opcaoNavegacaoInferior: -1,
      exibirMenu: false,
      opcoesMenu: []
    } )

  return { layoutState, setLayoutState }

}