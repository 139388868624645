import { gql } from "@apollo/client"
import { ContextoGlobalInterface } from "../../GlobalStates/ContextoGlobal"
import { LayoutStateInterface } from "../../GlobalStates/LayoutState"

export default class ClsPesquisaFilialAtual {

  public setarDescricaoFilial (
    idFilial: number,
    contextoGlobal: ContextoGlobalInterface,
    layoutState: LayoutStateInterface,
    setLayoutState: React.Dispatch<React.SetStateAction<LayoutStateInterface>>
  ) {

    if ( idFilial !== 0 ) {

      const query: string = `
        getFilialPorId (idFilial: ${idFilial}) {
          descricao
        }
      `

      contextoGlobal.apolloClient
        .query( {
          query: gql( `query { ${query} }` )
        } ).then( rs => {
          setLayoutState( { ...layoutState, descricaoFilialAtual: rs.data.getFilialPorId.descricao } )
        } )

    } else {
      setLayoutState( { ...layoutState, descricaoFilialAtual: 'Escolha a Filial' } )
    }

  }

}