import React, { useContext, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Container, Grid, Typography } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'
import { CustomCooperbom_ConsultaClienteERPInterface } from '../../ImportBackend/Interfaces/CustomCooperbom_Interfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'

interface PropsInterface {
  onIncluir: ( rs: CustomCooperbom_ConsultaClienteERPInterface ) => void
}

interface PesquisaInterface {
  descricao: string
}

export default function CooperbomClientesPesquisaERP ( { onIncluir }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const abortController: AbortController = new AbortController()

  const clsApi = new BackEndAPI()

  const CabecalhoPesquisaERP: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'codigoCliente',
      cabecalho: 'Código / Matrícula',
      alinhamento: 'left'
    },
    {
      campo: 'nome',
      cabecalho: 'Nome | Razão Social',
      alinhamento: 'left'
    },
    {
      campo: 'nomeFantasia',
      cabecalho: 'Nome Fantasia',
      alinhamento: 'left'
    },
    {
      campo: 'cnpjCPF',
      cabecalho: 'CPF / CNPJ',
      alinhamento: 'left'
    },
    {
      campo: 'ativo',
      cabecalho: 'Ativo ERP',
      alinhamento: 'left',
      format: ( rs: boolean ) => rs ? 'Sim' : 'Não'
    },
    {
      campo: 'cadastradoAPP',
      cabecalho: 'Cadastrado APP',
      alinhamento: 'left',
      format: ( rs: boolean ) => rs ? 'Sim' : 'Não'
    }
  ]

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )
  const [rsPesquisa, setRsPesquisa] = useState<Array<CustomCooperbom_ConsultaClienteERPInterface>>( [] )

  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', pesquisa, erros, retorno )

    setErros( erros )

    return retorno

  }

  const pesquisarERP = () => {

    if ( validarDados() ) {

      const query: string = `
        CustomCooperbom_pesquisaClienteERP (pesquisa: "${pesquisa.descricao}") {
          codigoCliente
          cnpjCPF
          nome
          nomeFantasia
          ativo
          cadastradoAPP
          cep
          endereco
          bairro
          cidade
          uf
        }
      `

      clsApi.query<Array<CustomCooperbom_ConsultaClienteERPInterface>>( query, 'CustomCooperbom_pesquisaClienteERP', 'Pesquisando ERP...', contexto, abortController ).then( ( rs ) => {
        setRsPesquisa( rs )
      } ).catch( () => {
        contexto.setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Tente restringir a consulta. Se o problema persistir, consulte suporte!!!!',
          tipo: MensagemTipo.Error,
          titulo: 'Erro na pesquisa'
        } )
      } )

      return () => {

        abortController.abort()
      }

    }

  }

  const onBtPesquisar = () => {
    pesquisarERP()
  }

  const incluirNovoCadastro = ( rs: CustomCooperbom_ConsultaClienteERPInterface ) => {
    onIncluir( rs )
  }

  return (
    <>
      <Container maxWidth="lg">

        <Grid container>

          <Grid item xs={12}>
            <Typography component="h6" variant="h6" align="left">
              Pesquisa no ERP
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={pesquisa}
              erros={erros}
              field='descricao'
              label='Utlize somente números ou nomes'
              setState={setPesquisa}
              iconeEnd="search"
              onClickIconeEnd={() => onBtPesquisar()}
              mapKeyPress={[{ key: 'Enter', onKey: onBtPesquisar }]}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <DataTable dados={rsPesquisa} cabecalho={CabecalhoPesquisaERP} acoes={[
              { icone: 'add_circle', toolTip: 'Incluir', onAcionador: incluirNovoCadastro, onDisabled: ( rs ) => rs.cadastradoAPP }]} />
          </Grid>

        </Grid>
      </Container>

    </>
  )

}