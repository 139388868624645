/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px
*/

import React, { useContext, useEffect, useState } from 'react'
import { Container, Divider, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoColSpanInterface, DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { MapaProducaoResumoInterface, OsPendenteProducaoInterface } from '../../../ImportBackend/Interfaces/MapaProducaoInterfaces'

import ClsValidacao from '../../../Utils/ClsValidacao'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import BackEndAPI from '../../../Services/BackEndAPI'
import { FilialInterface } from '../../../ImportBackend/Interfaces/FilialInterfaces'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import GerarMapasSelecaoAmostra from './GerarMapasSelecaoAmostra'
import { TipoMapaProducaoInterface } from '../../../ImportBackend/Interfaces/TipoMapaProducaoInterfaces'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'

export default function GerarMapas () {

  const navigate = useNavigate()
  const clsFormatos: ClsFormatacao = new ClsFormatacao()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const clsApi = new BackEndAPI()

  const [pesquisa, setPesquisa] = useState( {
    idTipoMapaProducao: 0,
    dataProducao: clsFormatos.dateParaDateSQL( new Date() ),
    idFilial: 0,
    qtdAmostrasPorBandeja: 0,
    capacidadeProducaoDiaria: 0,
    qtdProgramada: 0
  } )

  const [errosPesquisa, setErrosPesquisa] = useState<Record<string, string>>( {} )

  const [rsOrdensServicosPendentesProducao, setRsOrdensServicosPendentesProducao] = useState<Array<OsPendenteProducaoInterface>>( [] )

  const [rsFiliais, setRsFiliais] = useState<Array<FilialInterface>>( [] )

  const [rsMapaProducaoResumo, setRsMapaProducaoResumo] = useState<Array<MapaProducaoResumoInterface>>( [] )
  const [exibirMapaProducaoResumo, setExibirMapaProducaoResumo] = useState( false )

  const [cabecalhoColSpanResumo, setCabecalhoColSpanResumo] = useState<Array<DataTableCabecalhoColSpanInterface>>( [] )

  const [cabecalhoResumoMapaProducao, setCabecalhoResumoMapaProducao] = useState<Array<DataTableCabecalhoInterface>>( [] )

  const btFechar = () => {
    navigate( '/' )
  }

  const onChangeDataProducao = ( data: string ) => {
    setPesquisa( { ...pesquisa, dataProducao: data, capacidadeProducaoDiaria: 0, idTipoMapaProducao: 0, qtdAmostrasPorBandeja: 0, qtdProgramada: 0 } )

    setExibirMapaProducaoResumo( false )
    setRsOrdensServicosPendentesProducao( [] )
  }

  const criarCabecalhoResumoMapaProducao = ( rsMapaProducaoResumo: Array<MapaProducaoResumoInterface> ): Array<DataTableCabecalhoInterface> => {
    let tmpCabecalhoResumoMapa: Array<DataTableCabecalhoInterface> = [{
      cabecalho: 'Mapa',
      campo: 'descricao',
      alinhamento: 'left'
    }]

    if ( rsMapaProducaoResumo.length > 0 ) {

      rsFiliais.forEach( ( filial ) => {

        // Pendente
        tmpCabecalhoResumoMapa.push( {
          cabecalho: 'Pendentes',
          campo: 'descricao',
          alinhamento: 'center',
          format: ( v: any, rs: any ) => {
            let retorno: string = ''
            const indiceFilial: number = rs.filiais.findIndex( ( v: any ) => v.idFilial === filial.idFilial )

            if ( indiceFilial >= 0 ) retorno = rs.filiais[indiceFilial].qtdPendente

            if ( parseInt( retorno ) === 0 ) retorno = ''

            return retorno
          }
        } )

        // Programada
        tmpCabecalhoResumoMapa.push( {
          cabecalho: 'Programadas',
          campo: 'descricao',
          alinhamento: 'center',
          format: ( v: any, rs: any ) => {
            let retorno: string = ''
            const indiceFilial: number = rs.filiais.findIndex( ( v: any ) => v.idFilial === filial.idFilial )

            if ( indiceFilial >= 0 ) retorno = rs.filiais[indiceFilial].qtdProgramada

            if ( parseInt( retorno ) === 0 ) retorno = ''

            return retorno
          }
        } )
      } )

    }

    tmpCabecalhoResumoMapa.push(
      {
        cabecalho: 'Pendentes',
        campo: 'qtdTotalPendente',
        alinhamento: 'center',
        format: ( v ) => parseInt( v ) === 0 ? '' : clsFormatos.currency( parseInt( v ), 0 )
      },
      {
        cabecalho: 'Programadas',
        campo: 'qtdTotalProgramada',
        alinhamento: 'center',
        format: ( v ) => parseInt( v ) === 0 ? '' : clsFormatos.currency( parseInt( v ), 0 )
      },
      {
        cabecalho: 'Saldo',
        campo: 'saldo',
        alinhamento: 'center',
        format: ( v ) => parseInt( v ) === 0 ? '' : clsFormatos.currency( parseInt( v ), 0 )
      }
    )

    return tmpCabecalhoResumoMapa
  }

  const criarCabecalhoColSpanResumo = ( rsMapaProducaoResumo: Array<MapaProducaoResumoInterface> ): Array<DataTableCabecalhoColSpanInterface> => {

    let tmpCabecalhoColSpanResumo: Array<DataTableCabecalhoColSpanInterface> = [{
      cabecalho: '',
      alinhamento: 'left',
      largura: 1
    }]

    if ( rsMapaProducaoResumo.length > 0 ) {

      rsFiliais.forEach( ( filial, indice ) => {

        tmpCabecalhoColSpanResumo.push( {
          cabecalho: filial.descricao,
          alinhamento: 'center',
          largura: 2
        } )

      } )

    }

    tmpCabecalhoColSpanResumo.push( {
      alinhamento: 'center',
      cabecalho: 'Totais',
      largura: 3
    } )

    tmpCabecalhoColSpanResumo.push( {
      alinhamento: 'center',
      cabecalho: '',
      largura: 1
    } )

    return tmpCabecalhoColSpanResumo
  }

  const criarCabecalhoTabela = ( rsMapaProducaoResumo: Array<MapaProducaoResumoInterface> ) => {

    setCabecalhoResumoMapaProducao( criarCabecalhoResumoMapaProducao( rsMapaProducaoResumo ) )
    setCabecalhoColSpanResumo( criarCabecalhoColSpanResumo( rsMapaProducaoResumo ) )

  }

  const onChangeTipoMapaProducaoPesquisa = ( rsTipoMapaProducao: TipoMapaProducaoInterface ) => {

    const idTipoMapaProducao: number = rsTipoMapaProducao && rsTipoMapaProducao.idTipoMapaProducao ? rsTipoMapaProducao.idTipoMapaProducao : 0

    const indice: number = rsMapaProducaoResumo.findIndex( ( v ) => v.idTipoMapaProducao === idTipoMapaProducao )

    const qtdProgramada: number = indice >= 0 ? rsMapaProducaoResumo[indice].qtdTotalProgramada : 0

    setPesquisa( {
      ...pesquisa,
      idTipoMapaProducao: idTipoMapaProducao,
      capacidadeProducaoDiaria: idTipoMapaProducao > 0 ? rsTipoMapaProducao.capacidadeProducaoDiaria : 0,
      qtdAmostrasPorBandeja: idTipoMapaProducao > 0 ? rsTipoMapaProducao.qtdAmostrasPorBandeja : 0,
      qtdProgramada: qtdProgramada
    } )

    setRsOrdensServicosPendentesProducao( [] )
  }

  const onChangeFilial = ( filial: FilialInterface ) => {

    setPesquisa( { ...pesquisa, idFilial: filial && filial.idFilial ? filial.idFilial : 0 } )
    setRsOrdensServicosPendentesProducao( [] )
  }

  const validarPesquisaResumo = () => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'dataProducao', pesquisa, erros, retorno )

    setErrosPesquisa( erros )

    return retorno
  }

  const validarPesquisaOsEmAberto = () => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'dataProducao', pesquisa, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idTipoMapaProducao', pesquisa, erros, retorno )

    setErrosPesquisa( erros )

    return retorno
  }

  const btPesquisarResumo = () => {
    if ( validarPesquisaResumo() ) {
      pesquisarResumoProducao( pesquisa.dataProducao )
    }
  }

  const btPesquisarOsEmAberto = () => {

    if ( validarPesquisaOsEmAberto() ) {

      pesquisarOrdensServicosStatusCadastradas()

    }

  }

  const pesquisarResumoProducao = ( data: string ) => {
    const query = `
      getResumoMapaProducao(data: "${data}") {
        idTipoMapaProducao
        idMapaProducao
        descricao
        capacidadeProducaoDiaria
        filiais {
          idFilial
          descricao
          qtdProgramada
          qtdPendente
        }
        qtdTotalProgramada
        qtdTotalPendente
        saldo
      }
    `

    clsApi.query<Array<MapaProducaoResumoInterface>>( query, 'getResumoMapaProducao', 'Pesquisando Mapas de Producao...', contexto ).then( ( rsMapaProducaoResumo ) => {

      setRsMapaProducaoResumo( rsMapaProducaoResumo )
      criarCabecalhoTabela( rsMapaProducaoResumo )
      setExibirMapaProducaoResumo( true )

    } )
  }

  const pesquisarOrdensServicosStatusCadastradas = () => {

    const query = `
      osPendenteProducao( idFilial: ${pesquisa.idFilial} idTipoMapaProducao: ${pesquisa.idTipoMapaProducao} ) {
        idOrdemServico
        idFilial
        filial
        idClienteRemetente
        remetente
        qtd
        Lotes {
          idOrdemServico
          idLote
          proprietario
          propriedade
          qtd
          Amostras {
            idAmostra
            idLote
            idOrdemServico
            numeroAmostra
            observacao
          }
        }
      }
    `

    clsApi.query<Array<OsPendenteProducaoInterface>>( query, 'osPendenteProducao', 'Pesquisando Ordens de Serviços Em Aberto...', contexto ).then( rsOrdensServicosPendentesProducao => {
      setRsOrdensServicosPendentesProducao( rsOrdensServicosPendentesProducao )
      // setRsOrdensServicosProducao( [] )
      // calcularResumoProducao( [] )

    } )

  }

  const pesquisarFiliais = () => {
    const query = `
      getFiliais(pesquisa: "") {
        idFilial
        descricao
      }
    `

    clsApi.query<Array<FilialInterface>>( query, 'getFiliais', 'Pesquisando Filiais...', contexto ).then( rsFiliais => {
      setRsFiliais( rsFiliais )
    } )

  }

  useEffect( () => {

    pesquisarFiliais()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const cancelarMapaProducao = ( rs: MapaProducaoResumoInterface ) => {

    // console.log( rs.idMapaProducao )

    const mutation = `
      excluirMapaProducao(idMapaProducao: ${rs.idMapaProducao}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirMapaProducao', 'Excluindo Mapa de Producao...', contexto ).then( rs => {

      if ( !rs.ok ) {

        contexto.setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          titulo: 'Mapa Não Excluído!'
        } )

      } else {
        setExibirMapaProducaoResumo( false )
      }

    } )

  }

  return (
    <>

      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Gerar Mapas de Produção
                <Typography variant="body2" gutterBottom>
                  Geração automática de mapas de produção com base em parâmetros
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={6} md={2} >

              <InputText
                dados={pesquisa}
                field='dataProducao'
                label='Data da Produção'
                setState={setPesquisa}
                type='date'
                erros={errosPesquisa}
                onChange={( v ) => onChangeDataProducao( v )}
              />

            </Grid>

            <Grid textAlign='right' item xs={6} md={10} sx={{ mt: 4.5, textAlign: 'left', pl: 1 }}>
              <Button variant='contained' onClick={() => btPesquisarResumo()} >Pesquisar</Button>
            </Grid>

            <Condicional condicao={exibirMapaProducaoResumo}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography component="h6" variant="h6" align="left">
                  Resumo
                </Typography>
                <DataTable
                  cabecalhoColSpan={cabecalhoColSpanResumo}
                  cabecalho={cabecalhoResumoMapaProducao}
                  dados={rsMapaProducaoResumo}
                  acoes={[
                    { icone: 'cancel', toolTip: 'Excluir / Cancelar Mapa', onAcionador: cancelarMapaProducao, onDisabled: ( rs: MapaProducaoResumoInterface ) => rs.idMapaProducao === 0 }]} />
              </Grid>

              <Grid item xs={12} md={5}>

                <PesquisarTabela<TipoMapaProducaoInterface>
                  field='idTipoMapaProducao'
                  fieldSet='idTipoMapaProducao'
                  label='Mapa de Produção'
                  dados={pesquisa}
                  campoQueryPesquisaID='idTipoMapaProducao'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idTipoMapaProducao descricao qtdAmostrasPorBandeja capacidadeProducaoDiaria}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getTiposMapaProducao'
                  nomeQueryPesquisaID='getTipoMapaProducaoPorId'
                  mensagemPesquisa='Procurando Tipos de Mapas de Produção...'
                  pesquisarTudoAoIniciar
                  erros={errosPesquisa}
                  onChange={( v ) => { onChangeTipoMapaProducaoPesquisa( v ) }}
                />

              </Grid>

              <Grid item xs={12} md={5} sx={{ pl: { md: 1 } }}>

                <PesquisarTabela<FilialInterface>
                  setState={setPesquisa}
                  field='idFilial'
                  fieldSet='idFilial'
                  label='Filial'
                  dados={pesquisa}
                  campoQueryPesquisaID='idFilial'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idFilial, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getFiliais'
                  nomeQueryPesquisaID='getFilialPorId'
                  mensagemPesquisa='Procurando Filiais...'
                  pesquisarTudoAoIniciar
                  erros={errosPesquisa}
                  valorAtribuirLimpar={0}
                  onChange={( f ) => onChangeFilial( f )}
                />

              </Grid>

              <Grid textAlign='right' item xs={12} md={2} sx={{ mt: 4.5, pl: { xs: 0, md: 1 } }}>
                <Button variant='contained' onClick={() => btPesquisarOsEmAberto()} >Pesquisar</Button>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Condicional condicao={rsOrdensServicosPendentesProducao.length > 0}>
                  <GerarMapasSelecaoAmostra
                    rsOrdensServicosPendentesProducao={rsOrdensServicosPendentesProducao}
                    // exibirFilial={pesquisa.idFilial && pesquisa.idFilial > 0 ? true : false}
                    capacidadeProducaoDiaria={pesquisa.capacidadeProducaoDiaria}
                    qtdProgramada={pesquisa.qtdProgramada}
                    qtdAmostrasPorBandeja={pesquisa.qtdAmostrasPorBandeja}
                    dataProducao={pesquisa.dataProducao}
                    idTipoMapaProducao={pesquisa.idTipoMapaProducao}
                    onMapaGerado={() => onChangeDataProducao( pesquisa.dataProducao )}
                  />
                </Condicional>
              </Grid>

            </Condicional>

            <ExibirJSONDev exibir={EMDESENVOLVIMENTO} oque={['pesquisa', pesquisa]} />
            <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={['rsOrdensServicosPendentesProducao', rsOrdensServicosPendentesProducao]} />

          </Grid>



        </Paper>

      </Container >
    </>
  )
}