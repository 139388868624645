import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { ParametroInterface } from '../../ImportBackend/Interfaces/ParametroInterfaces'
import ComboBox from '../../DevComponents/ComboBox'
import JoditEditor from 'jodit-react'
import { pt_br } from '../../Config/TradutorJodit'
import { clsUtils } from 'zlib-utils'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import Condicional from '../../Layout/Condicional'
import InputText from '../../DevComponents/InputText'

export default function Parametros () {

  const CONFIG_EDITOR = {
    // buttons: [], 
    language: 'pt_br',
    spellcheck: false,
    i18n: {
      pt_br: pt_br
    },
    statusbar: false,
    removeButtons: ['image', 'video', 'about', 'file'],
    placeholder: 'Cabeçalho para Laudo...'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const [rsParametros, setRsParametros] = useState<ParametroInterface>( {
    propriedadesLaudo: {
      amostras_por_pagina: 6,
      cabecalho: '',
      determinacoes_por_tabela: 10,
      emitirDeterminacoesSomenteSolicitadas: true,
      nomenclaturaDeterminacaoNaoSolicitada: ''
    },
    modeloLaudo: 'linhas'
  } )

  const btFechar = () => {
    navigate( '/' )
  }

  const pesquisar = () => {

    const query: string = `
      getParametros {
        modeloLaudo
        propriedadesLaudo {
          cabecalho
          determinacoes_por_tabela
          amostras_por_pagina
          nomenclaturaDeterminacaoNaoSolicitada
          emitirDeterminacoesSomenteSolicitadas
        }
      }
    `

    clsApi.query<ParametroInterface>( query, 'getParametros', 'Pesquisando Parametros...', contexto, abortController ).then( ( rs ) => {
      setRsParametros( rs )
    } )

    return () => {

      abortController.abort()
    }
  }

  useEffect( () => {

    pesquisar()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const btConfirmar = () => {

    let tmpDados: ParametroInterface = {
      modeloLaudo: rsParametros.modeloLaudo,
      propriedadesLaudo: JSON.stringify( rsParametros.propriedadesLaudo ) as any
    }

    const mutation: string = `
      updateParametros (dados: ${clsUtils.ConverterEmGql( tmpDados )}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateParametros', 'Atualizando Parametros...', contexto, abortController ).then( ( rs ) => {

      if ( rs.ok ) {
        btFechar()
      } else {
        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: rs.mensagem,
          titulo: 'Erro',
          tipo: MensagemTipo.Error
        } )

      }
    } )

    return () => {

      abortController.abort()
    }
  }

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Parâmetros
                <Typography variant="body2" gutterBottom>
                  Parâmetros Gerais para funcionamento do sistema
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>

              <ComboBox
                dados={rsParametros}
                field='modeloLaudo'
                label='Modelo do Laudo'
                setState={setRsParametros}
                campoDescricao='modeloLaudo'
                campoID='modeloLaudo'
                opcoes={[{ modeloLaudo: 'linhas' }, { modeloLaudo: 'colunas' }]}
              />

            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={rsParametros.propriedadesLaudo}
                field='amostras_por_pagina'
                label='Amostras Por Página'
                tipo='number'
                onChange={( rs ) => { setRsParametros( { ...rsParametros, propriedadesLaudo: { ...rsParametros.propriedadesLaudo, amostras_por_pagina: rs } } ) }}
              />

            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={rsParametros.propriedadesLaudo}
                field='emitirDeterminacoesSomenteSolicitadas'
                label='Emitir Somente Determinações Solicitadas'
                tipo='checkbox'
                onChange={( rs ) => { setRsParametros( { ...rsParametros, propriedadesLaudo: { ...rsParametros.propriedadesLaudo, emitirDeterminacoesSomenteSolicitadas: rs } } ) }}
              />

            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={rsParametros.propriedadesLaudo}
                field='nomenclaturaDeterminacaoNaoSolicitada'
                label='Nomenclatura Determinações Não Solicitadas'
                maxLength={10}
                onChange={( rs ) => { setRsParametros( { ...rsParametros, propriedadesLaudo: { ...rsParametros.propriedadesLaudo, nomenclaturaDeterminacaoNaoSolicitada: rs } } ) }}
              />

            </Grid>

            <Grid item xs={12}>

              <Typography variant="body2" sx={{ mt: 3 }} gutterBottom>
                Cabeçalho para Laudo
              </Typography>

              <JoditEditor
                value={rsParametros.propriedadesLaudo.cabecalho}
                onBlur={( rs ) => { setRsParametros( { ...rsParametros, propriedadesLaudo: { ...rsParametros.propriedadesLaudo, cabecalho: rs } } ) }}
                config={CONFIG_EDITOR}
              />

            </Grid>

            <Condicional condicao={rsParametros.modeloLaudo === 'colunas'}>

              <Grid item xs={12}>

                <InputText
                  dados={rsParametros.propriedadesLaudo}
                  field='determinacoes_por_tabela'
                  label='Determinações por tabela'
                  onChange={( rs ) => { setRsParametros( { ...rsParametros, propriedadesLaudo: { ...rsParametros.propriedadesLaudo, determinacoes_por_tabela: rs } } ) }}
                  setState={setRsParametros}
                />

              </Grid>

            </Condicional>

            <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
              <Button variant='contained' onClick={() => btConfirmar()}>Confirmar</Button>
            </Grid>
          </Grid>

          <ExibirJSONDev oque={[' rsParametros', rsParametros]} />
        </Paper>
      </Container>

    </>
  )

}