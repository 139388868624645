import React, { useContext, useEffect, useState, useRef } from 'react'
import BackEndAPI from '../../../Services/BackEndAPI'
import { RsOrdemServicoAtualInterface, TabValue } from './OrdensServicos'
import { OrdemServicoInterface, OrdemServicoValorInterface } from '../../../ImportBackend/Interfaces/OrdemServicoInterfaces'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { Container, Grid, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import InputText from '../../../DevComponents/InputText'
import { StatusForm } from '../../../Utils/ClsCrud'
import ClsUtils from 'zlib-utils'

interface PropsInterface {
  onChangeTab: ( proximaTab: TabValue ) => void
  statusForm: StatusForm
  rsOrdemServicoAtual: RsOrdemServicoAtualInterface
}

export default function OrdensValores ( { rsOrdemServicoAtual, statusForm, onChangeTab }: PropsInterface ) {

  const clsApi: BackEndAPI = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const ResetDados: OrdemServicoValorInterface = {
    valorTotal: 0,
    desconto: 0,
    perDesconto: 0,
    acrescimo: 0,
    perAcrescimo: 0,
    valorLiquido: 0
  }

  const [dados, setDados] = useState<OrdemServicoValorInterface>( ResetDados )
  const [erros, setErros] = useState<Record<string, string>>( {} )
  const [permitirGravarValor, setPermitirGravarValor] = useState<boolean>( false )

  const valoresOriginais = useRef<OrdemServicoValorInterface>( ResetDados )

  const btCancelar = () => {
    setDados( () => valoresOriginais.current )
    setPermitirGravarValor( () => false )
  }

  const consultarOS = () => {
    const query = `
      getOSPorId (idOrdemServico: ${rsOrdemServicoAtual.idOrdemServico}) {
        valorTotal
        qtdAmostras
        desconto
        perDesconto
        acrescimo
        perAcrescimo
        valorLiquido
      }
    `

    clsApi.query<OrdemServicoInterface>( query, 'getOSPorId', 'Consultando Ordem de Serviços...', contexto ).then( rsOS => {

      valoresOriginais.current = {
        idOrdemServico: rsOrdemServicoAtual.idOrdemServico,
        valorTotal: rsOS.valorTotal,
        desconto: rsOS.desconto,
        perDesconto: rsOS.perDesconto,
        acrescimo: rsOS.acrescimo,
        perAcrescimo: rsOS.perAcrescimo,
        valorLiquido: rsOS.valorLiquido
      }

      btCancelar()

    } )

  }

  useEffect( () => {

    consultarOS()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const calcularValorTotal = ( tmpDados: Partial<OrdemServicoValorInterface>, campoValor: string ) => {
    tmpDados = { ...dados, ...tmpDados }

    if ( campoValor === 'desconto' ) {
      tmpDados.acrescimo = 0
      tmpDados.perAcrescimo = 0
    } else {
      tmpDados.desconto = 0
      tmpDados.perDesconto = 0
    }

    tmpDados.valorLiquido =
      ( tmpDados.valorTotal as number ) +
      ( tmpDados.acrescimo as number ) -
      ( tmpDados.desconto as number )

    if ( tmpDados.valorLiquido >= 0 ) {
      setErros( {} )
      setPermitirGravarValor( true )
    } else {
      setErros( { valorLiquido: 'Valor Deve Ser >= 0' } )
      setPermitirGravarValor( false )
    }

    setDados( { ...dados, ...tmpDados, idOrdemServico: rsOrdemServicoAtual.idOrdemServico } )

  }

  const calcularValorPeloPercentual = ( v: number, campoPercentual: string, campoValor: string ) => {

    calcularValorTotal( { [campoPercentual]: v, [campoValor]: parseFloat( ( dados.valorTotal * v / 100 ).toFixed( 2 ) ) }, campoValor )

  }

  const calcularPercentualPeloValor = ( v: number, campoPercentual: string, campoValor: string ) => {

    calcularValorTotal( { [campoPercentual]: v > 0 && dados.valorTotal > 0 ? parseFloat( ( v / dados.valorTotal * 100 ).toFixed( 2 ) ) : 0, [campoValor]: v }, campoValor )

  }

  const btConfirmar = () => {
    const mutation: string = `
      updateOrdemServicoValor (dados: ${( new ClsUtils() ).ConverterEmGql( dados )}) {
        ok
        mensagem
      }
    `

    clsApi.mutationRespostaPadrao( mutation, 'updateOrdemServicoValor', 'Atualizando Valores...', contexto ).then( rs => {
      onChangeTab( TabValue.ORDEMSERVICO )
    } )

  }

  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 5 }}>

        <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography component="h5" variant="h5" align="left">
              Resumo da Ordem de Serviços
              <Typography variant="body2" gutterBottom>
                Confira os Valores referente as amostras cadastradas
              </Typography>
            </Typography>

          </Grid>

          <Grid item xs={12} sm={12}>
            <InputText
              dados={dados}
              field='valorTotal'
              label='Valor Total'
              setState={setDados}
              erros={erros}
              disabled={true}
              tipo='currency'
              textAlign="right"
            // disabled={[StatusForm.Excluindo].includes( statusForm )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputText
              dados={dados}
              field='perDesconto'
              label='% Desconto'
              setState={setDados}
              erros={erros}
              tipo='currency'
              textAlign="right"
              onChange={( v: number ) => calcularValorPeloPercentual( v, 'perDesconto', 'desconto' )}
              disabled={[StatusForm.Excluindo].includes( statusForm )}
            />
          </Grid>

          <Grid item xs={12} sm={8} sx={{ pl: { sm: 1 } }}>
            <InputText
              dados={dados}
              field='desconto'
              label='$ Desconto'
              setState={setDados}
              erros={erros}
              tipo='currency'
              textAlign="right"
              onChange={( v: number ) => calcularPercentualPeloValor( v, 'perDesconto', 'desconto' )}
              disabled={[StatusForm.Excluindo].includes( statusForm )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputText
              dados={dados}
              field='perAcrescimo'
              label='% Acrescimo'
              setState={setDados}
              erros={erros}
              tipo='currency'
              textAlign="right"
              onChange={( v: number ) => calcularValorPeloPercentual( v, 'perAcrescimo', 'acrescimo' )}
              disabled={[StatusForm.Excluindo].includes( statusForm )}
            />
          </Grid>

          <Grid item xs={12} sm={8} sx={{ pl: { sm: 1 } }}>
            <InputText
              dados={dados}
              field='acrescimo'
              label='$ Acrescimo'
              setState={setDados}
              erros={erros}
              tipo='currency'
              textAlign="right"
              onChange={( v: number ) => calcularPercentualPeloValor( v, 'perAcrescimo', 'acrescimo' )}
              disabled={[StatusForm.Excluindo].includes( statusForm )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputText
              dados={dados}
              field='valorLiquido'
              label='Valor Líquido'
              setState={setDados}
              erros={erros}
              tipo='currency'
              textAlign="right"
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>

            <Button disabled={!permitirGravarValor} variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            <Button disabled={!permitirGravarValor} variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelar()}>Cancelar</Button>

          </Grid>

        </Grid>

      </Container >
    </>
  )

}