import React, { useEffect, useState } from 'react'
import { DeterminacaoCalculoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import LeituraInputDinamicoComponent from './LeituraInputDinamicoComponent'

interface PropsInterface {
  calculo: DeterminacaoCalculoInterface
  onAlterar: ( propriedade: string, novoValor: string | number | boolean, encerrarEdicao: boolean ) => void
  exibirLabel: boolean
  id?: string
  value: string | number | boolean
}

export default function LeituraCalculoComponent (
  {
    calculo,
    onAlterar,
    exibirLabel,
    id = 'idLeituraCalculoComponente',
    value
  }: PropsInterface ) {

  useEffect( () => {

    const determinacao = document.getElementById( id )

    if ( determinacao ) {
      determinacao.focus()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const [rsDados, setRsDados] = useState<Record<string, string | number | boolean>>( {} )

  const onChange = ( v: number ) => {

    setRsDados( { [calculo.variavel]: v } )

  }

  const onConfirmarAlteracao = () => {
    onAlterar( calculo.variavel, rsDados[calculo.variavel], false )
  }

  // Seta Valores Padrões das Variáveis

  useEffect( () => {

    // setRsDados( { [calculo.variavel]: dados[calculo.variavel] } )
    setRsDados( { [calculo.variavel]: value } )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <LeituraInputDinamicoComponent
        id={id}
        dados={rsDados}
        conteudo={calculo.conteudo}
        descricao={calculo.descricao}
        tipoInformacao={calculo.tipoInformacao}
        field={calculo.variavel}
        onChange={( _variavel: string, valor: number ) => { onChange( valor ) }}
        exibirLabel={exibirLabel}
        onConfirmarAlteracao={onConfirmarAlteracao}
      />
    </>
  )

}