import React, { useEffect, useState } from 'react'

import { Button, Container, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import { PropsInterfaceDetalheJSON, StatusForm } from '../../../DevComponents/DataTableCrudJSON'

import ClsValidacao from '../../../Utils/ClsValidacao'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import InputText from '../../../DevComponents/InputText'
import Condicional from '../../../Layout/Condicional'

export default function ParametroLeituraLaboratorioDetalheJSON<T> ( {
  onConfirmarExclusao,
  onConfirmarEdicaoInclusao,
  onCancelar,
  open,
  statusForm,
  registro,
  titulo
}: PropsInterfaceDetalheJSON<T> ) {

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de nova chave',
    [StatusForm.Excluindo]: 'Exclusão de chave',
    [StatusForm.Exibindo]: 'Chave',
    [StatusForm.Editando]: 'Alteração de dados de chave',
    [StatusForm.Inicial]: 'Chave do Parâmetro',
  }

  const [dados, setDados] = useState<Record<any, any>>( { ...registro as Record<any, any> } )

  useEffect( () => {
    setDados( { ...registro as Record<any, any> } )
  }, [registro] )

  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'chave', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valor', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btConfirmar = () => {

    if ( validarDados() ) {
      onConfirmarEdicaoInclusao( dados )
    }

  }

  return (
    <Dialog open={open} onClose={onCancelar}>

      <Container maxWidth="md" sx={{ my: 3 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                {titulo}
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => onCancelar()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='chave'
              label='Chave'
              maxLength={30}
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='valor'
              label='Valor'
              maxLength={10}
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>

            <Condicional condicao={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => onConfirmarExclusao()}>Confirmar</Button>
            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Excluindo}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            </Condicional>

            <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

          </Grid>

        </Paper>
      </Container>



      {/*
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {TituloForm[statusForm]}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelar}>Cancel</Button>
        <Button onClick={handleClose}>Subscribe</Button>
      </DialogActions>
  */}
    </Dialog>
  )

}