import React, { useState, useEffect, useContext, useRef } from 'react'
import { StatusForm } from '../../../Utils/ClsCrud'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import DataTableLoteAmostra, { DataTableCabecalhoAmostraInterface } from './DataTableLoteAmostra'
import { AnaliseGrupoInterface, AnaliseInterface } from '../../../ImportBackend/Interfaces/AnaliseInterfaces'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import { clsUtils } from 'zlib-utils'
import { AmostraInputDeterminacaoInterface, AmostraInputInterface, AmostraInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import { TabValue } from './OrdensServicos'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'

export interface PropsInterface {
  idCliente: number,
  idOrdemServico: number
  statusForm: StatusForm
  idLote: number
  idMaterial: number
  onCancelar: () => void
  onChangeTab: ( proximaTab: TabValue ) => void
}

export default function OrdensLotesAmostras ( { idCliente, idOrdemServico, onCancelar, idLote, idMaterial, onChangeTab }: PropsInterface ) {

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const celulaAtual = useRef( { linha: 0, coluna: 2 } )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [rsAmostras, setRsAmostras] = useState<Array<AmostraInterface>>( [] )
  const [rsAnaliseGrupo, setRsAnaliseGrupo] = useState<Array<AnaliseGrupoInterface>>( [] )
  const [rsAnalises, setRsAnalises] = useState<Array<AnaliseInterface>>( [] )
  const [rsDeterminacoes, setRsDeterminacoes] = useState<Array<DeterminacaoInterface>>( [] )

  const clsApi: BackEndAPI = new BackEndAPI()

  const pesquisarAnalises = () => {
    if ( idOrdemServico > 0 && idMaterial > 0 ) {

      const query = `
        getAnalisesPorMaterial(idMaterial: ${idMaterial}) {
          idAnalise
          descricao
        }
      `

      clsApi.query<Array<AnaliseInterface>>( query, 'getAnalisesPorMaterial', 'Pesquisando Análises...', contexto ).then( rs => {
        setRsAnalises( rs )
      } )
    }
  }

  const pesquisarAnaliseGrupo = () => {
    if ( idOrdemServico > 0 && idMaterial > 0 ) {

      const query = `
        getAnaliseGrupoPorMaterial(idMaterial: ${idMaterial}) {
          idAnaliseGrupo
          descricao
          Analises {
            idAnalise
          }
          Determinacoes {
            idDeterminacao
          }
        }
    `

      clsApi.query<Array<AnaliseGrupoInterface>>( query, 'getAnaliseGrupoPorMaterial', 'Pesquisando Grupos de Análises...', contexto ).then( rs => {
        setRsAnaliseGrupo( rs )
      } )
    }
  }

  const pesquisarDeterminacoes = () => {
    if ( idOrdemServico > 0 && idMaterial > 0 ) {

      const query = `
        getDeterminacoesPorMaterial(idMaterial: ${idMaterial}) {
          idDeterminacao
          nome
          simbolo
          permitirValorSemFormula          
        }
      `

      clsApi.query<Array<DeterminacaoInterface>>( query, 'getDeterminacoesPorMaterial', 'Pesquisando Determinacoes...', contexto ).then( rs => {
        setRsDeterminacoes( rs )
      } )
    }
  }



  const pesquisarAmostras = () => {
    if ( idOrdemServico > 0 ) {

      const query = `
        getAmostrasLoteOrdemServico(idLote: ${idLote}) {
          idAmostra
          idLote
          idOrdemServico
          idMaterial
          numeroAmostra
          identificacao01
          identificacao02
          identificacao03
          identificacao04
          observacao
          idAnaliseGrupo
          Determinacoes {
            idDeterminacao
            observacao
            adicionarValorSemFormula
          }
          Analises {
            idAnalise
          }
          valor
          status
        }
    `

      clsApi.query<Array<AmostraInterface>>( query, 'getAmostrasLoteOrdemServico', 'Pesquisando Amostras...', contexto ).then( rs => {
        setRsAmostras( rs )
      } )
    }
  }

  const cabecalho: Array<DataTableCabecalhoAmostraInterface> = [
    {
      cabecalho: 'Amostra',
      campo: 'numeroAmostra',
      alinhamento: 'right',
      edit: false,
      largura: 0
    },
    {
      cabecalho: 'Status',
      campo: 'status',
      edit: false,
      largura: 0
    },
    {
      cabecalho: 'Identificacao',
      campo: 'identificacao01',
      edit: true,
      largura: 0
    },
    {
      cabecalho: 'Identificacao',
      campo: 'identificacao02',
      edit: true,
      largura: 0
    },
    {
      cabecalho: 'Identificacao',
      campo: 'identificacao03',
      edit: true,
      largura: 0
    },
    {
      cabecalho: 'Identificacao',
      campo: 'identificacao04',
      edit: true,
      largura: 0
    },
    {
      cabecalho: 'Observacao',
      campo: 'observacao',
      edit: true,
      largura: 0
    },
    {
      cabecalho: 'Grupo',
      campo: 'idAnaliseGrupo',
      edit: true,
      largura: 200
    },
    {
      cabecalho: 'Análises',
      campo: 'Analises',
      edit: true,
      largura: 200
    },
    {
      cabecalho: 'Determinacoes',
      campo: 'Determinacoes',
      edit: true,
      largura: 0
    },
    {
      cabecalho: 'Valor',
      campo: 'valor',
      alinhamento: 'right',
      format: ( v ) => clsFormatos.currency( v ),
      edit: false,
      largura: 0
    }
  ]

  useEffect( () => {

    // console.log( 'idCliente', idCliente, 'idOrdemServico', idOrdemServico, 'onCancelar', onCancelar, 'idLote', idLote, 'idMaterial', idMaterial, onChangeTab )

    pesquisarAmostras()
    pesquisarAnaliseGrupo()
    pesquisarAnalises()
    pesquisarDeterminacoes()

    //eslint-disable-next-line
  }, [idLote] )

  const converterAmostraParaAmostraInput = ( rsAmostra: Array<AmostraInterface> ): Array<AmostraInputInterface> => {

    let retorno: Array<AmostraInputInterface> = []

    rsAmostra.forEach( amostra => {

      let rsAnalises: Array<number> = []
      let rsDeterminacoes: Array<AmostraInputDeterminacaoInterface> = []

      // console.log( amostra.Analises )

      amostra.Analises.forEach( analise => {
        rsAnalises.push( analise.idAnalise )
      } )

      amostra.Determinacoes.forEach( determinacao => {

        // if ( determinacao.adicionarValorSemFormula ) {
        // console.log( 'determinacao.observacao: ', determinacao.observacao, determinacao.adicionarValorSemFormula )
        // }
        rsDeterminacoes.push( {
          idDeterminacao: determinacao.idDeterminacao,
          observacao: determinacao.observacao ? determinacao.observacao : '',
          adicionarValorSemFormula: determinacao.adicionarValorSemFormula
        } )
      } )

      retorno.push( {
        Analises: rsAnalises,
        Determinacoes: rsDeterminacoes,
        idAmostra: amostra.idAmostra,
        identificacao01: amostra.identificacao01,
        identificacao02: amostra.identificacao02,
        identificacao03: amostra.identificacao03,
        identificacao04: amostra.identificacao04,
        observacao: amostra.observacao,
        valor: amostra.valor,
        idAnaliseGrupo: amostra.idAnaliseGrupo,
        status: amostra.status
      } )

    } )

    return retorno

  }

  const onConfirmarAlteracao = ( rsAmostras: Array<AmostraInterface> ) => {

    if ( idOrdemServico > 0 ) {

      let rs: Array<AmostraInputInterface> = converterAmostraParaAmostraInput( rsAmostras )

      const mutation = `
        setAmostrasLoteOrdemServico(rsAmostras: ${clsUtils.ConverterEmGql( rs )}) {
          ok
          mensagem
        }
      `

      clsApi.mutationRespostaPadrao( mutation, 'setAmostrasLoteOrdemServico', 'Atualizando Amostras...', contexto ).then( ( rs ) => {

        if ( rs.ok )
          onChangeTab( TabValue.VALORES )
        // onCancelar()
      } )

    }
  }

  const onRefreshDados = ( rs: Array<AmostraInterface>, linhaAtual: number, colunaAtual: number ) => {
    celulaAtual.current = { linha: linhaAtual, coluna: colunaAtual }
    setRsAmostras( JSON.parse( JSON.stringify( rs ) ) )
  }

  return (
    <>
      <DataTableLoteAmostra
        cabecalho={cabecalho}
        dados={rsAmostras}
        onConfirmarAlteracao={onConfirmarAlteracao}
        onRefreshDados={onRefreshDados}
        indiceColunaInicial={celulaAtual.current.coluna}
        indiceLinhaInicial={celulaAtual.current.linha}
        rsAnaliseGrupo={rsAnaliseGrupo}
        rsAnalises={rsAnalises}
        rsDeterminacoes={rsDeterminacoes}
        onCancelar={onCancelar}
        idCliente={idCliente}
      />

      <ExibirJSONDev oque={['rsAmostras', rsAmostras]} />
    </>
  )
}