import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import { GerarMapaProducaoInterface, OsPendenteProducaoInterface, OsPendenteProducaoLoteInterface } from '../../../ImportBackend/Interfaces/MapaProducaoInterfaces'
import { Checkbox, IconButton, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import Condicional from '../../../Layout/Condicional'
import { StyledTableRow } from '../../../DevComponents/DataTable'
import GerarMapasResumo from './GerarMapasResumo'
import { AmostraInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { clsUtils } from 'zlib-utils'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'

const enum CoresEnum {
  os = "#FFFFFF",
  lote = "#FFFFFF",
  amostra = "#FFFFFF"
}

const enum CoresTituloEnum {
  os = "#46923c",
  lote = "#72bf6a",
  amostra = "#cce7c9"
}

interface PropsInterface {
  rsOrdensServicosPendentesProducao: Array<OsPendenteProducaoInterface>
  capacidadeProducaoDiaria: number
  qtdProgramada: number
  idTipoMapaProducao: number
  qtdAmostrasPorBandeja: number
  dataProducao: string
  onMapaGerado: () => void
}

interface PropsRowOSInterface {
  rsOS: OsPendenteProducaoInterface
  indiceOS: number
}

interface PropsRowLoteInterface {
  rsLote: OsPendenteProducaoLoteInterface
  indiceLote: number
  indiceOS: number
}

interface ResumoSelecaoInterface {
  idOrdemServico: number
  qtdSelecionada: number
  open: boolean
  Lotes: Array<{
    idLote: number
    qtdSelecionada: number
    open: boolean
  }>
}

interface rsAmostrasSelecionadasInterface {
  idAmostra: number
  numeroAmostra: number
}

export default function GerarMapasSelecaoAmostra ( {
  rsOrdensServicosPendentesProducao,
  capacidadeProducaoDiaria,
  qtdProgramada,
  qtdAmostrasPorBandeja,
  dataProducao,
  idTipoMapaProducao,
  onMapaGerado
}: PropsInterface ) {

  const [rsAmostras, setRsAmostras] = useState<Array<rsAmostrasSelecionadasInterface>>( [] )
  const [rsResumoSelecao, setResumoSelecao] = useState<Array<ResumoSelecaoInterface>>( [] )
  const [qtdTotalSelecionada, setQtdTotalSelecionada] = useState( 0 )
  const [qtdTotal, setQtdTotal] = useState( 0 )

  const clsApi = new BackEndAPI()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const resumirSelecao = ( rsAmostrasSelecionadas: Array<rsAmostrasSelecionadasInterface> ) => {

    let tmpResumo: Array<ResumoSelecaoInterface> = []
    let tmpTotalSelecionada: number = 0
    let tmpTotal: number = 0

    const contarQtdSelecionadaLote = ( indiceOS: number, indiceLote: number ): number => {

      let retorno: number = 0

      rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].Amostras.forEach( amostra => {
        if ( rsAmostrasSelecionadas.findIndex( v => v.idAmostra === amostra.idAmostra ) >= 0 ) retorno++
        tmpTotal++
      } )

      return retorno

    }

    rsOrdensServicosPendentesProducao.forEach( ( rsOS, indiceOS ) => {

      let qtdSelecaoOS: number = 0

      tmpResumo.push( {
        idOrdemServico: rsOS.idOrdemServico,
        qtdSelecionada: 0,
        Lotes: [],
        open: rsResumoSelecao.length > 0 ? rsResumoSelecao[indiceOS].open : false
      } )

      const indiceResumo: number = tmpResumo.length - 1

      rsOS.Lotes.forEach( ( rsLote, indiceLote ) => {
        let qtdSelecaoLote: number = contarQtdSelecionadaLote( indiceOS, indiceLote )
        tmpResumo[indiceResumo].Lotes.push( {
          idLote: rsLote.idLote,
          qtdSelecionada: qtdSelecaoLote,
          open: rsResumoSelecao.length > 0 ? rsResumoSelecao[indiceOS].Lotes[indiceLote].open : false
        } )

        qtdSelecaoOS += qtdSelecaoLote
      } )

      tmpResumo[indiceResumo].qtdSelecionada = qtdSelecaoOS
      tmpTotalSelecionada += qtdSelecaoOS

    } )

    setResumoSelecao( tmpResumo )
    setQtdTotalSelecionada( tmpTotalSelecionada )
    setQtdTotal( tmpTotal )

  }

  const btSelecinarTudo = () => {

    // Verifica se é para selecionar ou cancelar o que está selecionado
    const selecionar: boolean = qtdTotalSelecionada === 0

    let tmpResumo: Array<ResumoSelecaoInterface> = []
    let tmpTotal: number = 0
    let tmpRsAmostras: Array<rsAmostrasSelecionadasInterface> = []

    rsOrdensServicosPendentesProducao.forEach( ( rsOS, indiceOS ) => {

      let qtdSelecaoOS: number = 0

      tmpResumo.push( {
        idOrdemServico: rsOS.idOrdemServico,
        qtdSelecionada: 0,
        Lotes: [],
        open: rsResumoSelecao.length > 0 ? rsResumoSelecao[indiceOS].open : false
      } )

      const indiceResumo: number = tmpResumo.length - 1

      rsOS.Lotes.forEach( ( rsLote, indiceLote ) => {
        rsLote.Amostras.forEach( rsAm => {
          tmpTotal++
          if ( selecionar ) {
            tmpRsAmostras.push( {
              idAmostra: rsAm.idAmostra,
              numeroAmostra: rsAm.numeroAmostra as number
            } )
            qtdSelecaoOS++
          }
        } )

        tmpResumo[indiceResumo].Lotes.push( {
          idLote: rsLote.idLote,
          qtdSelecionada: selecionar ? rsLote.Amostras.length : 0,
          open: rsResumoSelecao.length > 0 ? rsResumoSelecao[indiceOS].Lotes[indiceLote].open : false
        } )

      } )

      tmpResumo[indiceResumo].qtdSelecionada = qtdSelecaoOS

    } )

    setResumoSelecao( tmpResumo )
    setQtdTotalSelecionada( selecionar ? tmpTotal : 0 )
    setQtdTotal( tmpTotal )
    setRsAmostras( tmpRsAmostras )

  }

  const selecionarAmostra = ( rsAmostra: AmostraInterface ) => {
    let tmpRsAmostras = [...rsAmostras]

    const indice = tmpRsAmostras.findIndex( v => v.idAmostra === rsAmostra.idAmostra )

    if ( indice >= 0 ) {
      tmpRsAmostras.splice( indice, 1 )
    } else {
      tmpRsAmostras.push( { idAmostra: rsAmostra.idAmostra, numeroAmostra: rsAmostra.numeroAmostra } )
      tmpRsAmostras.sort()
    }

    setRsAmostras( tmpRsAmostras )

    resumirSelecao( tmpRsAmostras )

  }

  const changeViewLote = ( indiceLote: number, indiceOS: number ) => {
    let tmpResumo = [...rsResumoSelecao]
    tmpResumo[indiceOS].Lotes[indiceLote].open = !tmpResumo[indiceOS].Lotes[indiceLote].open
    setResumoSelecao( tmpResumo )
  }

  const changeViewOS = ( indiceOS: number ) => {
    let tmpResumo = [...rsResumoSelecao]
    tmpResumo[indiceOS].open = !tmpResumo[indiceOS].open
    setResumoSelecao( tmpResumo )
  }

  // Seleção de OS

  const selecaoParcialOS = ( indiceOS: number ): boolean => {

    return rsResumoSelecao[indiceOS].qtdSelecionada > 0 && rsResumoSelecao[indiceOS].qtdSelecionada !== rsOrdensServicosPendentesProducao[indiceOS].qtd

  }

  const selecaoTotalOS = ( indiceOS: number ): boolean => {

    return rsResumoSelecao[indiceOS].qtdSelecionada === rsOrdensServicosPendentesProducao[indiceOS].qtd

  }

  const incluirOuExcluirAmostra = ( newRsAmostras: Array<rsAmostrasSelecionadasInterface>, rsAmostra: rsAmostrasSelecionadasInterface, incluir: boolean ) => {

    const indice = newRsAmostras.findIndex( v => v.idAmostra === rsAmostra.idAmostra )

    if ( incluir && indice < 0 ) {
      newRsAmostras.push( rsAmostra )
    } else if ( !incluir && indice >= 0 ) {
      newRsAmostras.splice( indice, 1 )
    }

  }

  const btSelecinarOS = ( indiceOS: number ): void => {

    // Verifica se é para selecionar ou cancelar o que está selecionado
    const selecionar: boolean = !selecaoParcialOS( indiceOS ) && !selecaoTotalOS( indiceOS )

    let tmpResumo: Array<ResumoSelecaoInterface> = [...rsResumoSelecao]

    let tmpQtdTotalSelecionada: number = qtdTotalSelecionada

    // console.log( qtdTotalSelecionada )

    if ( selecionar ) {
      tmpResumo[indiceOS].qtdSelecionada = tmpResumo[indiceOS].qtdSelecionada - tmpResumo[indiceOS].qtdSelecionada + rsOrdensServicosPendentesProducao[indiceOS].qtd
      tmpResumo[indiceOS].qtdSelecionada = rsOrdensServicosPendentesProducao[indiceOS].qtd

      tmpQtdTotalSelecionada += tmpResumo[indiceOS].qtdSelecionada

    } else {
      tmpQtdTotalSelecionada -= tmpResumo[indiceOS].qtdSelecionada

      tmpResumo[indiceOS].qtdSelecionada = tmpResumo[indiceOS].qtdSelecionada - tmpResumo[indiceOS].qtdSelecionada
      tmpResumo[indiceOS].qtdSelecionada = 0

    }

    let newRsAmostras = [...rsAmostras]

    rsOrdensServicosPendentesProducao[indiceOS].Lotes.forEach( ( _rsLote, indiceLote ) => {

      tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada = selecionar ? rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].qtd : 0

      rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].Amostras.forEach( rsAmostra => {
        incluirOuExcluirAmostra( newRsAmostras, { idAmostra: rsAmostra.idAmostra, numeroAmostra: rsAmostra.numeroAmostra }, selecionar )
      } )

    } )

    setResumoSelecao( tmpResumo )
    setRsAmostras( newRsAmostras )

    setQtdTotalSelecionada( tmpQtdTotalSelecionada )

  }

  // Seleção de Lotes

  const selecaoParcialLote = ( indiceOS: number, indiceLote: number ): boolean => {

    return rsResumoSelecao[indiceOS].Lotes[indiceLote].qtdSelecionada > 0 && rsResumoSelecao[indiceOS].Lotes[indiceLote].qtdSelecionada !== rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].qtd

  }

  const selecaoTotalLote = ( indiceOS: number, indiceLote: number ): boolean => {

    return rsResumoSelecao[indiceOS].Lotes[indiceLote].qtdSelecionada === rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].qtd

  }

  const btSelecinarLote = ( indiceOS: number, indiceLote: number ): void => {

    // Verifica se é para selecionar ou cancelar o que está selecionado
    const selecionar: boolean = !selecaoParcialLote( indiceOS, indiceLote ) && !selecaoTotalLote( indiceOS, indiceLote )

    let tmpResumo: Array<ResumoSelecaoInterface> = [...rsResumoSelecao]

    let tmpQtdTotalSelecionada: number = qtdTotalSelecionada

    if ( selecionar ) {
      tmpResumo[indiceOS].qtdSelecionada = tmpResumo[indiceOS].qtdSelecionada - tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada + rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].qtd
      tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada = rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].qtd

      tmpQtdTotalSelecionada += tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada

    } else {
      tmpQtdTotalSelecionada -= tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada

      tmpResumo[indiceOS].qtdSelecionada = tmpResumo[indiceOS].qtdSelecionada - tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada
      tmpResumo[indiceOS].Lotes[indiceLote].qtdSelecionada = 0

    }

    let newRsAmostras = [...rsAmostras]

    rsOrdensServicosPendentesProducao[indiceOS].Lotes[indiceLote].Amostras.forEach( rsAmostra => {
      incluirOuExcluirAmostra( newRsAmostras, { idAmostra: rsAmostra.idAmostra, numeroAmostra: rsAmostra.numeroAmostra }, selecionar )
    } )

    setResumoSelecao( tmpResumo )
    setRsAmostras( newRsAmostras )

    setQtdTotalSelecionada( tmpQtdTotalSelecionada )

  }

  const RowLote = ( { rsLote, indiceLote, indiceOS }: PropsRowLoteInterface ) => {

    return (
      <>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset', backgroundColor: CoresEnum.lote } }}>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={selecaoTotalLote( indiceOS, indiceLote )}
              indeterminate={selecaoParcialLote( indiceOS, indiceLote )}
              onClick={() => btSelecinarLote( indiceOS, indiceLote )}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {rsLote.idLote}
          </TableCell>
          <TableCell >{rsLote.proprietario}</TableCell>
          <TableCell >{rsLote.propriedade}</TableCell>
          <TableCell align="right">{rsLote.qtd}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => changeViewLote( indiceLote, indiceOS )}
            >
              {rsResumoSelecao[indiceOS].Lotes[indiceLote].open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </StyledTableRow>

        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={rsResumoSelecao[indiceOS].Lotes[indiceLote].open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {/*
                <Typography variant="h6" gutterBottom component="div">
                Amostras
                </Typography>
    */}
                <Table size="small" aria-label="purchases">
                  <TableHead sx={{ backgroundColor: CoresTituloEnum.amostra }}>
                    <StyledTableRow>
                      <TableCell />
                      <TableCell>Amostra</TableCell>
                      <TableCell>Observação</TableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {rsLote.Amostras.map( ( rsAmostra, indice ) => (
                      <StyledTableRow
                        hover
                        onClick={() => selecionarAmostra( rsAmostra )}
                        role="checkbox"
                        aria-checked={rsAmostras.findIndex( v => v.idAmostra === rsAmostra.idAmostra ) >= 0}
                        tabIndex={-1}
                        key={indice}
                        selected={rsAmostras.findIndex( v => v.idAmostra === rsAmostra.idAmostra ) >= 0}
                        sx={{ cursor: 'pointer', backgroundColor: CoresEnum.amostra }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={rsAmostras.findIndex( v => v.idAmostra === rsAmostra.idAmostra ) >= 0}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rsAmostra.numeroAmostra}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rsAmostra.observacao}
                        </TableCell>
                      </StyledTableRow>
                    ) )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </>
    )

  }

  const RowOS = ( { rsOS, indiceOS }: PropsRowOSInterface ) => {

    return (
      <>
        <Condicional condicao={rsResumoSelecao.length >= 0}>
          <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: CoresEnum.os }}>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={selecaoTotalOS( indiceOS )}
                indeterminate={selecaoParcialOS( indiceOS )}
                onClick={() => btSelecinarOS( indiceOS )}
              />
            </TableCell>
            <TableCell component="th" scope="row">
              {rsOS.idOrdemServico}
            </TableCell>
            <TableCell >{rsOS.filial}</TableCell>
            <TableCell >{rsOS.remetente}</TableCell>
            <TableCell align="right">{rsOS.qtd}</TableCell>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => changeViewOS( indiceOS )}
              >
                {rsResumoSelecao[indiceOS].open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={rsResumoSelecao[indiceOS].open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 2, marginBottom: 4 }}>
                  {/*
                  <Typography variant="h6" gutterBottom component="div">
                  Lotes
                  </Typography>
    */}
                  <Table size="small" aria-label="purchases">
                    <TableHead sx={{ backgroundColor: CoresTituloEnum.lote }}>
                      <StyledTableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selecaoTotalOS( indiceOS )}
                            indeterminate={selecaoParcialOS( indiceOS )}
                            onClick={() => btSelecinarOS( indiceOS )}
                          />
                        </TableCell>
                        <TableCell>Lote</TableCell>
                        <TableCell >Proprietário</TableCell>
                        <TableCell >Propriedade</TableCell>
                        <TableCell align="right">Quantidade</TableCell>
                        <TableCell />
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {rsOS.Lotes.map( ( rsLote, indice ) => (
                        <RowLote key={indice} rsLote={rsLote} indiceLote={indice} indiceOS={indiceOS} />
                      ) )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </StyledTableRow>
        </Condicional>
      </>
    )
  }

  const btGerarMapaProducaorsAmostras = () => {

    const dados: GerarMapaProducaoInterface = {
      dataProducao: dataProducao,
      idTipoMapaProducao: idTipoMapaProducao,
      qtdAmostrasPorBandeja: qtdAmostrasPorBandeja,
      rsAmostrasSelecionadas: [...rsAmostras]
    }

    const mutation = `
      gerarMapaProducao(dados: ${clsUtils.ConverterEmGql( dados )}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'gerarMapaProducao', 'Gerando Mapas de Producao...', contexto ).then( rsOK => {

      if ( rsOK.ok ) {
        contexto.setMensagemState( {
          mensagem: 'Mapa Gerado com Sucesso!',
          exibirBotao: true,
          exibir: true,
          tipo: MensagemTipo.Info,
          titulo: 'Mapas de Produção',
          cb: null
        } )
        onMapaGerado()
      } else {
        contexto.setMensagemState( {
          mensagem: 'Erro ao Gerar Mapa!',
          exibirBotao: true,
          exibir: true,
          tipo: MensagemTipo.Error,
          titulo: 'Mapas de Produção',
          cb: null
        } )

      }

    } )
  }

  useEffect( () => {
    resumirSelecao( [] )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <GerarMapasResumo
        qtdTotal={qtdTotal}
        qtdTotalSelecionada={qtdTotalSelecionada}
        capacidadeProducaoDiaria={capacidadeProducaoDiaria}
        qtdProgramada={qtdProgramada}
        onBtGerarMapaProducao={() => btGerarMapaProducaorsAmostras()}
      />
      <TableContainer component={Paper}>
        <Typography variant="h6" gutterBottom component="div">
          Selecione as Amostras
        </Typography>
        <Table aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: CoresTituloEnum.os }}>
            <StyledTableRow>
              <TableCell width={1} padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={qtdTotalSelecionada === qtdTotal}
                  indeterminate={qtdTotalSelecionada > 0 && qtdTotalSelecionada < qtdTotal}
                  onClick={() => btSelecinarTudo()}
                />
              </TableCell>
              <TableCell width={1}>OS</TableCell>
              <TableCell width={3}>Filial</TableCell>
              <TableCell width={3}>Remetente</TableCell>
              <TableCell width={2} align="right">Quantidade</TableCell>
              <TableCell width={1}></TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rsResumoSelecao.map( ( _resumo, indice ) => (
              <RowOS key={indice} rsOS={rsOrdensServicosPendentesProducao[indice]} indiceOS={indice} />
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false}
        oque={['qtdTotal', qtdTotal, 'qtdTotalSelecionada', qtdTotalSelecionada]} />

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false}
        oque={['Amostras', rsAmostras, 'Resumo', rsResumoSelecao, 'rsOrdensServicosPendentesProducao', rsOrdensServicosPendentesProducao]} />

    </>
  )
}
