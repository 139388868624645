import React, { useContext, useMemo, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { MaterialInterface } from '../../../ImportBackend/Interfaces/MaterialInterfaces'
import ClsCrud, { StatusForm } from '../../../Utils/ClsCrud'

import ClsValidacao from '../../../Utils/ClsValidacao'
import ComboBox from '../../../DevComponents/ComboBox'
import { TipoMaterialTypes } from '../../../ImportBackend/types/ConstantesDataTypes'
import MateriaisDeterminacoes from './MateriaisDeterminacoes'
import JoditEditor from 'jodit-react'
import { pt_br } from '../../../Config/TradutorJodit'

enum StatusFormDeterminacoes {
  Pesquisando,
  Editando
}

interface PesquisaInterface {
  descricao: string
}

export default function Materiais () {

  const CONFIG_EDITOR:any = {
    // buttons: [], 
    language: 'pt_br',
    spellcheck: false,
    i18n: {
      pt_br: pt_br
    },
    statusbar: false,
    removeButtons: ['image', 'video', 'about', 'file'],
    placeholder: 'Observação para Laudo...',
    enter: "div"
  }

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'tipo',
      cabecalho: 'Tipo',
      alinhamento: 'left'
    }
  ]

  const ResetDados: MaterialInterface = {
    idMaterial: 0,
    descricao: '',
    tipo: '',
    observacaoLaudo: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Material',
    [StatusForm.Excluindo]: 'Exclusão de Material Não Utilizado',
    [StatusForm.Pesquisando]: 'Materiais são utilizados para tipos de Análises e Numeração',
    [StatusForm.Editando]: 'Alteração de Dados de Materiais',
    [StatusForm.Exibindo]: 'Dados do Material'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<MaterialInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<MaterialInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'tipo', dados, erros, retorno )

    setErros( erros )

    return retorno
  }

  // Setup Edicao Material Determinacao
  const [statusFormDeterminacoes, setStatusFormDeterminacoes] = useState<StatusFormDeterminacoes>( StatusFormDeterminacoes.Pesquisando )
  const [rsMaterial, setRsMaterial] = useState<MaterialInterface | null>( null )

  // Final Edicao Material Determinacao


  const clsCrud: ClsCrud<MaterialInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateMaterial',
      excluirMutation: 'delMaterial',
      campoId: 'idMaterial',
      camposPesquisa: '{idMaterial descricao tipo}',
      pesquisaQuery: 'getMateriais',
      pesquisaPorId: 'getMaterialPorId',
      camposPesquisaPorId: '{idMaterial descricao tipo observacaoLaudo}'
    },
    {
      confirmando: 'Atualizando Material',
      erroCadastro: 'Erro ao Cadastrar Material',
      erroExclusao: 'Erro ao Excluir Material',
      erroPesquisa: 'Erro ao Pesquisar Material',
      pesquisando: 'Pesquisando Dados de Materiais...',
      sucessoCadastro: 'Material Cadastrado com sucesso!',
      atualizacaoSucesso: 'Material Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Material Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Material...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const btEditarDeterminacao = ( rsMaterial: MaterialInterface ) => {
    if ( StatusForm.Pesquisando ) {
      setStatusFormDeterminacoes( StatusFormDeterminacoes.Editando )
      setRsMaterial( { ...rsMaterial } )
      setDados( { ...rsMaterial } )
    }
  }

  const btOnCloseDeterminacoes = (): void => {
    setStatusFormDeterminacoes( StatusFormDeterminacoes.Pesquisando )
  }

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Materiais
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando && statusFormDeterminacoes === StatusFormDeterminacoes.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando || statusFormDeterminacoes === StatusFormDeterminacoes.Editando}>
              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo || statusFormDeterminacoes === StatusFormDeterminacoes.Editando}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12}>

                <ComboBox
                  campoDescricao='descricao'
                  campoID='idTipoMaterial'
                  opcoes={TipoMaterialTypes}
                  dados={dados}
                  field='tipo'
                  label='Tipo do Material'
                  setState={setDados}
                  onChange={( v: any ) => setDados( { ...dados, tipo: v.idTipoMaterial } )}
                  disabled={statusForm === StatusForm.Excluindo || statusFormDeterminacoes === StatusFormDeterminacoes.Editando}
                />

              </Grid>

              <Grid item xs={12}>

                <Typography variant="body2" sx={{ mt: 3 }} gutterBottom>
                  Observações para Laudo
                </Typography>

                <JoditEditor
                  value={dados.observacaoLaudo}
                  onBlur={( rs ) => setDados( { ...dados, observacaoLaudo: rs } )}
                  config={{
                    ...CONFIG_EDITOR,
                    readonly: statusForm === StatusForm.Excluindo || statusFormDeterminacoes === StatusFormDeterminacoes.Editando
                  }}
                />


              </Grid>

              <Condicional condicao={statusFormDeterminacoes === StatusFormDeterminacoes.Pesquisando}>

                <Grid item xs={12} sx={{ mt: 3 }}>

                  <Condicional condicao={statusForm === StatusForm.Excluindo}>
                    <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                  </Condicional>

                  <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                    <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                  </Condicional>

                  <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

                </Grid>
              </Condicional>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando && statusFormDeterminacoes === StatusFormDeterminacoes.Pesquisando}>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable
                  dados={rsPesquisa}
                  cabecalho={Cabecalho}
                  acoes={[
                    { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                    { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar },
                    { icone: 'science', toolTip: 'Determinacoes', onAcionador: btEditarDeterminacao }
                  ]} />
              </Grid>
            </Condicional>

            <Condicional condicao={statusFormDeterminacoes === StatusFormDeterminacoes.Editando}>
              <MateriaisDeterminacoes
                rsMaterial={rsMaterial}
                onClose={() => btOnCloseDeterminacoes()} />
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}