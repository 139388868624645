import React from 'react'
import { FormControl, Typography, useTheme } from '@mui/material'
import { TipoInformacaoDeterminacaoType } from '../../../ImportBackend/types/ConstantesDataTypes'
import Condicional from '../../../Layout/Condicional'
import { NumericFormat } from 'react-number-format'

interface PropsInterface {
  descricao: string
  tipoInformacao: string
  conteudo: string
  onChange: ( variavel: string, valor: number ) => void
  onConfirmarAlteracao: () => void
  // setState: React.Dispatch<React.SetStateAction<any>>
  dados: { [key: string]: string | number | readonly string[] | undefined | any }
  field: string
  exibirLabel: boolean
  id: string
}

export default function LeituraInputDinamicoComponent (
  {
    descricao,
    tipoInformacao,
    conteudo,
    onChange,
    onConfirmarAlteracao,
    dados,
    field,
    exibirLabel,
    id
  }: PropsInterface ) {

  let scale: number = 0

  // Verifica se tem separador de decimal na máscara

  if ( tipoInformacao === TipoInformacaoDeterminacaoType.ENTRADA ) {
    const mascara: Array<string> = conteudo.split( '.' )

    if ( mascara.length === 2 ) {
      scale = mascara[1].trim().length
    }

  }

  const theme = useTheme()

  return (
    <>
      <Condicional condicao={tipoInformacao === TipoInformacaoDeterminacaoType.ENTRADA}>

        <FormControl>
          <Condicional condicao={exibirLabel}>
            <Typography
              variant='body2'
              textAlign='left'
              sx={{ mt: theme && theme.inputs && theme.inputs.marginTop ? theme.inputs.marginTop : 0 }}
            >
              {descricao}
            </Typography>
          </Condicional>

          <NumericFormat
            id={id}
            thousandSeparator={'.'}
            style={{ textAlign: 'right', padding: 5, maxWidth: '100px', fontSize: 14 }}
            decimalSeparator=","
            allowedDecimalSeparators={['.', ',']}
            decimalScale={scale}
            fixedDecimalScale={true}
            value={dados[field]}
            valueIsNumericString
            onBlur={() => onConfirmarAlteracao()}
            onKeyDown={( event ) => {
              if ( event.key === "Enter" ) {
                onConfirmarAlteracao()
              }
            }}
            onValueChange={
              // ( values ) => onChange ? onChange( field, scale > 0 ? parseFloat( values.value ) : parseInt( values.value ) ) : setState( { ...dados, [field]: values.value } )
              ( values ) => {

                if ( onChange ) {
                  onChange( field, scale > 0 ? parseFloat( values.value ) : parseInt( values.value ) )
                }

              }
            }
          />

        </FormControl>

      </Condicional>

    </>
  )

}