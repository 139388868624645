import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import InputText from '../../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal';
import Condicional from '../../../Layout/Condicional';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable';
import { useNavigate } from 'react-router-dom';
import ClsCrud from '../../../Utils/ClsCrud';

import { StatusForm } from '../../../Utils/ClsCrud'
import ClsValidacao from '../../../Utils/ClsValidacao';
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento';
import { MascaraTipoTypes } from '../../../ImportBackend/types/ConstantesDataTypes';
import { ParametroLeituraLaboratorioInterface } from '../../../ImportBackend/Interfaces/ParametroLeituraLaboratorioInterfaces';
import { MascaraTipoType } from '../../../ImportBackend/types/ConstantesDataTypes';
import ComboBox from '../../../DevComponents/ComboBox';
import DataTableCrudJSON from '../../../DevComponents/DataTableCrudJSON';
import ParametroLeituraLaboratorioDetalheJSON from './ParametroLeituraLaboratorioDetalheJSON';

interface PesquisaInterface {
  descricao: string
}

interface parametroLeituraJSONInterface {
  chave: string
  valor: string
}

export default function ParametrosLeituraLaboratorio () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'chave',
      cabecalho: 'Chave',
      alinhamento: 'left'
    },
    {
      campo: 'mascaraTipo',
      cabecalho: 'Tipo',
      alinhamento: 'left'
    },
    {
      campo: 'mascara',
      cabecalho: 'Máscara',
      alinhamento: 'left'
    }
  ]

  const ResetDados: ParametroLeituraLaboratorioInterface = {
    idParametro: 0,
    chave: '',
    descricao: '',
    mascaraTipo: MascaraTipoType.ND,
    mascara: '',
    valores: '[{"chave": "teste","valor": "caca"}]'
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de novo Parâmetro de Leitura',
    [StatusForm.Excluindo]: 'Exclusão de Parâmetro de Leitura não utilizado',
    [StatusForm.Pesquisando]: 'Parâmetro de Leitura são utilizados para auxiliar na leitura das determinações',
    [StatusForm.Editando]: 'Alteração de dados Parâmetro de Leitura',
    [StatusForm.Exibindo]: 'Parâmetro de Leitura'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<ParametroLeituraLaboratorioInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ParametroLeituraLaboratorioInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'chave', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'mascaraTipo', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'mascara', dados, erros, retorno )
  
    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<ParametroLeituraLaboratorioInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateParametroLeituraLaboratorio',
      excluirMutation: 'delParametroLeituraLaboratorio',
      campoId: 'idParametro',
      camposPesquisa: '{idParametro chave descricao  mascaraTipo mascara}',
      pesquisaQuery: 'getParametroLeituraLaboratorio',
      pesquisaPorId: 'getParametroLeituraLaboratorioPorId',
      camposPesquisaPorId: '{idParametro chave descricao  mascaraTipo mascara valores}'
    },
    {
      confirmando: 'Atualizando Parâmetro',
      erroCadastro: 'Erro ao Cadastrar Parâmetro',
      erroExclusao: 'Erro ao Excluir Parâmetro',
      erroPesquisa: 'Erro ao Pesquisar Parâmetro',
      pesquisando: 'Pesquisando Dados de Parâmetros...',
      sucessoCadastro: 'Parâmetro Cadastrado com sucesso!',
      atualizacaoSucesso: 'Parâmetro Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Parâmetro Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Parâmetro...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  // Dados para detalhe de parametros em JSON - Campo Valores

  const resetValores: parametroLeituraJSONInterface = {
    chave: '',
    valor: ''
  }

  const [valores, setValores] = useState<Array<parametroLeituraJSONInterface>>( dados.valores ? JSON.parse( dados.valores ) : [] )

  const CabecalhoValores: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'chave',
      cabecalho: 'Chave',
      alinhamento: 'left'
    },
    {
      campo: 'valor',
      cabecalho: 'Valor',
      alinhamento: 'right'
    }
  ]

  const onChangeValores = ( v: Array<parametroLeituraJSONInterface> ) => {
    setDados( { ...dados, valores: JSON.stringify( v ) } )
  }

  // FINAL ============ Dados para detalhe de parametros em JSON - Campo Valores

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Parâmetros
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12} sm={3}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='chave'
                  label='Chave'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={20}
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='mascara'
                  label='Máscara'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={15}
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>
                <ComboBox
                  campoDescricao='descricao'
                  campoID='idMascaraTipo'
                  opcoes={MascaraTipoTypes}
                  dados={dados}
                  field='mascaraTipo'
                  label='Tipo'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                />
              </Grid>

              <Grid item xs={12}>
                <DataTableCrudJSON<parametroLeituraJSONInterface>
                  dadosIniciaisRegistro={resetValores}
                  cabecalho={CabecalhoValores}
                  dados={valores}
                  ComponenteInputCrud={ParametroLeituraLaboratorioDetalheJSON}
                  setState={setValores}
                  tituloCrud='Parâmetros'
                  onChange={onChangeValores}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              {JSON.stringify( dados )}
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}