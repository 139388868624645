import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AmostraInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'

interface PropsInterface {
  idMapaProducao: number
  bandeja: number
  onClose: () => void
}

export default function ExibirBandeja ( { idMapaProducao, onClose, bandeja }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const abortController: AbortController = new AbortController()

  const clsApi = new BackEndAPI()

  const [rsAmostras, setRsAmostras] = useState<Array<AmostraInterface>>( [] )

  const btFechar = () => {
    onClose()
  }

  const pesquisar = () => {

    const query: string = `
      getAmostrasPorMapaProducaoResumo(idMapaProducao: ${idMapaProducao}) {
        idAmostra
        numeroAmostra
        identificacao01
        identificacao02
        identificacao03
        identificacao04
        observacao
        status        
      }
    `

    clsApi.query<Array<AmostraInterface>>( query, 'getAmostrasPorMapaProducaoResumo', 'Pesquisando Amostras...', contexto, abortController ).then( ( rs ) => {

      setRsAmostras( rs )

    } )

    return () => {

      abortController.abort()

    }

  }

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Amostra',
      campo: 'numeroAmostra',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Identificacao 01',
      campo: 'identificacao01',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Identificacao 02',
      campo: 'identificacao02',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Identificacao 03',
      campo: 'identificacao03',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Identificacao 04',
      campo: 'identificacao04',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Observação',
      campo: 'observacao',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Status',
      campo: 'status',
      alinhamento: 'left'
    }
  ]


  useEffect( () => {
    if ( idMapaProducao ) {
      pesquisar()
    }
    // eslint-disable-next-line
  }, [idMapaProducao] )

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Bandeja {bandeja}
                <Typography variant="body2" gutterBottom>
                  Amostras a serem lidas por bandeja
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={rsAmostras} cabecalho={cabecalho} acoes={[]} />
            </Grid>

          </Grid>

        </Paper>
      </Container>

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO} oque={['rsAmostras', rsAmostras]} />

    </>
  )

}