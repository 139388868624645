import React, { useState, useEffect } from 'react'

import { TextField } from '@mui/material'
import Condicional from '../../../Layout/Condicional'
import { AnaliseGrupoInterface, AnaliseInterface } from '../../../ImportBackend/Interfaces/AnaliseInterfaces'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import ComboBox from '../../../DevComponents/ComboBox'
import DataTableLoteAmostraAnalise from './DataTableLoteAmostraAnalise'
import { AmostraAnaliseInterface, AmostraDeterminacaoInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import DataTableLoteAmostraDeterminacao from './DataTableLoteAmostraDeterminacao'
// import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'

interface DataTableLoteAmostraCampoInterface {
  value: string | Array<AmostraAnaliseInterface> | Array<AmostraDeterminacaoInterface>
  nomeCampo: string
  indiceColuna: number
  indiceLinha: number
  onAlterar: ( indiceRegistro: number, propriedade: string, novoValor: string | Array<AmostraAnaliseInterface> | Array<AmostraDeterminacaoInterface>, encerrarEdicao: boolean ) => void
  rsAnaliseGrupo: Array<AnaliseGrupoInterface>
  rsAnalises: Array<AnaliseInterface>
  rsDeterminacoes: Array<DeterminacaoInterface>
  handleKeyDownEdicao: ( event: React.KeyboardEvent<HTMLDivElement>, indiceLinha: number, indiceColuna: number, nomeCampo: string ) => void
  onAlterarAnaliseGrupo: ( indiceRegistro: number, novoValor: number ) => void
  emEdicao: boolean
}

const calcularKeyDeterminacao = ( indiceLinha: number, indiceColuna: number, nomeCampo: string, ): string => {
  return 'txt'.concat( nomeCampo ).concat( indiceLinha.toString() ).concat( indiceColuna.toString() )
}

export default function DataTableLoteAmostraCampo ( {
  value,
  nomeCampo,
  indiceColuna,
  indiceLinha,
  onAlterar,
  rsAnaliseGrupo,
  rsAnalises,
  rsDeterminacoes,
  handleKeyDownEdicao,
  onAlterarAnaliseGrupo,
  emEdicao
}: DataTableLoteAmostraCampoInterface ) {

  const [dados, setDados] = useState( { [nomeCampo]: value } )

  const [exibirAmostraAnalise, setExibirAmostraAnalise] = useState<boolean>( false )
  const [exibirAmostraDeterminacao, setExibirAmostraDeterminacao] = useState<boolean>( false )

  useEffect( () => {

    if ( nomeCampo === 'Analises' && emEdicao ) {

      setExibirAmostraAnalise( true )

    } else if ( nomeCampo === 'Determinacoes' && emEdicao ) {

      setExibirAmostraDeterminacao( true )

    } else if ( emEdicao ) {

      const determinacao = document.getElementById( calcularKeyDeterminacao( indiceLinha, indiceColuna, nomeCampo ) )

      if ( determinacao ) {
        determinacao.focus()
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Condicional condicao={nomeCampo === 'Analises'}>
        <DataTableLoteAmostraAnalise
          value={value as Array<AmostraAnaliseInterface>}
          exibir={exibirAmostraAnalise}
          rsAnalises={rsAnalises}
          onClose={( rs: Array<AmostraAnaliseInterface> ) => {
            setExibirAmostraAnalise( false )
            setDados( { [nomeCampo]: rs } )
            onAlterar( indiceLinha, nomeCampo, rs, true )
          }}
        />
      </Condicional>

      <Condicional condicao={nomeCampo === 'Determinacoes'}>
        <DataTableLoteAmostraDeterminacao
          value={value as Array<AmostraDeterminacaoInterface>}
          exibir={exibirAmostraDeterminacao}
          rsDeterminacoes={rsDeterminacoes}
          onClose={( rs: Array<AmostraDeterminacaoInterface> ) => {
            setExibirAmostraDeterminacao( false )
            setDados( { [nomeCampo]: rs } )
            onAlterar( indiceLinha, nomeCampo, rs, true )
          }}
        />
      </Condicional>

      <Condicional condicao={!['idAnaliseGrupo', 'Analises', 'Determinacoes'].includes( nomeCampo )}>
        <TextField
          id={calcularKeyDeterminacao( indiceLinha, indiceColuna, nomeCampo )}
          sx={{ padding: 0, margin: 0 }}
          fullWidth
          size="small"
          hiddenLabel
          variant='outlined'
          value={dados[nomeCampo]}
          inputProps={{ readOnly: false, style: { height: '10px', fontSize: '14px', padding: '5px', margin: 0 } }}
          onChange={( v ) => {
            // console.log( 'On Change Value: ' )
            setDados( { [nomeCampo]: v.target.value } )
            // onAlterar( indiceLinha, nomeCampo, v.target.value, false )
          }}
          onKeyDown={( event ) => {
            if ( event.key === 'Enter' || event.key === 'Tab' || event.key === 'Escape' ) {
              // console.log( 'On Enter Edição' )
              onAlterar( indiceLinha, nomeCampo, dados[nomeCampo], false )
            }
            // console.log( 'On Key Down....' )
            handleKeyDownEdicao( event, indiceLinha, indiceColuna, nomeCampo )
          }}
        />
      </Condicional>

      <Condicional condicao={nomeCampo === 'idAnaliseGrupo'}>
        <ComboBox
          id={calcularKeyDeterminacao( indiceLinha, indiceColuna, nomeCampo )}
          campoDescricao='descricao'
          campoID='idAnaliseGrupo'
          opcoes={rsAnaliseGrupo}
          dados={dados}
          field='idAnaliseGrupo'
          label=''
          setState={setDados}
          onChange={( v: AnaliseGrupoInterface ) => {
            const valor: number = v && v.idAnaliseGrupo ? v.idAnaliseGrupo : 0
            setDados( { [nomeCampo]: valor.toString() } )
            onAlterarAnaliseGrupo( indiceLinha, valor )
          }}
        />
      </Condicional>      

    </>
  )

}