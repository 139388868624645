import React, { useContext, useState, useEffect } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { StatusForm } from '../../../Utils/ClsCrud'
import InputText from '../../../DevComponents/InputText'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { GetAnalisesTabelaPrecosTypeInterface, TabelaPrecoAnaliseInterface } from '../../../ImportBackend/Interfaces/TabelaPrecoInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

enum StatusFormAnalise {
  Inicial,
  Editando
}

export interface PropsInterface {
  idTabelaPreco: number | undefined
  statusForm: StatusForm
  onChange: ( Analises: Array<TabelaPrecoAnaliseInterface> ) => void
}

export default function TabelaPrecosAnalises ( { idTabelaPreco, statusForm, onChange }: PropsInterface ) {

  const [statusFormAnalise, setStatusFormAnalise] = useState<StatusFormAnalise>( StatusFormAnalise.Inicial )

  const clsFormatcao: ClsFormatacao = new ClsFormatacao()
  const [rsAnalises, setRsAnalises] = useState<Array<GetAnalisesTabelaPrecosTypeInterface>>( [] )

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi: BackEndAPI = new BackEndAPI()

  const pesquisarAnalisesDaTabelaPrecos = () => {

    if ( idTabelaPreco && idTabelaPreco > 0 ) {

      const query = `
      getAnalisesTabelaPrecos(idTabelaPreco: ${idTabelaPreco}) {
        idAnalise
        idMaterial
        descricaoAnalise
        descricaoMaterial
        valor
      }
    `

      clsApi.query<Array<GetAnalisesTabelaPrecosTypeInterface>>( query, 'getAnalisesTabelaPrecos', 'Pesquisando Analises...', contexto ).then( rsAnalises => {

        setRsAnalises( rsAnalises )

      } )

    } else {
      setRsAnalises( [] )
    }


  }

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Material',
      campo: 'descricaoMaterial'
    },
    {
      cabecalho: 'Analise',
      campo: 'descricaoAnalise'
    },
    {
      cabecalho: 'Valor',
      campo: 'valor',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatcao.currency( v )
    }
  ]

  const btEditarAnalise = ( oque: GetAnalisesTabelaPrecosTypeInterface ) => {
    setDados( oque )
    setStatusFormAnalise( StatusFormAnalise.Editando )
  }

  const btCancelarEdicaoAnalise = () => {
    setStatusFormAnalise( StatusFormAnalise.Inicial )
    setDados( ResetDados )
  }

  const btConfirmarInclusaoEdicaoAnalise = () => {

    if ( validarDados() ) {

      const indexEdicao: number = rsAnalises.findIndex( v => ( dados.idAnalise === v.idAnalise ) )

      const newAnalises: Array<TabelaPrecoAnaliseInterface> = []
      const newRsAnalises = [...rsAnalises]

      newRsAnalises[indexEdicao].valor = dados.valor

      newRsAnalises.forEach( el => {
        newAnalises.push( {
          idAnalise: el.idAnalise,
          valor: el.valor,
          idTabelaPreco: 0
        } )
      } )

      setStatusFormAnalise( StatusFormAnalise.Inicial )

      onChange( newAnalises )
      setRsAnalises( newRsAnalises )

      setDados( ResetDados )

    }

  }

  const ResetDados: GetAnalisesTabelaPrecosTypeInterface = {
    idAnalise: 0,
    idMaterial: 0,
    descricaoAnalise: '',
    descricaoMaterial: '',
    valor: 0
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idAnalise', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valor', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const [dados, setDados] = useState<GetAnalisesTabelaPrecosTypeInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  useEffect( () => {
    pesquisarAnalisesDaTabelaPrecos()

    //eslint-disable-next-line
  }, [idTabelaPreco] )

  const fecharEdicao = () => setStatusFormAnalise( StatusFormAnalise.Inicial )

  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography component="h6" variant="h6" align="left">
            Valores por Analises
            <Typography variant="body2" gutterBottom>
              Analises da Tabela de Preços
            </Typography>
          </Typography>
        </Grid>

        <Dialog
          fullWidth
          open={statusFormAnalise === StatusFormAnalise.Editando}
          onClose={fecharEdicao}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>Altere o Valor</DialogTitle>
          <DialogContent>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='descricaoMaterial'
                label='Material'
                setState={setDados}
                disabled
                erros={erros}
              />
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='descricaoAnalise'
                label='Análise'
                setState={setDados}
                disabled
                erros={erros}
              />
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='valor'
                label='Valor'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12} alignSelf='center' sx={{ pl: 1, textAlign: 'center', mt: 3 }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarInclusaoEdicaoAnalise()}>Confirmar</Button>
              <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelarEdicaoAnalise()}>Cancelar</Button>
            </Grid>

          </DialogContent>

        </Dialog>

      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <DataTable dados={rsAnalises} cabecalho={cabecalho}
          acoes={statusForm !== StatusForm.Excluindo ? [
            { icone: 'create', toolTip: '', onAcionador: btEditarAnalise },
          ] : []}
        />
      </Grid>

    </>
  )

}