export const pt_br = {
  'Type something': 'Escreva algo...',
  Advanced: 'Avançado',
  'About Jodit': 'Sobre o Jodit',
  'Jodit Editor': 'Editor Jodit',
  "Jodit User's Guide": 'Guia de usuário Jodit',
  'contains detailed help for using': 'contém ajuda detalhada para o uso.',
  'For information about the license, please go to our website:':
    'Para informação sobre a licença, por favor visite nosso site:',
  'Buy full version': 'Compre a versão completa',
  'Copyright © XDSoft.net - Chupurnov Valeriy. All rights reserved.':
    'Copyright © XDSoft.net - Chupurnov Valeriy. Todos os direitos reservados.',
  Anchor: 'Link',
  'Open in new tab': 'Abrir em nova aba',
  'Open in fullsize': 'Abrir editor em tela cheia',
  'Clear Formatting': 'Limpar formatação',
  'Fill color or set the text color': 'Cor de preenchimento ou cor do texto',
  Redo: 'Refazer',
  Undo: 'Desfazer',
  Bold: 'Negrito',
  Italic: 'Itálico',
  'Insert Unordered List': 'Inserir lista não ordenada',
  'Insert Ordered List': 'Inserir lista ordenada',
  'Align Center': 'Centralizar',
  'Align Justify': 'Justificar',
  'Align Left': 'Alinhar à Esquerda',
  'Align Right': 'Alinhar à Direita',
  'Insert Horizontal Line': 'Inserir linha horizontal',
  'Insert Image': 'Inserir imagem',
  'Insert file': 'Inserir arquivo',
  'Insert youtube/vimeo video': 'Inserir vídeo do Youtube/vimeo',
  'Insert link': 'Inserir link',
  'Font size': 'Tamanho da letra',
  'Font family': 'Fonte',
  'Insert format block': 'Inserir bloco',
  Normal: 'Normal',
  'Heading 1': 'Cabeçalho 1',
  'Heading 2': 'Cabeçalho 2',
  'Heading 3': 'Cabeçalho 3',
  'Heading 4': 'Cabeçalho 4',
  Quote: 'Citação',
  Code: 'Código',
  Insert: 'Inserir',
  'Insert table': 'Inserir tabela',
  'Decrease Indent': 'Diminuir recuo',
  'Increase Indent': 'Aumentar recuo',
  'Select Special Character': 'Selecionar caractere especial',
  'Insert Special Character': 'Inserir caractere especial',
  'Paint format': 'Copiar formato',
  'Change mode': 'Mudar modo',
  Margins: 'Margens',
  top: 'cima',
  right: 'direta',
  bottom: 'baixo',
  left: 'esquerda',
  Styles: 'Estilos CSS',
  Classes: 'Classes CSS',
  Align: 'Alinhamento',
  Right: 'Direita',
  Center: 'Centro',
  Left: 'Esquerda',
  '--Not Set--': '--Não Estabelecido--',
  Src: 'Fonte',
  Title: 'Título',
  Alternative: 'Texto Alternativo',
  Link: 'Link',
  'Open link in new tab': 'Abrir link em nova aba',
  Image: 'Imagem',
  file: 'Arquivo',
  'Image properties': 'Propriedades da imagem',
  Cancel: 'Cancelar',
  Ok: 'Ok',
  'Your code is similar to HTML. Keep as HTML?':
    'Seu código é similar ao HTML. Manter como HTML?',
  'Paste as HTML': 'Colar como HTML?',
  Keep: 'Manter',
  Clean: 'Limpar',
  'Insert as Text': 'Inserir como Texto',
  'Word Paste Detected': 'Colado do Word Detectado',
  'The pasted content is coming from a Microsoft Word/Excel document. Do you want to keep the format or clean it up?':
    'O conteúdo colado veio de um documento Microsoft Word/Excel. Você deseja manter o formato ou limpa-lo?',
  'Insert only Text': 'Inserir somente o Texto',
  'File Browser': 'Procurar arquivo',
  'Error on load list': 'Erro ao carregar a lista',
  'Error on load folders': 'Erro ao carregar as pastas',
  'Are you sure?': 'Você tem certeza?',
  'Enter Directory name': 'Escreva o nome da pasta',
  'Create directory': 'Criar pasta',
  'type name': 'Escreva seu nome',
  'Drop image': 'Soltar imagem',
  'Drop file': 'Soltar arquivo',
  'or click': 'ou clique',
  'Alternative text': 'Texto alternativo',
  Browse: 'Explorar',
  Upload: 'Upload',
  Background: 'Fundo',
  Text: 'Texto',
  Top: 'Cima',
  Middle: 'Meio',
  Bottom: 'Baixo',
  'Insert column before': 'Inserir coluna antes',
  'Insert column after': 'Inserir coluna depois',
  'Insert row above': 'Inserir linha acima',
  'Insert row below': 'Inserir linha abaixo',
  'Delete table': 'Excluir tabela',
  'Delete row': 'Excluir linha',
  'Delete column': 'Excluir coluna',
  'Empty cell': 'Limpar célula',
  Delete: 'Excluir',
  'Strike through': 'Tachado',
  Underline: 'Sublinhar',
  Break: 'Pausa',
  'Search for': 'Procurar por',
  'Replace with': 'Substituir com',
  Replace: 'Substituir',
  Edit: 'Editar',
  'Vertical align': 'Alinhamento vertical',
  'Horizontal align': 'Alinhamento horizontal',
  Filter: 'filtrar',
  'Sort by changed': 'Ordenar por modificação',
  'Sort by name': 'Ordenar por nome',
  'Sort by size': 'Ordenar por tamanho',
  'Add folder': 'Adicionar pasta',
  Split: 'Dividir',
  'Split vertical': 'Dividir vertical',
  'Split horizontal': 'Dividir horizontal',
  Merge: 'Mesclar',
  'Add column': 'Adicionar coluna',
  'Add row': 'Adicionar linha',
  Border: 'Borda',
  'Embed code': 'Incluir código',
  Update: 'Atualizar',
  superscript: 'sobrescrito',
  subscript: 'subscrito',
  'Cut selection': 'Cortar seleção',
  Paste: 'Colar',
  'Choose Content to Paste': 'Escolher conteúdo para colar',
  'Chars: %d': 'Caracteres: %d',
  'Words: %d': 'Palavras: %d',
  All: 'Tudo',
  'Select %s': 'Selecionar: %s',
  'Select all': 'Selecionar tudo',
  source: 'HTML',
  bold: 'negrito',
  italic: 'itálico',
  brush: 'pincel',
  link: 'link',
  undo: 'desfazer',
  redo: 'refazer',
  table: 'tabela',
  image: 'imagem',
  eraser: 'apagar',
  paragraph: 'parágrafo',
  fontsize: 'tamanho da letra',
  video: 'vídeo',
  font: 'fonte',
  about: 'Sobre de',
  print: 'Imprimir',
  underline: 'sublinhar',
  strikethrough: 'tachado',
  indent: 'recuar',
  outdent: 'diminuir recuo',
  fullsize: 'Tamanho completo',
  shrink: 'diminuir',
  hr: 'linha horizontal',
  ul: 'lista não ordenada',
  ol: 'lista ordenada',
  cut: 'Cortar',
  selectall: 'Selecionar tudo',
  'Open link': 'Abrir link',
  'Edit link': 'Editar link',
  'No follow': 'Não siga',
  Unlink: 'Remover link',
  Eye: 'Visualizar',
  pencil: 'Editar',
  ' URL': 'URL',
  Reset: 'Resetar',
  Save: 'Salvar',
  'Save as ...': 'Salvar como...',
  Resize: 'Redimensionar',
  Crop: 'Recortar',
  Width: 'Largura',
  Height: 'Altura',
  'Keep Aspect Ratio': 'Manter a proporção',
  Yes: 'Sim',
  No: 'Não',
  Remove: 'Remover',
  Select: 'Selecionar',
  'You can only edit your own images. Download this image on the host?':
    'Você só pode editar suas próprias imagens. Baixar essa imagem pro servidor?',
  'The image has been successfully uploaded to the host!':
    'A imagem foi enviada com sucesso para o servidor!',
  palette: 'Palette',
  'There are no files': 'Não há arquivos nesse diretório.',
  Rename: 'Húngara',
  'Enter new name': 'Digite um novo nome',
  preview: 'preview',
  download: 'Baixar',
  'Paste from clipboard': 'Colar da área de transferência',
  "Your browser doesn't support direct access to the clipboard.":
    'O seu navegador não oferece suporte a acesso direto para a área de transferência.',
  'Copy selection': 'Selecção de cópia',
  copy: 'cópia',
  'Border radius': 'Border radius',
  'Show all': 'Mostrar todos os',
  Apply: 'Aplicar',
  'Please fill out this field': 'Por favor, preencha este campo',
  'Please enter a web address': 'Por favor introduza um endereço web',
  Default: 'Padrão',
  Circle: 'Círculo',
  Dot: 'Ponto',
  Quadrate: 'Quadro',
  'Lower Alpha': 'Letra Minúscula',
  'Lower Greek': 'Grego Minúscula',
  'Lower Roman': 'Romano Minúscula',
  'Upper Alpha': 'Letra Maiúscula',
  'Upper Roman': 'Romano Maiúscula',
  Find: 'Encontrar',
  'Find Previous': 'Encontrar Anteriores',
  'Find Next': 'Localizar Próxima',
  'Insert className': 'Insira o nome da classe',
  'Press Alt for custom resizing':
    'Pressione Alt para redimensionamento personalizado'
}