import React, { useEffect, useState } from 'react'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { AmostraDeterminacaoInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import InputText from '../../../DevComponents/InputText'
import { StatusAmostraType } from '../../../ImportBackend/types/ConstantesDataTypes'
import { Grid } from '@mui/material'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import Condicional from '../../../Layout/Condicional'

interface DataTableLoteAmostraDeterminacaoPropsInterface {
  value: Array<AmostraDeterminacaoInterface>,
  exibir: boolean
  onClose: ( rs: Array<AmostraDeterminacaoInterface> ) => void
  rsDeterminacoes: Array<DeterminacaoInterface>
}

interface rsDeterminacoesEdicaoInterface {
  idDeterminacao?: number
  selecionado: boolean
  nome: string
  simbolo: string
  observacao: string
  adicionarValorSemFormula: boolean
}

interface CtrlObservacaoInterface {
  exibir: boolean
  observacao: string
  indice: number
  permitirValorSemFormula: boolean
}

export default function DataTableLoteAmostraDeterminacao ( {
  value,
  exibir,
  onClose,
  rsDeterminacoes
}: DataTableLoteAmostraDeterminacaoPropsInterface ) {

  const [ctrlObservacao, setCtrlObservacao] = useState<CtrlObservacaoInterface>( {
    exibir: false,
    observacao: '',
    indice: 0,
    permitirValorSemFormula: false
  } )


  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'selecionado',
      cabecalho: 'Seleção',
      format: ( selecionado ) => selecionado ? 'S' : 'N'
    },
    {
      campo: 'nome',
      cabecalho: 'Determinacao'
    },
    {
      campo: 'observacao',
      cabecalho: 'Observação'
    }
  ]

  const [dados, setDados] = useState<Array<rsDeterminacoesEdicaoInterface>>( [] )

  const btSelecionarDeterminacao = ( _rsDeterminacao: rsDeterminacoesEdicaoInterface, indice: number ) => {

    let tmpDados: Array<rsDeterminacoesEdicaoInterface> = [...dados]

    tmpDados[indice].selecionado = !tmpDados[indice].selecionado

    setDados( tmpDados )

  }

  const btEditarDeterminacao = ( rsDeterminacao: rsDeterminacoesEdicaoInterface, indice: number ) => {
    setRsEdicao( rsDeterminacao )

    const permitirValorSemFormula: boolean = rsDeterminacoes.find( v => v.idDeterminacao === rsDeterminacao.idDeterminacao )?.permitirValorSemFormula || false
    
    setCtrlObservacao( { exibir: true, indice: indice, observacao: rsDeterminacao.observacao, permitirValorSemFormula: permitirValorSemFormula } )
  }

  useEffect( () => {

    const tmpRsDeterminacoesEdicao: Array<rsDeterminacoesEdicaoInterface> = []

    rsDeterminacoes.forEach( determinacao => {

      const indice: number = value.findIndex( v => v.idDeterminacao === determinacao.idDeterminacao )

      tmpRsDeterminacoesEdicao.push( {
        nome: determinacao.nome,
        observacao: indice >= 0 && value[indice].observacao ? value[indice].observacao : '',
        selecionado: indice >= 0,
        simbolo: determinacao.simbolo,
        idDeterminacao: determinacao.idDeterminacao,
        adicionarValorSemFormula: indice >= 0 && value[indice].adicionarValorSemFormula ? true : false
      } )

    } )

    tmpRsDeterminacoesEdicao.sort( ( a, b ) => a.nome > b.nome ? 1 : 0 )

    setDados( tmpRsDeterminacoesEdicao )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const confirmarDados = () => {

    let retorno: Array<AmostraDeterminacaoInterface> = []

    dados.forEach( determinacao => {
      if ( determinacao.selecionado )
        retorno.push( {
          idDeterminacao: determinacao.idDeterminacao as number,
          observacao: determinacao.observacao ? determinacao.observacao : '',
          idAmostra: 0,
          idAmostraDeterminacao: 0,
          status: StatusAmostraType.CADASTRADA,
          adicionarValorSemFormula: determinacao.adicionarValorSemFormula
        } )
    } )

    // console.log( 'On Close e chamada de dados: ', JSON.stringify( retorno, null, 4 ) )

    onClose( retorno )

    // Tratar os dados e realizar o onclose....
  }

  const [rsEdicao, setRsEdicao] = useState<rsDeterminacoesEdicaoInterface>( {
    nome: '',
    observacao: '',
    selecionado: false,
    simbolo: '',
    adicionarValorSemFormula: false
  } )

  const confirmarEdicaoObservacao = () => {
    let tmpDados: Array<rsDeterminacoesEdicaoInterface> = [...dados]

    tmpDados[ctrlObservacao.indice].selecionado = true
    tmpDados[ctrlObservacao.indice].observacao = rsEdicao.observacao
    tmpDados[ctrlObservacao.indice].adicionarValorSemFormula = rsEdicao.adicionarValorSemFormula
    setDados( tmpDados )

    // console.log( 'tmpDados dentro do confirmarEdicaoObservacao', tmpDados[ctrlObservacao.indice] )
    setCtrlObservacao( { ...ctrlObservacao, exibir: false } )
  }

  return (
    <>

      <Dialog open={ctrlObservacao.exibir} onClose={() => confirmarEdicaoObservacao()}>
        <DialogTitle>
          Observação
        </DialogTitle>
        <DialogContent>
          <Grid container>

            <Grid item xs={12}>

              <InputText
                dados={rsEdicao}
                setState={setRsEdicao}
                field='observacao'
                label='Observação'
              />

            </Grid>

            <Condicional condicao={ctrlObservacao.permitirValorSemFormula}>
              <Grid item xs={12}>
                <InputText
                  dados={rsEdicao}
                  field='adicionarValorSemFormula'
                  label='Adicional Sem Fórmula'
                  setState={setRsEdicao}
                  tipo='checkbox'
                />
              </Grid>
            </Condicional>

          </Grid>

          <ExibirJSONDev oque={['ctrl', ctrlObservacao]} />
        </DialogContent>

        <DialogActions>
          <Button onClick={( event ) => confirmarEdicaoObservacao()}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <span>{exibir ? 'Exibindo...' : 'Não Exibindo....'}</span>

      <Dialog open={exibir} onClose={() => confirmarDados()}>
        <DialogTitle>
          Determinacoes
        </DialogTitle>
        <DialogContent>
          <DataTable
            onSelecionarLinha={( rs, indice ) => btSelecionarDeterminacao( rs as rsDeterminacoesEdicaoInterface, indice )}
            dados={dados}
            cabecalho={Cabecalho}
            acoes={[
              { icone: 'create', toolTip: 'Observação', onAcionador: btEditarDeterminacao }
            ]}
          />
        </DialogContent>
        {/*
        <DialogActions>
        <Button onClick={( event ) => { event.nativeEvent.preventDefault(); confirmarDados() }}>
        Fechar Janela
        </Button>
        </DialogActions>
          */}

        <ExibirJSONDev oque={['value', value, 'rsDeterminacoes', rsDeterminacoes, 'ctrlObservacao', ctrlObservacao]} exibir={false} />
      </Dialog>


    </>
  )

}