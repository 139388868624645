import React, { useContext, useState, useEffect } from 'react'
import { Container, Divider, Grid, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsCrud from '../../../Utils/ClsCrud'

import { StatusForm } from '../../../Utils/ClsCrud'
import ClsValidacao from '../../../Utils/ClsValidacao'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import { FilialInterface } from '../../../ImportBackend/Interfaces/FilialInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import ComboBox from '../../../DevComponents/ComboBox'
import { OrdemServicoCadastroInterface, OrdemServicoInterface, OrdemServicoPesquisaInterface } from '../../../ImportBackend/Interfaces/OrdemServicoInterfaces'
import { clsUtils } from 'zlib-utils'
import { StatusAmostraTypes, StatusOSType, StatusOSTypes } from '../../../ImportBackend/types/ConstantesDataTypes'
import { RsOrdemServicoAtualInterface, TabValue } from './OrdensServicos'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { DateTime } from 'luxon'

export interface PropsInterface {
  onChange: ( rsOrdemServicoAtual: RsOrdemServicoAtualInterface, statusFormCrud: StatusForm ) => void
  rsOrdemServicoAtual: RsOrdemServicoAtualInterface
  statusForm: StatusForm
  onChangeTab: ( proximaTab: TabValue ) => void
}

export default function OrdensCrud ( { onChange, statusForm, onChangeTab }: PropsInterface ) {

  const [rsFiliais, setRsFiliais] = useState<Array<FilialInterface>>( [] )

  const clsApi: BackEndAPI = new BackEndAPI()

  const pesquisarUsuarioFiliais = () => {
    const query = `
      getUsuarioFilialsPermitidasPorAcesso {
        idFilial
        descricao
      }
    `

    clsApi.query<Array<FilialInterface>>( query, 'getUsuarioFilialsPermitidasPorAcesso', 'Recebendo Filiais...', contexto ).then( rsFiliais => {

      setRsFiliais( rsFiliais )

    } )

  }

  useEffect( () => {

    if ( statusForm !== StatusForm.Exibindo ) {
      pesquisarUsuarioFiliais()
      setStatusFormCrud( StatusForm.Pesquisando )
    }

    //eslint-disable-next-line
  }, [statusForm] )


  const [statusFormCrud, setStatusFormCrud] = useState<StatusForm>( statusForm )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idOrdemServico',
      cabecalho: 'Número',
      alinhamento: 'left'
    },
    {
      campo: 'createdAt',
      cabecalho: 'Data Entrada',
      alinhamento: 'left',
      format: ( rs: any ) => DateTime.fromISO( rs ).toFormat( 'dd/MM/yyyy HH:ss' )
    },
    {
      campo: 'idClienteRemetente',
      cabecalho: 'Remetente',
      alinhamento: 'left',
      format: ( v, rs ) => rs.ClienteRemetente.nomeRazaoSocial
    },
    {
      campo: 'status',
      cabecalho: 'Status',
      alinhamento: 'left',
      format: ( rs: string ) => StatusAmostraTypes.find( v => v.idStatusAmostra === rs )?.descricao
    },
    {
      campo: 'qtdAmostras',
      cabecalho: 'Amostras',
      alinhamento: 'right'
    }
  ]

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const ResetDados: OrdemServicoCadastroInterface =
  {
    idOrdemServico: 0,
    idFilial: contexto.loginState.idFilialAtual,
    idClienteRemetente: 0,
    valorLiquido: 0,
    idClienteFaturamento: 0,
    idClienteProprietarioFaturamento: 0,
    idPropriedadeFaturamento: 0,
    observacaoFaturamento: '',
    status: StatusOSType.CADASTRADA,
    notificacoes: []
  }

  const [dados, setDados] = useState<OrdemServicoCadastroInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<OrdemServicoPesquisaInterface>( { descricao: '', inicioCadastro: '', terminoCadastro: '', status: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<OrdemServicoCadastroInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idFilial', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idClienteRemetente', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idClienteFaturamento', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btConfirmarInclusaoEdicao = () => {
    if ( validarDados() ) {

      const mutation: string = `
      
        updateOrdemServicoCadastro(dados: ${clsUtils.ConverterEmGql( dados as Object )})
      
      `

      clsApi.mutation<number>( mutation, 'updateOrdemServicoCadastro', 'Cadastrando OS', contexto ).then( rs => {

        // setDados( { ...dados, idOrdemServico: rs } )
        // setStatusFormCrud( StatusForm.Pesquisando )
        btExibirOS( rs )

      } )

    }
  }

  const clsCrud: ClsCrud<OrdemServicoCadastroInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusFormCrud,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateOrdemServico',
      excluirMutation: 'delOrdemServico',
      campoId: 'idOrdemServico',
      camposPesquisa: '{idOrdemServico status qtdAmostras ClienteRemetente { nomeRazaoSocial } createdAt }',
      pesquisaQuery: 'getOSs',
      pesquisaPorId: 'getOSPorId',
      camposPesquisaPorId: '{ idOrdemServico idFilial idClienteRemetente valorLiquido idClienteFaturamento idClienteProprietarioFaturamento idPropriedadeFaturamento observacaoFaturamento status notificacoes { tipo numeroEmail nome observacao notificarFaturamento enviarResultado notificarRecebimentoAmostra } }'
    },
    {
      confirmando: 'Atualizando Ordem de Serviço',
      erroCadastro: 'Erro ao Cadastrar Ordem de Serviço',
      erroExclusao: 'Erro ao Excluir Ordem de Serviço',
      erroPesquisa: 'Erro ao Pesquisar Ordem de Serviço',
      pesquisando: 'Pesquisando dados de Ordens de Serviços...',
      sucessoCadastro: 'Ordem de Serviço Cadastrada com sucesso!',
      atualizacaoSucesso: 'Ordem de Serviço Atualizada com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Ordem de Serviço excluída com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Ordem de Serviço...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa, mensagemState )
  }

  const onChangeIdClienteRemetente = ( rs: { idCliente: number, nomeRazaoSocial: string } ) => {
    let setupRsDados = { ...dados }

    if ( rs && rs.idCliente ) {

      if ( !dados.idClienteFaturamento ) {
        setupRsDados.idClienteFaturamento = rs.idCliente

        if ( !dados.idClienteProprietarioFaturamento ) {
          // console.log( 'Setup Proprietário...' )
          setupRsDados.idClienteProprietarioFaturamento = rs.idCliente
        }
      }

      setupRsDados.idClienteRemetente = rs.idCliente

    } else {
      setupRsDados.idClienteRemetente = 0
    }

    setDados( { ...setupRsDados } )

  }

  const btExibirOS = ( idOrdemServico: number ) => {

    const query = `
      getOSPorId (idOrdemServico: ${idOrdemServico}) {
        idOrdemServico
        idFilial
        idClienteRemetente
        valorLiquido
        qtdAmostras
        idClienteFaturamento
        idClienteProprietarioFaturamento
        idPropriedadeFaturamento
        observacaoFaturamento
        status
        notificacoes { 
          tipo 
          numeroEmail 
          nome 
          observacao 
          notificarFaturamento 
          enviarResultado 
          notificarRecebimentoAmostra 
        }
        ClienteRemetente {
          nomeRazaoSocial
        }
        Filial {
          descricao
        }
      }
    `

    clsApi.query<OrdemServicoInterface>( query, 'getOSPorId', 'Consultando Ordem de Serviços...', contexto ).then( ( rsOS ) => {

      setStatusFormCrud( StatusForm.Exibindo )

      setDados( {
        idOrdemServico: rsOS.idOrdemServico,
        idFilial: rsOS.idFilial,
        idClienteRemetente: rsOS.idClienteRemetente,
        valorLiquido: rsOS.valorLiquido,
        idClienteFaturamento: rsOS.idClienteFaturamento,
        idClienteProprietarioFaturamento: rsOS.idClienteProprietarioFaturamento,
        idPropriedadeFaturamento: rsOS.idPropriedadeFaturamento,
        observacaoFaturamento: rsOS.observacaoFaturamento,
        status: rsOS.status,
        notificacoes: rsOS.notificacoes
      } )

      onChange( {
        idOrdemServico: rsOS.idOrdemServico as number,
        filial: rsOS.Filial.descricao,
        remetente: rsOS.ClienteRemetente.nomeRazaoSocial,
        qtdAmostras: rsOS.qtdAmostras,
        idClienteFaturamento: rsOS.idClienteFaturamento,
        notificacoes: rsOS.notificacoes
      }, StatusForm.Exibindo )

      onChangeTab( TabValue.NOTIFICACOES )

    } )
  }

  return (
    <>
      <Container sx={{ mt: 1 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Condicional condicao={statusFormCrud === StatusForm.Pesquisando}>

              <Grid item xs={6} md={4}>

                <InputText
                  dados={pesquisa}
                  field='inicioCadastro'
                  label='Entrada de'
                  type='date'
                  setState={setPesquisa}
                  iconeEnd='clear'
                  onClickIconeEnd={() => setPesquisa( { ...pesquisa, inicioCadastro: '' } )}
                />

              </Grid>

              <Grid item xs={6} md={4} sx={{ pl: 1 }}>

                <InputText
                  dados={pesquisa}
                  field='terminoCadastro'
                  label='até'
                  type='date'
                  setState={setPesquisa}
                  iconeEnd='clear'
                  onClickIconeEnd={() => setPesquisa( { ...pesquisa, terminoCadastro: '' } )}
                />

              </Grid>
              <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }} >
                <ComboBox
                  campoDescricao='descricao'
                  campoID='idStatusOS'
                  opcoes={[{ idStatusOS: '', descricao: 'Todas' }, ...StatusOSTypes,]}
                  dados={pesquisa}
                  field='status'
                  label='Status da OS'
                  setState={setPesquisa}
                />
              </Grid>

              <Grid item xs={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} md={2} alignSelf='center' sx={{ mt: { xs: 0, md: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={![StatusForm.Pesquisando, StatusForm.Exibindo].includes( statusFormCrud )}>

              <Grid item xs={4} md={2}>
                <InputText
                  dados={dados}
                  field='idOrdemServico'
                  label='Número OS'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={6}
                />
              </Grid>

              <Grid item xs={8} md={3} sx={{ pl: 1 }}>

                <ComboBox
                  campoDescricao='descricao'
                  campoID='idFilial'
                  opcoes={rsFiliais}
                  dados={dados}
                  field='idFilial'
                  label='Filial'
                  setState={setDados}
                  disabled={[StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} md={7} sx={{ pl: { md: 1 } }}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idCliente'
                  fieldSet='idClienteRemetente'
                  label='Remetente'
                  dados={dados}
                  campoQueryPesquisaID='idCliente'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idCliente, nomeRazaoSocial, cpfCnpj, ieRG }'
                  camposParaExibir={['nomeRazaoSocial', 'ieRG', 'cpfCnpj']}
                  campoLabelQueryPesquisa={'nomeRazaoSocial'}
                  nomeQueryPesquisa='getClientes'
                  nomeQueryPesquisaID='getClientePorId'
                  mensagemPesquisa='Procurando Clientes...'
                  disabled={[StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}
                  erros={erros}
                  onChange={( v ) => onChangeIdClienteRemetente( v )}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Divider />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography component="h6" variant="h6" align="left">
                  Faturamento
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idCliente'
                  fieldSet='idClienteFaturamento'
                  label='Cliente Faturamento'
                  dados={dados}
                  campoQueryPesquisaID='idCliente'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idCliente, nomeRazaoSocial, cpfCnpj, ieRG }'
                  camposParaExibir={['nomeRazaoSocial', 'ieRG', 'cpfCnpj']}
                  campoLabelQueryPesquisa='nomeRazaoSocial'
                  nomeQueryPesquisa='getClientes'
                  nomeQueryPesquisaID='getClientePorId'
                  mensagemPesquisa='Procurando Clientes...'
                  disabled={[StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idCliente'
                  fieldSet='idClienteProprietarioFaturamento'
                  label='Proprietário'
                  dados={dados}
                  campoQueryPesquisaID='idCliente'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idCliente, nomeRazaoSocial, cpfCnpj, ieRG }'
                  camposParaExibir={['nomeRazaoSocial', 'ieRG', 'cpfCnpj']}
                  campoLabelQueryPesquisa='nomeRazaoSocial'
                  nomeQueryPesquisa='getClientes'
                  nomeQueryPesquisaID='getClientePorId'
                  mensagemPesquisa='Procurando Clientes...'
                  disabled={[StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} md={6}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idPropriedade'
                  fieldSet='idPropriedadeFaturamento'
                  label='Propriedade'
                  dados={dados}
                  campoQueryPesquisaID='idPropriedade'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idPropriedade, nome, cnpj, ie }'
                  camposParaExibir={['nome', 'cnpj', 'ie']}
                  campoLabelQueryPesquisa='nome'
                  nomeQueryPesquisa='getPropriedades'
                  nomeQueryPesquisaID='getPropriedadePorId'
                  mensagemPesquisa='Procurando Propriedades...'
                  disabled={[StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  field='observacaoFaturamento'
                  label='Observação'
                  setState={setDados}
                  disabled={[StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}
                  erros={erros}
                  maxLength={50}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusFormCrud === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={![StatusForm.Excluindo, StatusForm.Exibindo].includes( statusFormCrud )}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarInclusaoEdicao()}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusFormCrud === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  {
                    icone: 'delete', toolTip: 'Excluir', onAcionador: ( rs: OrdemServicoCadastroInterface ) => {
                      clsCrud.btExcluir( rs )
                      // onChange( rsOrdemServicoAtual.idOrdemServico as number, StatusForm.Excluindo )
                    }
                  },
                  {
                    icone: 'create', toolTip: 'Alterar', onAcionador: ( rs: OrdemServicoCadastroInterface ) => {
                      clsCrud.btEditar( rs )
                      // onChange( rs.idOrdemServico as number, StatusForm.Editando )
                    }
                  },
                  { icone: 'visibility', toolTip: 'Visualizar', onAcionador: ( rs: OrdemServicoCadastroInterface ) => btExibirOS( rs.idOrdemServico as number ) }
                ]} />
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

        <ExibirJSONDev oque={['rsPesquisa', rsPesquisa]} />

      </Container>
    </>
  )
}