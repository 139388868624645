import React, { useState, useEffect, useContext, useRef } from 'react'
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { StatusForm } from '../../../Utils/ClsCrud'
import InputText from '../../../DevComponents/InputText'
import ClsValidacao from '../../../Utils/ClsValidacao'
import Condicional from '../../../Layout/Condicional'
import { OrdemServicoLoteInterface } from '../../../ImportBackend/Interfaces/OrdemServicoInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { clsUtils } from 'zlib-utils'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import { MaterialInterface } from '../../../ImportBackend/Interfaces/MaterialInterfaces'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import OrdensLotesAmostras from './OrdensLotesAmostras'
import ComboBox from '../../../DevComponents/ComboBox'
import { MunicipioSiafiInterface } from '../../../ImportBackend/Interfaces/MunicipioSiafiInterfaces'
import { TabValue } from './OrdensServicos'
import { StatusOSType } from '../../../ImportBackend/types/ConstantesDataTypes'

import HelpIcon from '@mui/icons-material/Help';
import HelpTeclas from './HelpTeclas'

enum StatusFormLote {
  Exibindo,
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando
}

export interface PropsInterface {
  idOrdemServico: number
  statusForm: StatusForm
  idCliente: number
  onChangeTab: ( proximaTab: TabValue ) => void
}

export interface PesquisaTextoInterface {
  descricao: string
}

export default function OrdensLotes ( { idCliente, idOrdemServico, statusForm, onChangeTab }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const [rsLotes, setRsLotes] = useState<Array<OrdemServicoLoteInterface>>( [] )

  const [statusFormLote, setStatusFormLote] = useState<StatusFormLote>( StatusFormLote.Pesquisando )
  const [rsPropriedades, setRsPropriedades] = useState<Array<PesquisaTextoInterface>>( [] )
  const [rsProprietarios, setRsProprietarios] = useState<Array<PesquisaTextoInterface>>( [] )
  const [rsCidades, setRsCidades] = useState<Array<MunicipioSiafiInterface>>( [] )

  const [exibirHelpTeclas, setExibirHelpTeclas] = useState<boolean>( false )

  const clsApi: BackEndAPI = new BackEndAPI()

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Proprietario',
      campo: 'proprietario'
    },
    {
      cabecalho: 'Propriedade',
      campo: 'propriedade'
    },
    {
      cabecalho: 'Cidade',
      campo: 'cidade'
    },
    {
      cabecalho: 'UF',
      campo: 'uf'
    },
    {
      cabecalho: 'Material',
      campo: 'Material',
      format: ( v ) => v.descricao
    },
    {
      cabecalho: 'QTD',
      campo: 'qtdAmostras',
      alinhamento: 'right'
    }
  ]

  const btConfirmarExclusao = () => {

    const query = `
        excluirLoteOrdemServico(idLote: ${dados.idLote}) {
          ok
          mensagem
        }
      `

    clsApi.mutationRespostaPadrao( query, 'excluirLoteOrdemServico', 'Excluindo Lote...', contexto ).then( rs => {

      if ( rs ) {
        pesquisarLotes()
        setStatusFormLote( StatusFormLote.Pesquisando )
      }

    } )

  }

  const btEditarExcluirExibir = ( idLote: number, status: StatusFormLote ) => {
    const query = `
        getLoteOrdemServicoPorId(idLote: ${idLote}) {
          idLote
          idOrdemServico
          proprietario
          propriedade
          cidade
          uf
          observacao
          qtdAmostras
          idMaterial
          laudoEmitido
        }
      `

    clsApi.query<OrdemServicoLoteInterface>( query, 'getLoteOrdemServicoPorId', 'Pesquisando Lote...', contexto ).then( rs => {

      if ( rs ) {
        setDados( rs )
        setStatusFormLote( status )
        setErros( {} )
      }

    } )
  }

  const btConfirmar = () => {

    const mensagem: string = ( statusFormLote === StatusFormLote.Incluindo ? 'Incluindo ' : 'Alterando ' ).concat( ' Lote...' )

    if ( validarDados() ) {

      const query = `
        updateLoteOrdemServico(dados: ${clsUtils.ConverterEmGql( { ...dados, idOrdemServico: idOrdemServico } )}) {
          ok
          mensagem
          id
        }
      `

      clsApi.mutationRespostaPadrao( query, 'updateLoteOrdemServico', mensagem, contexto ).then( rs => {

        if ( rs.ok ) {
          btEditarExcluirExibir( rs.id as number, StatusFormLote.Exibindo )
        }

      } )

    }

  }

  const ResetDados: OrdemServicoLoteInterface = {
    idLote: 0,
    idOrdemServico: 0,
    proprietario: '',
    propriedade: '',
    cidade: '',
    uf: '',
    observacao: '',
    idMaterial: 0,
    qtdAmostras: 0,
    status: StatusOSType.CADASTRADA,
    laudoEmitido: false
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    // retorno = clsValidacao.naoVazio( 'proprietario', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'propriedade', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'uf', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'qtdAmostras', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idMaterial', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const [dados, setDados] = useState<OrdemServicoLoteInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const pesquisarLotes = () => {
    if ( idOrdemServico > 0 ) {

      const query = `
        getLotesOrdemServico(idOrdemServico: ${idOrdemServico}) {
          idLote
          idOrdemServico
          proprietario
          propriedade
          cidade
          uf
          observacao
          idMaterial
          qtdAmostras
          laudoEmitido
          Material {
            descricao
          }
        }
    `

      clsApi.query<Array<OrdemServicoLoteInterface>>( query, 'getLotesOrdemServico', 'Pesquisando Notificações...', contexto ).then( rs => {
        setRsLotes( rs )
        if ( rs.length === 0 ) {
          setStatusFormLote( StatusFormLote.Incluindo )
        }
      } )
    }
  }

  useEffect( () => {

    pesquisarLotes()

    //eslint-disable-next-line
  }, [idOrdemServico] )

  const btCancelar = () => {
    // console.log( 'Dentro do Cancelar' )
    pesquisarLotes()
    setStatusFormLote( StatusFormLote.Pesquisando )
    setDados( ResetDados )
  }

  const btIncluir = () => {
    setDados( ResetDados )
    setErros( {} )
    setStatusFormLote( StatusFormLote.Incluindo )
  }

  // Suporte ao ComboBox de Propriedade e Proprietario para pesquisa de textos já digitados pelo usuário

  const emPesquisaTexto: React.MutableRefObject<boolean> = useRef( false )
  const abortControllerPesquisaTexto = useRef( new AbortController() )

  const pesquisarTexto = ( campo: string, pesquisa: string, setState: React.Dispatch<React.SetStateAction<PesquisaTextoInterface[]>> ) => {

    const query: string = `
      pesquisaCampoLote(campo: "${campo}", pesquisa: "${pesquisa}") {
        descricao
      }    
    `

    if ( emPesquisaTexto.current ) {
      abortControllerPesquisaTexto.current.abort()
    } else {
      emPesquisaTexto.current = true
    }

    abortControllerPesquisaTexto.current = new AbortController()

    clsApi.query<PesquisaTextoInterface[]>( query, 'pesquisaCampoLote', 'Pesquisando '.concat( pesquisa ), contexto, abortControllerPesquisaTexto.current ).then( rs => {
      emPesquisaTexto.current = false
      setState( rs )
    } )

  }

  const onKeyEnterPropriedade = ( pesquisa: string ) => {
    pesquisarTexto( 'propriedade', pesquisa, setRsPropriedades )
    setDados( { ...dados, propriedade: pesquisa } )
  }

  const onKeyEnterProprietario = ( pesquisa: string ) => {
    pesquisarTexto( 'proprietario', pesquisa, setRsProprietarios )
    setDados( { ...dados, proprietario: pesquisa } )
  }

  const onKeyEnterCidade = ( pesquisa: string ) => {
    const query: string = `
      pesquisaMunicipioSiafi(pesquisa: "${pesquisa}") {
        cidade
        uf
      }    
    `

    if ( emPesquisaTexto.current ) {
      abortControllerPesquisaTexto.current.abort()
    } else {
      emPesquisaTexto.current = true
    }

    abortControllerPesquisaTexto.current = new AbortController()

    clsApi.query<MunicipioSiafiInterface[]>( query, 'pesquisaMunicipioSiafi', 'Pesquisando '.concat( pesquisa ), contexto, abortControllerPesquisaTexto.current ).then( rs => {
      emPesquisaTexto.current = false
      setRsCidades( rs )
    } )
  }


  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

        <HelpTeclas exibir={exibirHelpTeclas} setExibir={setExibirHelpTeclas} />

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography component="h6" variant="h6" align="left">
            Lotes
            <Typography variant="body2" gutterBottom>
              Lotes da Ordem de Serviço
            </Typography>
          </Typography>
        </Grid>

        <Condicional condicao={idOrdemServico > 0 && statusFormLote !== StatusFormLote.Pesquisando}>

          <Grid item xs={12} md={4}>
            <ComboBox
              campoDescricao='descricao'
              campoID='proprietario'
              opcoes={rsProprietarios}
              dados={dados}
              erros={erros}
              field='proprietario'
              label='Proprietario'
              setState={setDados}
              onChange={( v: any ) => setDados( { ...dados, proprietario: v ? v.descricao : '' } )}
              mapKeyPress={[{ key: 'Enter', onKey: onKeyEnterProprietario }, { key: 'Tab', onKey: ( pesquisa: string ) => setDados( { ...dados, proprietario: pesquisa } ) }]}
              permitirNovaOpcao={true}
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
            />
          </Grid>

          <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>
            <ComboBox
              campoDescricao='descricao'
              campoID='propriedade'
              opcoes={rsPropriedades}
              dados={dados}
              erros={erros}
              field='propriedade'
              label='Propriedade'
              setState={setDados}
              onChange={( v: any ) => setDados( { ...dados, propriedade: v ? v.descricao : '' } )}
              mapKeyPress={[{ key: 'Enter', onKey: onKeyEnterPropriedade }, { key: 'Tab', onKey: ( pesquisa: string ) => setDados( { ...dados, propriedade: pesquisa } ) }]}
              permitirNovaOpcao={true}
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
            />
          </Grid>

          <Grid item xs={10} md={3} sx={{ pl: { md: 1 } }}>

            <ComboBox<MunicipioSiafiInterface>
              campoDescricao='cidade'
              campoID='cidade'
              opcoes={rsCidades}
              dados={dados}
              erros={erros}
              field='cidade'
              label='Cidade'
              setState={setDados}
              onChange={( v: MunicipioSiafiInterface ) => setDados( { ...dados, cidade: v ? v.cidade : '', uf: v ? v.uf : '' } )}
              mapKeyPress={[{ key: 'Enter', onKey: onKeyEnterCidade }, { key: 'Tab', onKey: ( pesquisa: string ) => setDados( { ...dados, cidade: pesquisa } ) }]}
              permitirNovaOpcao={true}
              formatarOption={( opcao: MunicipioSiafiInterface ) => opcao.cidade.concat( '-' ).concat( opcao.uf )}
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
            />

          </Grid>

          <Grid item xs={2} md={1} sx={{ pl: 1 }}>
            <InputText
              dados={dados}
              field='uf'
              label='UF'
              setState={setDados}
              erros={erros}
              mask='uf'
              tipo='uppercase'
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <InputText
              dados={dados}
              field='observacao'
              label='Observação'
              maxLength={50}
              setState={setDados}
              erros={erros}
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
            />
          </Grid>

          <Grid item xs={10} md={3} sx={{ pl: { md: 1 } }}>

            <PesquisarTabela<MaterialInterface>
              setState={setDados}
              field='idMaterial'
              fieldSet='idMaterial'
              label='Material'
              dados={dados}
              campoQueryPesquisaID='idMaterial'
              campoQueryPesquisa='pesquisa'
              camposRetornoQueryPesquisa='{idMaterial, descricao}'
              campoLabelQueryPesquisa='descricao'
              nomeQueryPesquisa='getMateriais'
              nomeQueryPesquisaID='getMaterialPorId'
              mensagemPesquisa='Procurando Materiais...'
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
              erros={erros}
              pesquisarTudoAoIniciar
            />

          </Grid>

          <Grid item xs={2} md={1} sx={{ pl: 1 }}>
            <InputText
              dados={dados}
              field='qtdAmostras'
              label='Qtd'
              setState={setDados}
              disabled={[StatusFormLote.Excluindo, StatusFormLote.Exibindo].includes( statusFormLote )}
              erros={erros}
              maxLength={5}
              tipo='number'
              mask='00000'
              textAlign='right'
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={() => setExibirHelpTeclas( true )}>
              <HelpIcon fontSize="inherit" />
            </IconButton>
          </Grid>

          <Grid item xs={12} sx={{ mt: 0 }}>
            <Condicional condicao={[StatusFormLote.Exibindo].includes( statusFormLote )}>
              <OrdensLotesAmostras
                idLote={dados.idLote as number}
                idMaterial={dados.idMaterial}
                idOrdemServico={idOrdemServico}
                statusForm={statusForm}
                onCancelar={btCancelar}
                idCliente={idCliente}
                onChangeTab={onChangeTab}
              />
            </Condicional>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>

            <Condicional condicao={[StatusFormLote.Excluindo].includes( statusFormLote )}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarExclusao()}>Confirmar Exclusão</Button>
            </Condicional>

            <Condicional condicao={[StatusFormLote.Editando, StatusFormLote.Incluindo].includes( statusFormLote )}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>{statusFormLote === StatusFormLote.Editando ? 'Alterar' : 'Incluir'}</Button>
            </Condicional>

            <Condicional condicao={[StatusFormLote.Excluindo, StatusFormLote.Editando].includes( statusFormLote )}>
              <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelar()}>Cancelar</Button>
            </Condicional>

          </Grid>

        </Condicional >

      </Grid >

      <Condicional condicao={statusFormLote === StatusFormLote.Pesquisando}>

        <Grid item xs={12} sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
          <Button variant='contained' onClick={() => btIncluir()}>Incluir Lote</Button>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <DataTable dados={rsLotes} cabecalho={cabecalho}
            acoes={statusForm !== StatusForm.Excluindo ? [
              { icone: 'delete', toolTip: 'Excluir', onAcionador: ( v: OrdemServicoLoteInterface ) => btEditarExcluirExibir( v.idLote as number, StatusFormLote.Excluindo ) },
              { icone: 'create', toolTip: 'Editar', onAcionador: ( v: OrdemServicoLoteInterface ) => btEditarExcluirExibir( v.idLote as number, StatusFormLote.Editando ) },
              { icone: 'visibility', toolTip: 'Visualizar', onAcionador: ( v: OrdemServicoLoteInterface ) => btEditarExcluirExibir( v.idLote as number, StatusFormLote.Exibindo ) }
            ] : []}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

      </Condicional >

    </>
  )

}