/*
interface SISTEMA_PERMISSOES_INTERFACE {
  [key: string]: {
    MODULO: string
    PERMISSOES: {
      [key: string]: string
      // EXCLUIR: string,

    }
  }
}
*/


// TODO - Criar Novo Crud
export interface SISTEMA_PERMISSOES_INTERFACE {

  PARAMETRO: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  LAUDO: {
    MODULO: string
    PERMISSOES: {
      EMISSAO: string
      REEMISSAO: string
      DOWNLOAD: string
    }
  }

  LEITURA: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  MAPAPRODUCAO: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
      CONSULTAR: string
      DIGITARRESULTADOS: string
    }
  }

  TIPOMAPAPRODUCAO: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  ORDENSDESERVICOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  TABELAPRECOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  ANALISES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  CLIENTES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  PARAMETROLEITURALABORATORIO: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  PROPRIEDADES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  CONDICOESPAGAMENTOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  FILIAIS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  MATERIAIS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  PRODUTOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  GRUPOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }

  USUARIOS: {
    MODULO: string
    PERMISSOES: {
      USUARIOS_GLOBAIS: string
      CADASTRO_USUARIO_FILIAL: string
    }
  }
}

export const MAXIMO_ERRO_LOGIN: number = 3

export const SISTEMA_PERMISSOES: SISTEMA_PERMISSOES_INTERFACE = {

  PARAMETRO: {
    MODULO: 'Parâmetros Gerais do Sistema',
    PERMISSOES: {
      MANUTENCAO: 'Aleração dos Parâmetros'
    }
  },

  LAUDO: {
    MODULO: 'Laudos de Análises',
    PERMISSOES: {
      EMISSAO: 'Emitir Laudos',
      REEMISSAO: 'Reemitir Laudos',
      DOWNLOAD: 'Consultar e Imprimir Laudos'
    }
  },

  LEITURA: {
    MODULO: 'Leitura de Resultado',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Leituras de Resultados'
    }
  },

  MAPAPRODUCAO: {
    MODULO: 'Mapa de Produção',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Mapas de Produção',
      CONSULTAR: 'Consultar Mapas de Produção',
      DIGITARRESULTADOS: 'Realizar Leituras de Amostras'
    }
  },

  TIPOMAPAPRODUCAO: {
    MODULO: 'Tipos de Mapa de Produção',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Tipos de Mapas de Produção'
    }
  },

  ORDENSDESERVICOS: {
    MODULO: 'Cadastro de Ordens de Serviços',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Ordens de Serviços'
    }
  },

  ANALISES: {
    MODULO: 'Cadastro de Análises',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Análises'
    }
  },

  TABELAPRECOS: {
    MODULO: 'Tabela de Preços',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Tabelas de Precos'
    }
  },

  CLIENTES: {
    MODULO: 'Cadastro de Clientes',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Clientes'
    }
  },

  PARAMETROLEITURALABORATORIO: {
    MODULO: 'Cadastro de Parâmetros de Leitura de Laboratório',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Parâmetros de Leitura de Laboratório'
    }
  },

  PROPRIEDADES: {
    MODULO: 'Cadastro de Propriedades',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Propriedades'
    }
  },

  CONDICOESPAGAMENTOS: {
    MODULO: 'Cadastro de Condições de Pagamentos',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Condições de Pagamentos'
    }
  },

  FILIAIS: {
    MODULO: 'Cadastro de Filiais',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Filiais'
    }
  },

  MATERIAIS: {
    MODULO: 'Cadastro de Materiais',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Materiais'
    }
  },

  PRODUTOS: {
    MODULO: 'Cadastro de Produtos',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Produtos'
    }
  },

  GRUPOS: {
    MODULO: 'Cadastro de Grupos de Usuários',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Grupos'
    }
  },

  USUARIOS: {
    MODULO: 'Cadastro de Usuário',
    PERMISSOES: {
      USUARIOS_GLOBAIS: 'Manutenção de Usuários Globais',
      CADASTRO_USUARIO_FILIAL: 'Manutenção de Usuários da Filial'
    }
  }
}
