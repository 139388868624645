import React, { useContext, useState, useEffect, useRef } from 'react'

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, tableCellClasses } from '@mui/material'

import { StyledTableRow } from '../../../DevComponents/DataTable'

import { styled } from '@mui/material/styles'

import DataTableLoteAmostraCampo from './DataTableLoteAmostraCampo'
import Condicional from '../../../Layout/Condicional'
import { AnaliseGrupoInterface, AnaliseInterface } from '../../../ImportBackend/Interfaces/AnaliseInterfaces'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import { AmostraAnaliseInterface, AmostraDeterminacaoInterface, AmostraInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { StatusAmostraType } from '../../../ImportBackend/types/ConstantesDataTypes'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'

export const calcularKeyDeterminacao = ( indiceLinha: number, indiceColuna: number ): string => {
  return "l".concat( indiceLinha.toString().trim() ).concat().concat( 'c' ).concat( indiceColuna.toString().trim() )
}

export interface DataTableCabecalhoAmostraInterface {
  campo: string
  cabecalho: string
  alinhamento?: 'left' | 'right' | 'center'
  largura?: number
  format?: ( arg: any, row: any ) => string | number | undefined
  edit: boolean
}

interface DataTableLoteAmostraInterface {
  dados: Array<AmostraInterface>
  cabecalho: Array<DataTableCabecalhoAmostraInterface>
  onConfirmarAlteracao: ( rs: Array<AmostraInterface> ) => void
  onRefreshDados: ( rs: Array<AmostraInterface>, indiceLinha: number, indiceColuna: number ) => void
  onCancelar: () => void
  indiceLinhaInicial: number
  indiceColunaInicial: number
  rsAnaliseGrupo: Array<AnaliseGrupoInterface>
  rsAnalises: Array<AnaliseInterface>
  rsDeterminacoes: Array<DeterminacaoInterface>
  idCliente: number
}
/*
'&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "grey",
  },


*/
const StyledTableCell = styled( TableCell )( ( { theme } ) => ( {
  [`&.${tableCellClasses.head}`]: {
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    // fontSize: 15,
    color: theme.palette.common.white
  },

  [`&.${tableCellClasses.head}:nth-of-type(1)`]: {
    // padding: 8,
    backgroundColor: theme.palette.primary.main,
    // fontSize: 15,
    color: theme.palette.common.white,
    position: 'sticky',
    left: 0
  },

  [`&.${tableCellClasses.body}`]: {
    padding: 5,
    fontSize: 15,
  },

  [`&.${tableCellClasses.body}:nth-of-type(1)`]: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#F0F0F0',
    // opacity: 2
    // background: 'gray'
  },

} ) )

enum KEYS {
  F2 = 'F2',
  ARROWLEFT = 'ArrowLeft',
  ARROWRIGHT = 'ArrowRight',
  ARROWDOWN = 'ArrowDown',
  ARROWUP = 'ArrowUp',
  TAB = 'Tab',
  ALT = 'Alt',
  COPIAR_TUDO = 'T',
  COPIAR_COLUNA = 'C',
  ENTER = 'Enter',
  DEL = 'Delete',
  ESC = 'Escape'
}

export default function DataTableLoteAmostra ( {
  dados = [],
  cabecalho = [],
  onConfirmarAlteracao,
  onRefreshDados,
  indiceLinhaInicial,
  indiceColunaInicial,
  rsAnaliseGrupo,
  rsAnalises,
  rsDeterminacoes,
  onCancelar,
  idCliente
}: DataTableLoteAmostraInterface ) {

  const [rsDados, setRsDados] = useState<Array<AmostraInterface>>( [] )
  const [refresh, setRefresh] = useState( 1 )
  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi: BackEndAPI = new BackEndAPI()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const rsRefreshCampo = useRef<Record<string, number>>( {} )

  const [page, setPage] = React.useState( 0 )
  const [rowsPerPage, setRowsPerPage] = React.useState( 35 )

  const foco: React.MutableRefObject<{
    linha: number
    coluna: number
    edicao: boolean
    nomeCampo: string
  }> = useRef( { linha: -1, coluna: -1, edicao: false, nomeCampo: '' } )

  const onAlterar = ( indiceRegistro: number,
    propriedade: string,
    novoValor: string | Array<AmostraAnaliseInterface> | Array<AmostraDeterminacaoInterface>,
    encerrarEdicao: boolean
  ) => {

    indiceRegistro += rowsPerPage * page

    // console.log( 'Dentro do On Alterar: ', propriedade, novoValor )

    const tmpDados = [...rsDados]

    tmpDados[indiceRegistro] = {
      ...rsDados[indiceRegistro],
      [propriedade]: novoValor
    }

    if ( propriedade === 'Determinacoes' || propriedade === 'Analises' ) {
      calcularValorAmostra( idCliente, tmpDados[indiceRegistro].idMaterial, tmpDados[indiceRegistro].Analises, tmpDados[indiceRegistro].Determinacoes ).then( ( rsValor ) => {
        if ( rsValor > 0 ) {
          tmpDados[indiceRegistro].valor = rsValor
          setRsDados( tmpDados )
        }
      } )
    } else {
      setRsDados( tmpDados )
    }

    if ( encerrarEdicao ) {
      foco.current.edicao = false
    }

  }

  const onAlterarAnaliseGrupo = ( indiceRegistro: number, novoValor: number ) => {

    const tmpDados = [...rsDados]

    tmpDados[indiceRegistro] = {
      ...rsDados[indiceRegistro],
      idAnaliseGrupo: 0,
      Analises: [],
      Determinacoes: [],
      valor: 0
    }

    setRsDados( tmpDados )

    foco.current.edicao = false

    if ( novoValor > 0 ) {

      const queryAnalisesGrupo: string = `
      
        getAnaliseGrupoPorId (idAnaliseGrupo: ${novoValor}) {
          Analises { 
            idAnalise
          }
        }

      `

      const queryDeterminacoesGrupo: string = `
        getDeterminacoesPorAnaliseGrupo (idAnaliseGrupo: ${novoValor}) {
          idDeterminacao
          adicionarValorSemFormula
        }
      `

      clsApi.query<AnaliseGrupoInterface>( queryAnalisesGrupo, 'getAnaliseGrupoPorId', 'Recebendo Analises...', contexto ).then( rsGrupo => {

        const tmpAnalises: Array<AmostraAnaliseInterface> = []

        rsGrupo.Analises.forEach( ( v ) => {

          tmpAnalises.push( {
            idAmostra: 0,
            idAnalise: v.idAnalise,
            idAmostraAnalise: 0,
            status: StatusAmostraType.CADASTRADA
          } )

        } )

        clsApi.query<Array<AmostraDeterminacaoInterface>>( queryDeterminacoesGrupo, 'getDeterminacoesPorAnaliseGrupo', 'Recebendo Determinacoes...', contexto ).then( rsDeterminacoes => {

          calcularValorAmostra( idCliente, tmpDados[indiceRegistro].idMaterial, tmpAnalises, rsDeterminacoes ).then( rsValorAmostra => {
            if ( rsValorAmostra > 0 ) {
              tmpDados[indiceRegistro] = {
                ...rsDados[indiceRegistro],
                idAnaliseGrupo: novoValor,
                Analises: tmpAnalises,
                Determinacoes: rsDeterminacoes,
                valor: rsValorAmostra
              }
            }

          } )

        } )

      } )

    }

  }

  const apagarConteudoCampo = ( indiceLinha: number, indiceColuna: number, nomeCampo: string ) => {

    indiceLinha += rowsPerPage * page

    if ( nomeCampo === 'idAnaliseGrupo' || nomeCampo === 'Analises' || nomeCampo === 'Determinacoes' ) {
      onAlterarAnaliseGrupo( indiceLinha, 0 )
    } else if ( cabecalho[indiceColuna].edit ) {
      onAlterar( indiceLinha, nomeCampo, '', true )
    }
  }

  const onCopiarColuna = ( indiceLinha: number, indiceColuna: number, nomeCampo: string, refreshDados: boolean = true ) => {

    indiceLinha += rowsPerPage * page

    if ( cabecalho[indiceColuna].edit ) {
      const conteudo: string = ( rsDados[indiceLinha] as unknown as Record<string, string> )[nomeCampo]

      rsDados.forEach( ( v, indice ) => {
        rsDados[indice] = { ...v, [nomeCampo]: conteudo }
      } )

      if ( nomeCampo === 'idAnaliseGrupo' && refreshDados ) {
        onCopiarColuna( indiceLinha, indiceColuna, 'Analises', false )
        onCopiarColuna( indiceLinha, indiceColuna, 'Determinacoes', false )
        onCopiarColuna( indiceLinha, indiceColuna, 'valor', false )
      }

      if ( nomeCampo === 'Analises' && refreshDados ) {
        onCopiarColuna( indiceLinha, indiceColuna, 'idAnaliseGrupo', false )
        onCopiarColuna( indiceLinha, indiceColuna, 'Determinacoes', false )
        onCopiarColuna( indiceLinha, indiceColuna, 'valor', false )
      }

      if ( nomeCampo === 'Determinacoes' && refreshDados ) {
        onCopiarColuna( indiceLinha, indiceColuna, 'idAnaliseGrupo', false )
        onCopiarColuna( indiceLinha, indiceColuna, 'Analises', false )
        onCopiarColuna( indiceLinha, indiceColuna, 'valor', false )
      }

      if ( refreshDados )
        onRefreshDados( rsDados, indiceLinha, indiceColuna )

    }

  }

  const onCopiarTudo = ( indiceLinha: number, indiceColuna: number ) => {

    indiceLinha += rowsPerPage * page

    let camposParaCopiar: Array<string> = []
    let dadosOrigem: Record<string, string | number> = {}

    cabecalho.forEach( v => {
      if ( v.edit || v.campo === 'valor' ) {
        camposParaCopiar.push( v.campo )
        dadosOrigem[v.campo] = ( rsDados[indiceLinha] as unknown as Record<string, string | number> )[v.campo]
      }
    } )

    rsDados.forEach( ( _v, indice ) => {
      camposParaCopiar.forEach( nomeCampo => {
        ( rsDados[indice] as unknown as Record<string, string | number> )[nomeCampo] = dadosOrigem[nomeCampo]
      } )
    } )

    onRefreshDados( rsDados, indiceLinha, indiceColuna )

  }

  const onCopiarRegistroAnterior = ( indiceLinha: number, indiceColuna: number, indiceCopia: number ) => {

    indiceLinha += rowsPerPage * page

    if ( indiceLinha > 0 && ( indiceLinha - indiceCopia ) >= 0 ) {

      let camposParaCopiar: Array<string> = []
      let dadosOrigem: Record<string, string | number> = {}

      cabecalho.forEach( v => {
        if ( v.edit || v.campo === 'valor' ) {
          camposParaCopiar.push( v.campo )
          dadosOrigem[v.campo] = ( rsDados[indiceLinha - indiceCopia] as unknown as Record<string, string | number> )[v.campo]
        }
      } )

      camposParaCopiar.forEach( nomeCampo => {
        ( rsDados[indiceLinha] as unknown as Record<string, string | number> )[nomeCampo] = dadosOrigem[nomeCampo]
      } )

      onRefreshDados( rsDados, indiceLinha, indiceColuna )

    }

  }

  const onCopiarCampoAnterior = ( indiceLinha: number, indiceColuna: number, nomeCampo: string, indiceCopia: number, refreshDados: boolean = true ) => {

    indiceLinha += rowsPerPage * page

    if ( indiceLinha > 0 && ( indiceLinha - indiceCopia ) >= 0 && cabecalho[indiceColuna].edit ) {

      ( rsDados[indiceLinha] as unknown as Record<string, string | number> )[nomeCampo] = ( rsDados[indiceLinha - indiceCopia] as unknown as Record<string, string | number> )[nomeCampo]

      if ( nomeCampo === 'idAnaliseGrupo' ) {
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Analises', indiceCopia, false )
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Determinacoes', indiceCopia, false )
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Valor', indiceCopia, false )
      }

      if ( nomeCampo === 'Analises' ) {
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'idAnaliseGrupo', indiceCopia, false )
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Determinacoes', indiceCopia, false )
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Valor', indiceCopia, false )
      }

      if ( nomeCampo === 'Determinacoes' ) {
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'idAnaliseGrupo', indiceCopia, false )
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Analises', indiceCopia, false )
        onCopiarCampoAnterior( indiceLinha, indiceColuna, 'Valor', indiceCopia, false )
      }

      if ( refreshDados ) onRefreshDados( rsDados, indiceLinha, indiceColuna )

    }

  }

  useEffect( () => {

    setRsDados( [...dados] )
    // setRefresh( refresh + 1 )

    //eslint-disable-next-line
  }, [dados] )

  useEffect( () => {
    if ( foco.current.linha >= 0 && foco.current.coluna >= 0 ) {
      setarFoco( foco.current.linha, foco.current.coluna )
    } else {
      setarFoco( indiceLinhaInicial, indiceColunaInicial )
    }
  } )

  const setarFoco = ( indiceLinha: number, indiceColuna: number ) => {

    const determinacaoDestino = document.getElementById( calcularKeyDeterminacao( indiceLinha, indiceColuna ) )

    if ( !foco.current.edicao && determinacaoDestino ) {
      foco.current.linha = indiceLinha
      foco.current.coluna = indiceColuna
      determinacaoDestino.focus()
    }

  }

  const editarCampo = ( indiceLinha: number, indiceColuna: number, nomeCampo: string ) => {

    // console.log( 'Dentro do Editar Campo' )

    if ( ( cabecalho.find( ( v ) => v.campo === nomeCampo ) as DataTableCabecalhoAmostraInterface ).edit ) {
      foco.current.edicao = true
      foco.current.nomeCampo = nomeCampo
      foco.current.linha = indiceLinha
      foco.current.coluna = indiceColuna

      // const determinacaoDestino = document.getElementById( calcularKeyDeterminacao( indiceLinha, indiceColuna ) )

      // console.log( determinacaoDestino.value )

      // rsRefreshCampo.current[calcularKeyDeterminacao( indiceLinha, indiceColuna )]++

      // console.log( 'Refresh 01' )
      setRefresh( refresh + 1 )
    }
  }

  const handleKeyDown = ( event: React.KeyboardEvent<HTMLDivElement>, indiceLinha: number, indiceColuna: number, nomeCampo: string ) => {

    const TeclasEdicao: RegExp = /^[a-zA-Z0-9!@#$%^&*()\-_=+{}[\]|\\:"'<>,.?/~` ]+$/

    // console.log( '\n\n', event.key )

    let proximaLinha: number = indiceLinha
    let proximaColuna: number = indiceColuna

    if ( event.key === KEYS.ARROWDOWN ) {
      proximaLinha++
    } else if ( event.key === KEYS.ARROWUP ) {
      proximaLinha--
    } else if ( ( event.key === KEYS.TAB && event.shiftKey ) || ( event.key === KEYS.ARROWLEFT && !foco.current.edicao ) ) {
      // console.log( 'Aqui 01' )
      proximaColuna--
    } else if ( ( event.key === KEYS.TAB && !event.shiftKey ) || ( event.key === KEYS.ARROWRIGHT && !foco.current.edicao ) ) {
      foco.current.edicao = false
      proximaColuna++
    } else if ( event.key.toUpperCase() === KEYS.COPIAR_TUDO && event.altKey ) {
      event.preventDefault()
      onCopiarTudo( indiceLinha, indiceColuna )
    } else if ( event.key.toUpperCase() === KEYS.COPIAR_COLUNA && event.altKey ) {
      event.preventDefault()
      onCopiarColuna( indiceLinha, indiceColuna, nomeCampo )
    } else if ( event.code.includes( 'Digit' ) && event.altKey && event.ctrlKey ) {

      const tecla = event.code.substring( 5 )

      if ( tecla >= '1' && tecla <= '9' ) {
        event.preventDefault()
        onCopiarCampoAnterior( indiceLinha, indiceColuna, nomeCampo, parseInt( tecla ) )
      }

    } else if ( event.key >= '1' && event.key <= '9' && event.altKey ) {
      event.preventDefault()
      onCopiarRegistroAnterior( indiceLinha, indiceColuna, parseInt( event.key ) )
    } else if ( event.key === KEYS.ALT ) {
      event.preventDefault()
    } else if ( ( event.key === KEYS.ENTER || event.key === KEYS.F2 || ( TeclasEdicao.test( event.key ) && event.key.length === 1 ) ) && !foco.current.edicao ) {
      // console.log( 'Editar Campo...' )
      editarCampo( indiceLinha, indiceColuna, nomeCampo )
    } else if ( ( event.key === KEYS.ENTER || event.key === KEYS.ESC ) && foco.current.edicao ) {
      setRefresh( refresh + 1 )
      foco.current.edicao = false

    } else if ( event.key === KEYS.DEL && event.altKey ) {
      // apagarConteudoCampo( indiceLinha, indiceColuna, nomeCampo )
      // Apagar Tudo...

      const tmpDados = [...rsDados]

      tmpDados.forEach( ( _v, indice ) => {
        tmpDados[indice].Analises = []
        tmpDados[indice].Determinacoes = []
        tmpDados[indice].identificacao01 = ''
        tmpDados[indice].identificacao02 = ''
        tmpDados[indice].identificacao03 = ''
        tmpDados[indice].identificacao04 = ''
        tmpDados[indice].observacao = ''
        tmpDados[indice].idAnaliseGrupo = 0
        tmpDados[indice].valor = 0
      } )


    } else if ( event.key === KEYS.DEL && !foco.current.edicao ) {
      apagarConteudoCampo( indiceLinha, indiceColuna, nomeCampo )
    }

    if ( proximaColuna !== indiceColuna || proximaLinha !== indiceLinha ) {

      if ( proximaColuna >= 0 && proximaLinha >= 0
        && cabecalho.length - 1 >= proximaColuna
        && dados.length - 1 >= proximaLinha
      ) {

        // setRefresh( refresh + 1 )

        const proximoDeterminacao = document.getElementById( calcularKeyDeterminacao( proximaLinha, proximaColuna ) )

        if ( proximoDeterminacao ) {
          setarFoco( proximaLinha, proximaColuna )
        }

      }

      event.preventDefault()

    }

  }

  const getDescricaoAnalise = ( campo: string, rs: Array<AmostraAnaliseInterface> ) => {

    if ( campo === "Analises" && rs && rs.length > 0 ) {

      let retorno: Array<string> = []

      rsAnalises.forEach( ( analise ) => {
        if ( rs.findIndex( ( v ) => v.idAnalise === analise.idAnalise ) >= 0 ) retorno.push( analise.descricao )
      } )

      return retorno.toString()

    } else {

      return ''

    }

  }

  const getDescricaoAnaliseGrupo = ( campo: string, rs: number ) => {

    if ( campo === "idAnaliseGrupo" ) {

      let indice: number = rsAnaliseGrupo.findIndex( ( v ) => v.idAnaliseGrupo === rs )

      if ( indice >= 0 ) {
        return rsAnaliseGrupo[indice].descricao
      } else {
        return ''
      }

    }
  }

  const getDescricaoDeterminacao = ( campo: string, rs: Array<AmostraDeterminacaoInterface> ) => {

    let retorno: string = ""

    if ( campo === "Determinacoes" && rs ) {

      if ( rs.length > 5 ) {

        retorno = rs.length.toString()

      } else {

        let descricoes: Array<string> = []

        rsDeterminacoes.forEach( ( determinacao ) => {
          if ( rs.findIndex( ( v ) => v.idDeterminacao === determinacao.idDeterminacao ) >= 0 ) descricoes.push( determinacao.simbolo )
        } )

        retorno = descricoes.toString()

      }

      return retorno

    }

  }

  const calcularValorAmostra = (
    idCliente: number,
    idMaterial: number,
    Analises: Array<AmostraAnaliseInterface>,
    Determinacoes: Array<AmostraDeterminacaoInterface>
  ): Promise<number> => {

    const rsAnalises: Array<number> = Analises.map( v => v.idAnalise )
    const rsDeterminacoes: Array<number> = Determinacoes.map( v => v.idDeterminacao )
    const rsDeterminacoesComAdicionalSemFormula: Array<number> = Determinacoes.filter( v => v.adicionarValorSemFormula ).map( v => v.idDeterminacao )

    const queryValor: string = `
      calcularValorAmostra (idCliente: ${idCliente}, idMaterial: ${idMaterial}, Analises: [${rsAnalises}], Determinacoes: [${rsDeterminacoes}], DeterminacoesAdicionalSemFormula: [${rsDeterminacoesComAdicionalSemFormula}] )
    `

    return clsApi.query<number>( queryValor, 'calcularValorAmostra', 'Calculando Valor...', contexto ).then( rsValor => {

      if ( rsValor <= 0 ) {
        setMensagemState( { ...mensagemState, mensagem: 'Tabela de Preços Incompleta!', exibirBotao: true, tipo: MensagemTipo.Error, exibir: true } )
      }

      return parseFloat( rsValor.toFixed( 2 ) )
    } )

  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage( newPage )
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage( parseInt( event.target.value, 10 ) )
    setPage( 0 )
  }


  return (
    <>
      <TableContainer component={Paper} >
        <Table >
          <TableHead>
            <StyledTableRow>
              {cabecalho.map( ( coluna, indice ) => (
                <StyledTableCell
                  key={indice}
                  align={coluna.alinhamento ? coluna.alinhamento : 'left'}
                  style={{ minWidth: coluna.largura }}>
                  {coluna.cabecalho}
                </StyledTableCell>
              ) )}
            </StyledTableRow>
          </TableHead>

          <TableBody key={refresh}>
            {
              ( rowsPerPage > 0
                ? rsDados.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                : rsDados )
                // rsDados
                // .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                .map( ( row, indiceLinha ) => {

                  let contadorRefreshCampo: number = 1

                  return (

                    <StyledTableRow
                      key={indiceLinha}
                    >
                      {
                        cabecalho.map( ( coluna, indiceColuna ) => {
                          const indiceRefresh: string = calcularKeyDeterminacao( indiceLinha, indiceColuna )
                          const valorInicialRefresh: number = 10000

                          rsRefreshCampo.current[calcularKeyDeterminacao( indiceLinha, indiceColuna )] = valorInicialRefresh + contadorRefreshCampo++ * valorInicialRefresh

                          // console.log( 'Dentro do MAP...' )

                          return (

                            <StyledTableCell
                              tabIndex={0}
                              key={rsRefreshCampo.current[indiceRefresh]}
                              align={coluna.alinhamento ? coluna.alinhamento : 'left'}
                              id={calcularKeyDeterminacao( indiceLinha, indiceColuna )}
                              onClick={( event ) => {
                                if ( !foco.current.edicao ) {
                                  editarCampo( indiceLinha, indiceColuna, coluna.campo )
                                }
                              }}
                              onKeyDown={( event ) => handleKeyDown( event, indiceLinha, indiceColuna, coluna.campo )}
                            >

                              <Condicional condicao={foco.current.coluna === indiceColuna && foco.current.linha === indiceLinha && foco.current.edicao}>

                                <DataTableLoteAmostraCampo
                                  nomeCampo={coluna.campo}
                                  value={coluna.format
                                    ? coluna.format( ( row as any )[coluna.campo], row )
                                    : ( row as any )[coluna.campo]}
                                  indiceColuna={indiceColuna}
                                  indiceLinha={indiceLinha}
                                  onAlterar={( indiceRegistro: number, propriedade: string, novoValor: string | Array<AmostraAnaliseInterface> | Array<AmostraDeterminacaoInterface>, encerrarEdicao: boolean ) => onAlterar( indiceRegistro, propriedade, novoValor, encerrarEdicao )}
                                  rsAnaliseGrupo={rsAnaliseGrupo}
                                  rsAnalises={rsAnalises}
                                  rsDeterminacoes={rsDeterminacoes}
                                  handleKeyDownEdicao={() => { console.log( 'Aqui Key Down....' ) }}
                                  onAlterarAnaliseGrupo={onAlterarAnaliseGrupo}
                                  emEdicao={foco.current.edicao}
                                />

                              </Condicional>

                              <Condicional condicao={!( foco.current.coluna === indiceColuna && foco.current.linha === indiceLinha && foco.current.edicao )}>

                                <Condicional condicao={!['Analises', 'Determinacoes', 'idAnaliseGrupo'].includes( coluna.campo )}>
                                  {coluna.format ? coluna.format( ( row as any )[coluna.campo], row ) : ( row as any )[coluna.campo]}
                                </Condicional>

                                <Condicional condicao={coluna.campo === "Analises"}>
                                  {getDescricaoAnalise( coluna.campo, ( row as any )[coluna.campo] )}
                                </Condicional>

                                <Condicional condicao={coluna.campo === "Determinacoes"}>
                                  {getDescricaoDeterminacao( coluna.campo, ( row as any )[coluna.campo] )}
                                </Condicional>

                                <Condicional condicao={coluna.campo === "idAnaliseGrupo"}>
                                  {getDescricaoAnaliseGrupo( coluna.campo, ( row as any )[coluna.campo] )}
                                </Condicional>


                              </Condicional>

                            </StyledTableCell>
                          )
                        }

                        )
                      }

                    </StyledTableRow>

                  )
                }

                )

            }

            <Condicional condicao={dados.length === 0}>
              <StyledTableRow>
                <StyledTableCell colSpan={cabecalho.length + 1} style={{ textAlign: 'center' }}>
                  <p>Não Há registros!!!!</p>
                </StyledTableCell>
              </StyledTableRow>
            </Condicional>

          </TableBody>
          <TableFooter>
            <StyledTableRow>
              < TablePagination
                labelRowsPerPage="Qtd: "
                rowsPerPageOptions={[35, 50, 65, { label: 'All', value: -1 }]}
                count={dados.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer >

      <ExibirJSONDev oque={['dados', rsDados]} exibir={false} />

      <Button variant='contained' onClick={() => { onConfirmarAlteracao( rsDados ) }} sx={{ mt: 5 }}>Confirmar Alterações</Button>

      <Button variant='contained' onClick={() => { onCancelar() }} sx={{ mt: 5, ml: 1 }}>Cancelar</Button>

    </>
  )

}