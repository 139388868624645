import React, { useContext, useState, useEffect, useRef } from 'react'

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, tableCellClasses } from '@mui/material'

import { StyledTableRow } from '../../../DevComponents/DataTable'

import { styled } from '@mui/material/styles'

import Condicional from '../../../Layout/Condicional'
import { DeterminacaoCalculoInterface, DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { GravarLeituraRealizadaInterface, rsAmostraParaLeituraInterface } from '../../../ImportBackend/Interfaces/LeituraInterfaces'
import LeituraCalculoComponent from './LeituraCalculoComponent'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
// import ClsFormatacao from '../../../Utils/ClsFormatacao'
import { clsUtils } from 'zlib-utils'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import ClsCalcularResultado from './ClsCalcularResultado'
import { UnidadeDeterminacaoInterface } from '../../../ImportBackend/Interfaces/UnidadeDeterminacaoInterfaces'
import ComboBox from '../../../DevComponents/ComboBox'

export const calcularKeyDeterminacao = ( indiceLinha: number, indiceColuna: number ): string => {
  return "l".concat( indiceLinha.toString().trim() ).concat().concat( 'c' ).concat( indiceColuna.toString().trim() )
}

export interface DataTableCabecalhoLeituraInterface {
  campo: string
  cabecalho: string
  alinhamento?: 'left' | 'right' | 'center'
  largura?: number
  format?: ( arg: any, row: any ) => string | number | undefined
  edit: boolean
  calculo?: DeterminacaoCalculoInterface
}

interface PropsInterface {
  rsDeterminacao: DeterminacaoInterface
  dados: Array<rsAmostraParaLeituraInterface>
  cabecalho: Array<DataTableCabecalhoLeituraInterface>
  onRefreshDados: ( rs: Array<rsAmostraParaLeituraInterface>, indiceLinha: number, indiceColuna: number ) => void
  rsColunasEditaveis: Array<number>
  rsParametros: Record<string, number>
  idMapaProducao: number
  idUsuario: number
  rsUnidadeDeterminacao: UnidadeDeterminacaoInterface
}
/*
'&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "grey",
  },


*/
const StyledTableCell = styled( TableCell )( ( { theme } ) => ( {
  [`&.${tableCellClasses.head}`]: {
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    // fontSize: 15,
    color: theme.palette.common.white
  },

  [`&.${tableCellClasses.head}:nth-of-type(1)`]: {
    // padding: 8,
    backgroundColor: theme.palette.primary.main,
    // fontSize: 15,
    color: theme.palette.common.white,
    position: 'sticky',
    left: 0
  },

  [`&.${tableCellClasses.body}`]: {
    padding: 5,
    fontSize: 15,
  },

  [`&.${tableCellClasses.body}:nth-of-type(1)`]: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#F0F0F0',
    // opacity: 2
    // background: 'gray'
  },

} ) )

enum KEYS {
  F2 = 'F2',
  ARROWLEFT = 'ArrowLeft',
  ARROWRIGHT = 'ArrowRight',
  ARROWDOWN = 'ArrowDown',
  ARROWUP = 'ArrowUp',
  TAB = 'Tab',
  ALT = 'Alt',
  COPIAR_TUDO = 'T',
  COPIAR_COLUNA = 'C',
  ENTER = 'Enter',
  DEL = 'Delete',
  ESC = 'Escape'
}

export default function LeituraDataTable ( {
  dados = [],
  cabecalho = [],
  onRefreshDados,
  rsColunasEditaveis,
  rsParametros,
  rsDeterminacao,
  idMapaProducao,
  idUsuario,
  rsUnidadeDeterminacao
}: PropsInterface ) {

  const [rsDados, setRsDados] = useState<Array<rsAmostraParaLeituraInterface>>( [] )
  const [refresh, setRefresh] = useState( 1 )
  // const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi: BackEndAPI = new BackEndAPI()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const rsRefreshCampo = useRef<Record<string, number>>( {} )

  // const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const clsCalcularResultado: ClsCalcularResultado = new ClsCalcularResultado()

  const [page, setPage] = React.useState( 0 )
  const [rowsPerPage, setRowsPerPage] = React.useState( 35 )

  // List para Unidade da Determinacao

  const [rsLstDeterminacao, setRsLstDeterminacao] = useState<Array<{ unidade: string }>>( [] )

  const preencherRsLstDeterminacao = () => {

    let tmpRsLstDeterminacao: Array<{ unidade: string }> = [{ unidade: rsUnidadeDeterminacao.unidade }]

    rsUnidadeDeterminacao.conversoes.forEach( rsConversao => {

      tmpRsLstDeterminacao.push( { unidade: rsConversao.unidade } )

    } )

    setRsLstDeterminacao( tmpRsLstDeterminacao )

  }

  const foco: React.MutableRefObject<{
    linha: number
    coluna: number
    edicao: boolean
    nomeCampo: string
  }> = useRef( { linha: -1, coluna: -1, edicao: false, nomeCampo: '' } )

  /**
   * Realiza a alteração dos dados e cálculo do resultado. Envia os dados para gravação no banco de dados
   * 
   * @param indiceRegistro 
   * @param propriedade 
   * @param novoValor 
   * @param encerrarEdicao 
   */
  const onDigitarResultado = ( indiceRegistro: number,
    propriedade: string,
    novoValor: number | string | boolean,
    encerrarEdicao: boolean
  ) => {

    // Inclui o campo digitado e os parâmetros atuais de leitura
    // console.log( 'Setup Propriedade: ', propriedade )

    const tmpDados = [...rsDados]
    indiceRegistro += rowsPerPage * page

    if ( tmpDados[indiceRegistro].leitura === null ) {
      tmpDados[indiceRegistro].leitura = {
        idAmostra: rsDados[indiceRegistro].idAmostra,
        idDeterminacao: rsDeterminacao.idDeterminacao as number,
        digitacao: {},
        idUnidadeDeterminacao: rsDeterminacao.idUnidadeDeterminacao,
        resultado: '',
        unidadeResultado: rsUnidadeDeterminacao.unidade
      }
    }

    if ( propriedade === "unidadeResultado" ) {
      tmpDados[indiceRegistro].leitura.unidadeResultado = ( novoValor as any ).unidade
      // console.log( 'Dentro de Unidade Resultado: ', ( novoValor as any ).unidade )
    } else {
      tmpDados[indiceRegistro].leitura.digitacao = { ...tmpDados[indiceRegistro].leitura.digitacao, [propriedade]: novoValor }
      tmpDados[indiceRegistro].edicao = true
    }

    tmpDados[indiceRegistro] = calcularResultadoAmostra( tmpDados[indiceRegistro] )

    setRsDados( tmpDados )

    if ( encerrarEdicao ) {
      foco.current.edicao = false
    }

  }

  const calcularResultadoAmostra = ( rsAmostraParaLeitura: rsAmostraParaLeituraInterface ): rsAmostraParaLeituraInterface => {

    // Se não tem Leitura, Inclui, se não usa a que tem (Recebeu filtro por determinação + amostra)

    rsAmostraParaLeitura = { ...rsAmostraParaLeitura }

    // Realiza o Cálculo do Resultado
    let { digitacao, calculoExecutadoComSucesso } = clsCalcularResultado.calcular(
      rsAmostraParaLeitura.leitura.digitacao,
      rsParametros,
      rsDeterminacao,
      rsUnidadeDeterminacao
    )

    // console.log( 'digitacao', digitacao, 'calculoExecutadoComSucesso: ', calculoExecutadoComSucesso )

    if ( calculoExecutadoComSucesso ) {
      rsAmostraParaLeitura.leitura.digitacao = digitacao

      // console.log( 'Digitacao: ', digitacao )

      //Conversão para Unidade
      const { resultado, unidadeResultado, conversaoRealizadaComSucesso } = clsCalcularResultado.converterResultadoUnidade(
        rsAmostraParaLeitura.leitura,
        rsUnidadeDeterminacao
      )

      if ( conversaoRealizadaComSucesso ) {
        rsAmostraParaLeitura.leitura.resultado = resultado
        rsAmostraParaLeitura.leitura.unidadeResultado = unidadeResultado
      } else {
        calculoExecutadoComSucesso = false
      }

    }

    rsAmostraParaLeitura.calculoExecutadoComSucesso = calculoExecutadoComSucesso
    rsAmostraParaLeitura.edicao = true

    return rsAmostraParaLeitura

  }

  const gravarLeituraRealizada = ( indice: number ) => {

    const rsGravarLeituraRealizada: GravarLeituraRealizadaInterface = {
      idUnidadeDeterminacao: rsDados[indice].leitura.idUnidadeDeterminacao,
      unidadeResultado: rsDados[indice].leitura.unidadeResultado,
      resultado: rsDados[indice].leitura.resultado,
      leitura: rsDados[indice].leitura.digitacao,
      idAmostra: rsDados[indice].idAmostra,
      idDeterminacao: rsDados[indice].leitura.idDeterminacao,
      idMapaProducao: idMapaProducao
    }

    const dados = {
      ...rsGravarLeituraRealizada,
      leitura: JSON.stringify( rsGravarLeituraRealizada.leitura )
    }

    const mutation = `
      gravarLeituraRealizada(leituraRealizada: ${clsUtils.ConverterEmGql( dados )}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'gravarLeituraRealizada', 'Gravando Leitura...', contexto ).then( rsOK => {

      if ( rsOK.ok ) {
        const tmpDados = [...rsDados]

        tmpDados[indice].edicao = false

        setRsDados( tmpDados )
      }

    } )

  }

  const apagarConteudoCampo = ( indiceLinha: number, indiceColuna: number, nomeCampo: string ) => {

    indiceLinha += rowsPerPage * page

    if ( cabecalho[indiceColuna].edit ) {
      onDigitarResultado( indiceLinha, nomeCampo, 0, true )
    }
  }

  const onCopiarColuna = ( indiceLinha: number, indiceColuna: number, nomeCampo: string, refreshDados: boolean = true ) => {

    let edicaoRealizada: boolean = false

    let tmpDados = [...rsDados]
    indiceLinha += rowsPerPage * page

    if ( cabecalho[indiceColuna].edit ) {
      edicaoRealizada = true

      if ( nomeCampo === 'idUnidadeDeterminacao' ) {

        // Copia campo unidadeResultado

        const conteudo: string = tmpDados[indiceLinha].leitura.unidadeResultado

        tmpDados.forEach( ( v, indice ) => {

          if ( tmpDados[indice].leitura === null ) {
            tmpDados[indice].leitura = {
              idAmostra: rsDados[indice].idAmostra,
              idDeterminacao: rsDeterminacao.idDeterminacao as number,
              digitacao: {},
              idUnidadeDeterminacao: rsDeterminacao.idUnidadeDeterminacao,
              resultado: '',
              unidadeResultado: rsUnidadeDeterminacao.unidade
            }
          }

          tmpDados[indice].leitura = { ...v.leitura, unidadeResultado: conteudo }

          tmpDados[indice] = calcularResultadoAmostra( tmpDados[indice] )

        } )

      } else {

        const conteudo: string | boolean | number = tmpDados[indiceLinha].leitura.digitacao[nomeCampo]

        tmpDados.forEach( ( v, indice ) => {

          if ( tmpDados[indice].leitura === null ) {
            tmpDados[indice].leitura = {
              idAmostra: rsDados[indice].idAmostra,
              idDeterminacao: rsDeterminacao.idDeterminacao as number,
              digitacao: {},
              idUnidadeDeterminacao: rsDeterminacao.idUnidadeDeterminacao,
              resultado: '',
              unidadeResultado: rsUnidadeDeterminacao.unidade
            }
          }

          tmpDados[indice].leitura.digitacao = { ...tmpDados[indice].leitura.digitacao, [nomeCampo]: conteudo }

          tmpDados[indice] = calcularResultadoAmostra( tmpDados[indice] )

        } )

      }

    }

    if ( refreshDados && edicaoRealizada ) {
      onRefreshDados( tmpDados, indiceLinha, indiceColuna )
    }

  }

  const onCopiarCampoAnterior = ( indiceLinha: number, indiceColuna: number, nomeCampo: string, indiceCopia: number ) => {

    // console.log( 'Copiar Campo Anterior' )

    indiceLinha += rowsPerPage * page
    const indiceOrigem: number = indiceLinha - indiceCopia

    const tmpDados = [...rsDados]
    let edicaoRealizada: boolean = false

    if ( indiceLinha > 0 && ( indiceOrigem ) >= 0 && cabecalho[indiceColuna].edit ) {

      if ( nomeCampo === 'idUnidadeDeterminacao' ) {
        tmpDados[indiceLinha].leitura.unidadeResultado = tmpDados[indiceOrigem].leitura.unidadeResultado
        tmpDados[indiceLinha] = calcularResultadoAmostra( tmpDados[indiceLinha] )
        edicaoRealizada = true
      } else {
        if ( tmpDados[indiceOrigem].leitura
          && tmpDados[indiceOrigem].leitura.digitacao
          && tmpDados[indiceOrigem].leitura.digitacao[nomeCampo]
        ) {

          if ( tmpDados[indiceLinha].leitura === null ) {
            tmpDados[indiceLinha].leitura = {
              idAmostra: tmpDados[indiceLinha].idAmostra,
              idDeterminacao: rsDeterminacao.idDeterminacao as number,
              digitacao: {},
              idUnidadeDeterminacao: rsDeterminacao.idUnidadeDeterminacao,
              resultado: '',
              unidadeResultado: rsUnidadeDeterminacao.unidade
            }
          }

          tmpDados[indiceLinha].leitura.digitacao[nomeCampo] = tmpDados[indiceOrigem].leitura.digitacao[nomeCampo]
          tmpDados[indiceLinha] = calcularResultadoAmostra( tmpDados[indiceLinha] )
          edicaoRealizada = true

        }
      }

      //      ( rsDados[indiceLinha] as unknown as Record<string, string | number> )[nomeCampo] = ( rsDados[indiceLinha - indiceCopia] as unknown as Record<string, string | number> )[nomeCampo]


      if ( edicaoRealizada ) {
        onRefreshDados( tmpDados, indiceLinha, indiceColuna )
      }

    }

  }

  useEffect( () => {

    // Preenche os campos para leitura / Preparação da tabela
    // Se não tem Leitura, Inclui, se não usa a que tem (Recebeu filtro por determinação + amostra)

    setRsDados( [...dados] )

    preencherRsLstDeterminacao()

    //eslint-disable-next-line
  }, [dados] )

  useEffect( () => {
    if ( !foco.current.edicao ) {

      if ( foco.current.linha >= 0 || foco.current.coluna >= 0 ) {

        // Pesquisa o indice da edição da coluna
        const indiceRsColunasEditaveis: number = rsColunasEditaveis.findIndex( v => v === foco.current.coluna )

        // Se chegou ao fim das colunas editáveis, acrescenta uma linha se puder
        if ( indiceRsColunasEditaveis === rsColunasEditaveis.length - 1 ) {

          if ( dados.length - 1 !== foco.current.linha )
            setarFoco( foco.current.linha + 1, rsColunasEditaveis[0] )

        } else {
          setarFoco( foco.current.linha, rsColunasEditaveis[indiceRsColunasEditaveis + 1] )
        }

      } else {

        if ( rsColunasEditaveis[0] )
          setarFoco( 0, rsColunasEditaveis[0] )
      }

    }

  } )

  const setarFoco = ( indiceLinha: number, indiceColuna: number ) => {

    const determinacaoDestino = document.getElementById( calcularKeyDeterminacao( indiceLinha, indiceColuna ) )

    if ( !foco.current.edicao && determinacaoDestino ) {
      foco.current.linha = indiceLinha
      foco.current.coluna = indiceColuna
      determinacaoDestino.focus()
    }

  }

  const editarCampo = ( indiceLinha: number, indiceColuna: number, nomeCampo: string ) => {

    if ( ( cabecalho.find( ( v ) => v.campo === nomeCampo ) as DataTableCabecalhoLeituraInterface ).edit ) {
      foco.current.edicao = true
      foco.current.nomeCampo = nomeCampo
      foco.current.linha = indiceLinha
      foco.current.coluna = indiceColuna

      setRefresh( refresh + 1 )
    }
  }

  const handleKeyDown = ( event: React.KeyboardEvent<HTMLDivElement>, indiceLinha: number, indiceColuna: number, nomeCampo: string ) => {

    const TeclasEdicao: RegExp = /^[a-zA-Z0-9!@#$%^&*()\-_=+{}[\]|\\:"'<>,.?/~` ]+$/

    // console.log( '\n\n', event.key )

    let proximaLinha: number = indiceLinha
    let proximaColuna: number = indiceColuna

    if ( event.key === KEYS.ARROWDOWN ) {
      proximaLinha++
    } else if ( event.key === KEYS.ARROWUP ) {
      proximaLinha--
    } else if ( ( event.key === KEYS.TAB && event.shiftKey ) || ( event.key === KEYS.ARROWLEFT && !foco.current.edicao ) ) {
      // console.log( 'Aqui 01' )
      proximaColuna--
    } else if ( ( event.key === KEYS.TAB && !event.shiftKey ) || ( event.key === KEYS.ARROWRIGHT && !foco.current.edicao ) ) {
      foco.current.edicao = false
      proximaColuna++
      /*
    } else if ( event.key.toUpperCase() === KEYS.COPIAR_TUDO && event.altKey ) {
      event.preventDefault()
      onCopiarTudo( indiceLinha, indiceColuna )
      */
    } else if ( event.key.toUpperCase() === KEYS.COPIAR_COLUNA && event.altKey ) {
      event.preventDefault()
      onCopiarColuna( indiceLinha, indiceColuna, nomeCampo )
    } else if ( event.code.includes( 'Digit' ) && event.altKey && event.ctrlKey ) {

      const tecla = event.code.substring( 5 )

      if ( tecla >= '1' && tecla <= '9' ) {
        event.preventDefault()
        onCopiarCampoAnterior( indiceLinha, indiceColuna, nomeCampo, parseInt( tecla ) )
      }
      /*
          } else if ( event.key >= '1' && event.key <= '9' && event.altKey ) {
            event.preventDefault()
            onCopiarRegistroAnterior( indiceLinha, indiceColuna, parseInt( event.key ) )
            */
    } else if ( event.key === KEYS.ALT ) {
      event.preventDefault()
    } else if ( ( event.key === KEYS.ENTER || event.key === KEYS.F2 || ( TeclasEdicao.test( event.key ) && event.key.length === 1 ) ) && !foco.current.edicao ) {
      editarCampo( indiceLinha, indiceColuna, nomeCampo )
    } else if ( ( event.key === KEYS.ENTER || event.key === KEYS.ESC ) && foco.current.edicao ) {
      setRefresh( refresh + 1 )
      foco.current.edicao = false

    } else if ( event.key === KEYS.DEL && event.altKey ) {
      // apagarConteudoCampo( indiceLinha, indiceColuna, nomeCampo )
      // Apagar Tudo...

      const tmpDados = [...rsDados]

      tmpDados.forEach( ( v, indice ) => {
        cabecalho.forEach( cab => {
          if ( cab.edit ) {
            //@ts-ignore
            tmpDados[indice][cab.campo] = ''
          }
        } )
      } )


    } else if ( event.key === KEYS.DEL && !foco.current.edicao ) {
      apagarConteudoCampo( indiceLinha, indiceColuna, nomeCampo )
    }

    if ( proximaColuna !== indiceColuna || proximaLinha !== indiceLinha ) {

      if ( proximaColuna >= 0 && proximaLinha >= 0
        && cabecalho.length - 1 >= proximaColuna
        && dados.length - 1 >= proximaLinha
      ) {

        // setRefresh( refresh + 1 )

        const proximoDeterminacao = document.getElementById( calcularKeyDeterminacao( proximaLinha, proximaColuna ) )

        if ( proximoDeterminacao ) {
          setarFoco( proximaLinha, proximaColuna )
        }

      }

      event.preventDefault()

    }

  }
  /*

  const getDescricaoDeterminacao = ( campo: string, rs: Array<AmostraDeterminacaoInterface> ) => {

    let retorno: string = ""

    if ( campo === "Determinacoes" && rs ) {

      if ( rs.length > 5 ) {

        retorno = rs.length.toString()

      } else {

        let descricoes: Array<string> = []

        rsDeterminacoes.forEach( ( determinacao ) => {
          if ( rs.findIndex( ( v ) => v.idDeterminacao === determinacao.idDeterminacao ) >= 0 ) descricoes.push( determinacao.simbolo )
        } )

        retorno = descricoes.toString()

      }

      return retorno

    }

  }

  */

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage( newPage )
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage( parseInt( event.target.value, 10 ) )
    setPage( 0 )
  }

  const habilitarBtSalvar = ( row: rsAmostraParaLeituraInterface ): boolean => {
    return row.edicao && row.calculoExecutadoComSucesso
  }

  const retornarCampoCalculado = ( row: rsAmostraParaLeituraInterface, coluna: DataTableCabecalhoLeituraInterface ): any => {

    if ( typeof row !== 'undefined'
      && typeof row.leitura !== 'undefined'
      && row.leitura !== null
      && typeof row.leitura.digitacao !== 'undefined'
    ) {

      let conteudo = row.leitura.digitacao[coluna.campo]

      if ( coluna.campo.toUpperCase() === 'RESULTADO' ) {
        conteudo = row.leitura.resultado
      }

      // console.log('Retorno: ',conteudo, JSON.stringify(row,null,4))

      return coluna.format ? coluna.format( conteudo, row ) : conteudo

    } else {

      return ''

    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              {cabecalho.map( ( coluna, indice ) => (
                <StyledTableCell
                  key={indice}
                  align={coluna.alinhamento ? coluna.alinhamento : 'left'}
                  style={{ minWidth: coluna.largura }}>
                  {coluna.cabecalho}
                </StyledTableCell>
              ) )}
            </StyledTableRow>
          </TableHead>

          <TableBody key={refresh}>
            {
              ( rowsPerPage > 0
                ? rsDados.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                : rsDados )
                // rsDados
                // .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                .map( ( row, indiceLinha ) => {

                  let contadorRefreshCampo: number = 1

                  return (

                    <StyledTableRow
                      key={indiceLinha}
                    >
                      {
                        cabecalho.map( ( coluna, indiceColuna ) => {
                          const indiceRefresh: string = calcularKeyDeterminacao( indiceLinha, indiceColuna )
                          const valorInicialRefresh: number = 10000

                          rsRefreshCampo.current[calcularKeyDeterminacao( indiceLinha, indiceColuna )] = valorInicialRefresh + contadorRefreshCampo++ * valorInicialRefresh

                          return (

                            <StyledTableCell
                              tabIndex={0}
                              key={rsRefreshCampo.current[indiceRefresh]}
                              align={coluna.alinhamento ? coluna.alinhamento : 'left'}
                              id={calcularKeyDeterminacao( indiceLinha, indiceColuna )}
                              onClick={( event ) => {
                                if ( !foco.current.edicao ) {
                                  editarCampo( indiceLinha, indiceColuna, coluna.campo )
                                }
                              }}
                              onKeyDown={( event ) => handleKeyDown( event, indiceLinha, indiceColuna, coluna.campo )}
                            >

                              <Condicional condicao={foco.current.coluna === indiceColuna && foco.current.linha === indiceLinha && foco.current.edicao}>

                                <Condicional condicao={!['IDUNIDADEDETERMINACAO'].includes( coluna.campo.toUpperCase() )}>
                                  <LeituraCalculoComponent
                                    exibirLabel={false}
                                    calculo={cabecalho[indiceColuna].calculo as DeterminacaoCalculoInterface}
                                    onAlterar={( propriedade: string, novoValor: number | string | boolean, encerrarEdicao: boolean ) => onDigitarResultado( indiceLinha, propriedade, novoValor, encerrarEdicao )}
                                    value={row.leitura && row.leitura.digitacao && row.leitura.digitacao[cabecalho[indiceColuna].campo] ? row.leitura.digitacao[cabecalho[indiceColuna].campo] : ''}
                                  />
                                </Condicional>

                                <Condicional condicao={['IDUNIDADEDETERMINACAO'].includes( coluna.campo.toUpperCase() )}>

                                  <ComboBox
                                    campoDescricao='unidade'
                                    campoID='unidade'
                                    opcoes={rsLstDeterminacao}
                                    dados={{ idUnidade: row && row.leitura && typeof row.leitura.unidadeResultado === 'string' ? row.leitura.unidadeResultado : '' }}
                                    field='idUnidade'
                                    label='Unidade'
                                    onChange={( v: string ) => onDigitarResultado( indiceLinha, 'unidadeResultado', v, false )}
                                  />

                                </Condicional>

                              </Condicional>

                              <Condicional condicao={!( foco.current.coluna === indiceColuna && foco.current.linha === indiceLinha && foco.current.edicao )}>
                                <Condicional condicao={typeof coluna.calculo === 'undefined' && !['IDUNIDADEDETERMINACAO', 'BTSALVAR'].includes( coluna.campo.toUpperCase() )}>
                                  {coluna.format ? coluna.format( ( row as any )[coluna.campo], row ) : ( row as any )[coluna.campo]}
                                </Condicional>

                                <Condicional condicao={typeof coluna.calculo !== 'undefined'}>
                                  {retornarCampoCalculado( row, coluna )}
                                </Condicional>

                                <Condicional condicao={['IDUNIDADEDETERMINACAO'].includes( coluna.campo.toUpperCase() )}>
                                  {row.leitura ? row.leitura.unidadeResultado : ''}
                                </Condicional>

                                <Condicional condicao={['BTSALVAR'].includes( coluna.campo.toUpperCase() )}>
                                  <Button disabled={!habilitarBtSalvar( row )} variant='contained' color='primary' onClick={() => gravarLeituraRealizada( indiceLinha )} sx={{ margin: 0, padding: 0 }}>
                                    Salvar
                                  </Button>
                                </Condicional>

                              </Condicional>

                            </StyledTableCell>
                          )
                        }

                        )
                      }

                    </StyledTableRow>

                  )
                }

                )

            }

            <Condicional condicao={dados.length === 0}>
              <StyledTableRow>
                <StyledTableCell colSpan={cabecalho.length + 1} style={{ textAlign: 'center' }}>
                  <p>Não Há registros!!!!</p>
                </StyledTableCell>
              </StyledTableRow>
            </Condicional>

          </TableBody>
          <TableFooter>
            <StyledTableRow>
              < TablePagination
                labelRowsPerPage="Qtd: "
                rowsPerPageOptions={[35, 50, 65, { label: 'All', value: -1 }]}
                count={dados.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer >

      {/*
      

      <Button variant='contained' onClick={() => { onConfirmarAlteracao( rsDados ) }} sx={{ mt: 5 }}>Confirmar Alterações</Button>

      <Button variant='contained' onClick={() => { onCancelar() }} sx={{ mt: 5, ml: 1 }}>Cancelar</Button>

          */}

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO} oque={['rsUnidadeDeterminacao', rsUnidadeDeterminacao, 'rsDados', rsDados]} />

    </>
  )

}