import { PermissoesInterface } from "../ImportBackend/Interfaces/SistemaModuloPermissaoInterfaces"
import { SISTEMA_PERMISSOES } from "../ImportBackend/types/AcessosDataTypes"
import ClsAcesso from "../Utils/ClsAcesso"

export interface MenuOpcoesInterface {
  descricao: string
  path: string
  icon: string
  filhos: Array<MenuOpcoesInterface>
  modulo: string
  permissao: string
}

// TODO - Novo Menu / Modulo 
const MENU: Array<MenuOpcoesInterface> = [
  {
    descricao: 'Cadastros',
    path: '',
    icon: 'app_registration_outlined',
    modulo: '',
    permissao: '',
    filhos: [
      {
        descricao: 'Propriedades',
        path: '/Propriedades',
        icon: 'add_business_rounded',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.PROPRIEDADES.MODULO,
        permissao: SISTEMA_PERMISSOES.PROPRIEDADES.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Clientes',
        path: '/Clientes',
        icon: 'people',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.CLIENTES.MODULO,
        permissao: SISTEMA_PERMISSOES.CLIENTES.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Ordens de Serviços',
        path: '/OrdensServicos',
        icon: 'list_alt',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.ORDENSDESERVICOS.MODULO,
        permissao: SISTEMA_PERMISSOES.ORDENSDESERVICOS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Materiais',
        path: '/Materiais',
        icon: 'co2',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.MATERIAIS.MODULO,
        permissao: SISTEMA_PERMISSOES.MATERIAIS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Unidades das Determinações',
        path: '/UnidadesDeterminacao',
        icon: 'vaccines_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.MATERIAIS.MODULO,
        permissao: SISTEMA_PERMISSOES.MATERIAIS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Filiais',
        path: '/Filiais',
        icon: 'domain_add_rounded',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.FILIAIS.MODULO,
        permissao: SISTEMA_PERMISSOES.FILIAIS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Condições de Pagamentos',
        path: '/CondicoesPagamentos',
        icon: 'add_card',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.CONDICOESPAGAMENTOS.MODULO,
        permissao: SISTEMA_PERMISSOES.CONDICOESPAGAMENTOS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Análises',
        path: '/Analises',
        icon: 'biotech',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.ANALISES.MODULO,
        permissao: SISTEMA_PERMISSOES.ANALISES.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Grupos de Análises',
        path: '/AnalisesGrupos',
        icon: 'water_voc',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.TABELAPRECOS.MODULO,
        permissao: SISTEMA_PERMISSOES.TABELAPRECOS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Tabela de Preços',
        path: '/TabelaPrecos',
        icon: 'price_check',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.TABELAPRECOS.MODULO,
        permissao: SISTEMA_PERMISSOES.TABELAPRECOS.PERMISSOES.MANUTENCAO
      }
    ]
  },
  {
    descricao: 'Produção',
    path: '',
    icon: 'app_registration_outlined',
    modulo: '',
    permissao: '',
    filhos: [
      /*
      {
        descricao: 'Mapas',
        path: '/Mapas',
        icon: 'people_alt_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.MAPAPRODUCAO.MODULO,
        permissao: SISTEMA_PERMISSOES.MAPAPRODUCAO.PERMISSOES.MANUTENCAO
      },
      */
      {
        descricao: 'Parâmetros do Laboratório',
        path: '/ParametroLeituraLaboratorio',
        icon: 'group_work',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.PARAMETROLEITURALABORATORIO.MODULO,
        permissao: SISTEMA_PERMISSOES.PARAMETROLEITURALABORATORIO.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Tipos de Mapas de Produção',
        path: '/TiposMapaProducao',
        icon: 'format_align_center_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.TIPOMAPAPRODUCAO.MODULO,
        permissao: SISTEMA_PERMISSOES.TIPOMAPAPRODUCAO.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Gerar Mapas',
        path: '/GerarMapas',
        icon: 'grid_on_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.MAPAPRODUCAO.MODULO,
        permissao: SISTEMA_PERMISSOES.MAPAPRODUCAO.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Leitura',
        path: '/ExibirMapas',
        icon: 'calculated_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.MAPAPRODUCAO.MODULO,
        permissao: SISTEMA_PERMISSOES.MAPAPRODUCAO.PERMISSOES.CONSULTAR
      },
      {
        descricao: 'Laudo',
        path: '/EmitirLaudo',
        icon: 'feed_oulined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.MAPAPRODUCAO.MODULO,
        permissao: SISTEMA_PERMISSOES.MAPAPRODUCAO.PERMISSOES.CONSULTAR
      },
      /*
      {
        descricao: 'Leituras',
        path: '/LeituraPesquisa',
        icon: 'people_alt_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.LEITURA.MODULO,
        permissao: SISTEMA_PERMISSOES.LEITURA.PERMISSOES.MANUTENCAO
      },
      */
    ]
  },
  {
    descricao: 'Sistema',
    path: '',
    icon: 'app_registration_outlined',
    modulo: '',
    permissao: '',
    filhos: [
      {
        descricao: 'Parâmetros',
        path: '/Parametros',
        icon: 'people_alt_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.PARAMETRO.MODULO,
        permissao: SISTEMA_PERMISSOES.PARAMETRO.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Grupos de Usuários',
        path: '/Grupos',
        icon: 'people_alt_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.GRUPOS.MODULO,
        permissao: SISTEMA_PERMISSOES.GRUPOS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Usuários',
        path: '/Usuarios',
        icon: 'person_outline_outlined',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.USUARIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.USUARIOS.PERMISSOES.CADASTRO_USUARIO_FILIAL
      }
    ]
  }
]

export default class ClsMenu {

  private retornoMenu: Array<MenuOpcoesInterface> = []

  public constructor( private Permissoes: Array<PermissoesInterface> = [] ) {
    this.retornoMenu = this.limparMenuSemFilhos( this.tratarPermissoes( MENU ) )
  }

  private limparMenuSemFilhos ( menu: Array<MenuOpcoesInterface> ): Array<MenuOpcoesInterface> {
    let retorno: Array<MenuOpcoesInterface> = []

    menu.forEach( ( v ) => {
      if (
        ( v.modulo
          && v.modulo.length > 0
          && v.permissao
          && v.permissao.length > 0 )
        || ( v.modulo
          && v.modulo.length > 0 )
        || ( !v.modulo && !v.permissao )
      ) {
        if ( v.filhos && v.filhos.length > 0 ) {
          v.filhos = this.limparMenuSemFilhos( v.filhos )
        }

        if ( ( v.filhos && v.filhos.length > 0 )
          || v.path.length > 0
        )
          retorno.push( v )
      }
    } )

    return retorno


  }
  private tratarPermissoes ( menu: Array<MenuOpcoesInterface> ): Array<MenuOpcoesInterface> {

    let retorno: Array<MenuOpcoesInterface> = []
    let clsAcesso = new ClsAcesso()

    menu.forEach( ( v ) => {
      if (
        ( v.modulo
          && v.modulo.length > 0
          && v.permissao
          && v.permissao.length > 0
          && clsAcesso.chkAcesso( this.Permissoes, v.modulo, v.permissao ) )
        || ( v.modulo
          && v.modulo.length > 0
          && v.permissao.length === 0
          && clsAcesso.chkAcessoModulo( this.Permissoes, v.modulo ) )
        || ( !v.modulo && !v.permissao )
      ) {
        if ( v.filhos && v.filhos.length > 0 ) {
          v.filhos = this.tratarPermissoes( v.filhos )
        }
        retorno.push( v )
      }
    } )

    return retorno

  }

  public get MenuOpcoes (): Array<MenuOpcoesInterface> {
    return this.retornoMenu
  }

}