import { ClienteContatoInterface } from "../../ImportBackend/Interfaces/ClienteInterfaces";

import { CanalNotificacaoType, CanalNotificacaoTypes } from '../../ImportBackend/types/ConstantesDataTypes';

import { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';

export const ResetDadosContato: ClienteContatoInterface = {
  tipo: CanalNotificacaoType.WHATSAPP,
  numeroEmail: '',
  nome: '',
  observacao: '',
  notificarFaturamento: false,
  enviarResultado: false,
  notificarRecebimentoAmostra: false
}

export const CabecalhoContatos: Array<DataTableCabecalhoInterface> = [
  {
    cabecalho: 'Tipo', campo: 'tipo',
    format: ( canal: string ) => CanalNotificacaoTypes.find( v => v.idCanal === canal )?.descricao as string
  },
  { cabecalho: 'Nome', campo: 'nome' },
  { cabecalho: 'Contato', campo: 'numeroEmail' },
  { cabecalho: 'Observacao', campo: 'observacao' },
  { cabecalho: 'Fat', campo: 'notificarFaturamento', format: ( v ) => v ? 'S' : 'N' },
  { cabecalho: 'Res', campo: 'enviarResultado', format: ( v ) => v ? 'S' : 'N' },
  { cabecalho: 'Rec', campo: 'notificarRecebimentoAmostra', format: ( v ) => v ? 'S' : 'N' }

]