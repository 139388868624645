import React, { useEffect, useContext, useState } from 'react'
import DataTableCrudJSON from '../../../DevComponents/DataTableCrudJSON'
import { ClienteContatoInterface } from '../../../ImportBackend/Interfaces/ClienteInterfaces'
import { CabecalhoContatos, ResetDadosContato } from '../ClientesContatosFormCls'
import ClientesContatosForm from '../ClientesContatosForm'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'

import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { clsUtils } from 'zlib-utils'

interface PropsInterface {
  idOrdemServico: number
  notificacoes: Array<ClienteContatoInterface>
  // onChangeTab: ( proximaTab: TabValue ) => void
}

export default function OrdensNotificacoes ( { idOrdemServico, notificacoes }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { setMensagemState } = contexto
  const clsApi: BackEndAPI = new BackEndAPI()

  const [rsNotificacoes, setRsNotificacoes] = useState<Array<ClienteContatoInterface>>( [] )

  const updateNotificacao = ( ( rs: ClienteContatoInterface[] ) => {

    const mutation = `
      updateOrdemServicoNotificacao(idOrdemServico: ${idOrdemServico}, dados: ${clsUtils.ConverterEmGql( rs )}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateOrdemServicoNotificacao', 'Atualizando Notificacões...', contexto ).then( rsUpdate => {

      if ( rsUpdate.ok ) {
        setRsNotificacoes( rs )
      } else {
        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Erro ao Atualizar Contatos',
          tipo: MensagemTipo.Error,
          titulo: 'Contato Não Atualizado!'
        } )
      }

    } )

  } )

  useEffect( () => {

    if ( idOrdemServico > 0 ) {
      setRsNotificacoes( notificacoes )
    }

    // eslint-disable-next-line
  }, [idOrdemServico] )

  return (
    <>
      <DataTableCrudJSON<ClienteContatoInterface>
        dadosIniciaisRegistro={ResetDadosContato}
        cabecalho={CabecalhoContatos}
        dados={rsNotificacoes}
        ComponenteInputCrud={ClientesContatosForm}
        tituloCrud='Notificações da OS'
        onChange={( rs ) => updateNotificacao( rs )}
      // onChange={onChangeContato}
      />
    </>
  )

}