import React, { useEffect, useState } from 'react'

import { Button, Container, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import { StatusForm } from '../../../DevComponents/DataTableCrudJSON'

import ClsValidacao from '../../../Utils/ClsValidacao'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import InputText from '../../../DevComponents/InputText'
import Condicional from '../../../Layout/Condicional'
import ComboBox from '../../../DevComponents/ComboBox'
import { TipoInformacaoDeterminacaoType, TipoInformacaoDeterminacaoTypes, VALIDACAO_MASCARA, VALIDACAO_NOME_VARAIVEL } from '../../../ImportBackend/types/ConstantesDataTypes'
import { DeterminacaoCalculoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'

interface PropsInterface<T> {
  onConfirmarExclusao: () => void
  onConfirmarEdicaoInclusao: ( registro: DeterminacaoCalculoInterface ) => void
  onCancelar: () => void
  open: boolean
  statusForm: StatusForm
  registro: DeterminacaoCalculoInterface
  titulo: string
  dadosIniciaisRegistro: T
}

export default function MateriaisDeterminacoesCalculoJSON<T> ( {
  onConfirmarExclusao,
  onConfirmarEdicaoInclusao,
  onCancelar,
  open,
  statusForm,
  registro,
  titulo
}: PropsInterface<T> ) {

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Sequencia',
    [StatusForm.Excluindo]: 'Exclusão Sequencia',
    [StatusForm.Exibindo]: 'Sequencia',
    [StatusForm.Editando]: 'Alteração de Dados de Sequencia',
    [StatusForm.Inicial]: 'Dados de Sequencia',
  }

  // const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  // const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<DeterminacaoCalculoInterface>( { ...registro } )

  useEffect( () => {
    setDados( { ...registro } )
  }, [registro] )

  const [erros, setErros] = useState( {} )

  const validarFormula = ( formula: string ): boolean => {
    const regExpSubstituir: RegExp = /\[\[(.*?)\]\]/g

    formula = formula.replace( regExpSubstituir, '10' )

    try {

      // eslint-disable-next-line no-eval
      const result = eval( formula )
      return typeof result === 'number'

    } catch {
      return false
    }

  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'tipoInformacao', dados, erros, retorno )

    if ( retorno && dados.tipoInformacao === TipoInformacaoDeterminacaoType.ENTRADA ) {
      retorno = clsValidacao.validarComRegEx( VALIDACAO_MASCARA, 'conteudo', dados, erros, retorno, true, 'Máscara Inválida!', 'Forneça uma Máscara!' )
    } else if ( retorno && dados.tipoInformacao === TipoInformacaoDeterminacaoType.CALCULO ) {
      retorno = validarFormula( dados.conteudo )
      if ( !retorno ) {
        erros.conteudo = 'Cálculo inválido!'
      }
    } else {
      retorno = clsValidacao.naoVazio( 'conteudo', dados, erros, retorno )
    }

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.validarComRegEx( VALIDACAO_NOME_VARAIVEL, 'variavel', dados, erros, retorno, false, 'Nome Inválido!', 'Forneça um nome válido!' )

    setErros( erros )

    return retorno

  }

  const btConfirmar = () => {

    if ( validarDados() ) {
      onConfirmarEdicaoInclusao( dados )
    }

  }

  return (
    <Dialog open={open} onClose={onCancelar}>

      <Container maxWidth="md" sx={{ my: 3 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                {titulo}
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => onCancelar()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='descricao'
              label='Descrição Usuário'
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>

            <ComboBox
              campoDescricao='descricao'
              campoID='idTipoInformacaoDeterminacao'
              opcoes={TipoInformacaoDeterminacaoTypes}
              dados={dados}
              field='tipoInformacao'
              label='Tipo'
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='conteudo'
              label='Conteúdo'
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={dados}
              field='variavel'
              label='Variável'
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>

            <Condicional condicao={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => onConfirmarExclusao()}>Confirmar</Button>
            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Excluindo}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            </Condicional>

            <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

          </Grid>

        </Paper>
      </Container>

    </Dialog>
  )

}