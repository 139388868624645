import React, { useState } from 'react'
import { AnaliseInterface } from '../../../ImportBackend/Interfaces/AnaliseInterfaces'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { AmostraAnaliseInterface } from '../../../ImportBackend/Interfaces/AmostraInterfaces'
import { StatusAmostraType } from '../../../ImportBackend/types/ConstantesDataTypes'

interface DataTableLoteAmostraAnalisePropsInterface {
  value: Array<AmostraAnaliseInterface>,
  exibir: boolean
  onClose: ( rs: Array<AmostraAnaliseInterface> ) => void
  rsAnalises: Array<AnaliseInterface>
}

export default function DataTableLoteAmostraAnalise ( {
  value,
  exibir,
  onClose,
  rsAnalises
}: DataTableLoteAmostraAnalisePropsInterface ) {

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idAnalise',
      cabecalho: 'Seleção',
      format: ( idAnalise ) => dados.findIndex( v => v.idAnalise === idAnalise ) >= 0 ? 'S' : 'N'
    },
    {
      campo: 'descricao',
      cabecalho: 'Análise'
    }
  ]

  const [dados, setDados] = useState<Array<AmostraAnaliseInterface>>( value )

  const btSelecionarAnalise = ( rsAnalise: AnaliseInterface ) => {
    let tmpDados: Array<AmostraAnaliseInterface> = [...dados]

    if ( typeof rsAnalise.idAnalise !== 'undefined' ) {

      const indice: number = tmpDados.findIndex( ( v ) => v.idAnalise === rsAnalise.idAnalise )

      if ( indice >= 0 ) {
        tmpDados.splice( indice, 1 )

      } else {
        tmpDados.push( {
          idAnalise: rsAnalise.idAnalise,
          idAmostra: 0,
          idAmostraAnalise: 0,
          status: StatusAmostraType.CADASTRADA
        } )
      }

      setDados( tmpDados )

    }

  }

  return (
    <>
      <Dialog open={exibir} onClose={() => onClose( dados )}>
        <DialogTitle>
          Análises
        </DialogTitle>
        <DialogContent>
          <DataTable
            onSelecionarLinha={( rs, _linha ) => btSelecionarAnalise( rs as AnaliseInterface )}
            dados={rsAnalises}
            cabecalho={Cabecalho}
          />
        </DialogContent>
        {/*
        <DialogActions>
        <Button onClick={(event) => { event.preventDefault();onClose( dados );}}>
        Fechar
        </Button>
        </DialogActions>
  */}
      </Dialog>
    </>
  )

}