import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import InputText from '../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import Condicional from '../../Layout/Condicional';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';
import { useNavigate } from 'react-router-dom';
import { ClienteContatoInterface, ClienteInterface } from '../../ImportBackend/Interfaces/ClienteInterfaces';
import ClsCrud, { StatusForm } from '../../Utils/ClsCrud';

import ClsValidacao from '../../Utils/ClsValidacao';
import ClsCep from '../../Utils/ClsCep';
import { TipoClienteType, TipoClienteTypes } from '../../ImportBackend/types/ConstantesDataTypes';
import RadioButton from '../../DevComponents/RadioButton';
import PesquisarTabela from '../../DevComponents/PesquisarTabela';
import BackEndAPI from '../../Services/BackEndAPI';
import { TabelaPrecoInterface } from '../../ImportBackend/Interfaces/TabelaPrecoInterfaces';
import DataTableCrudJSON from '../../DevComponents/DataTableCrudJSON';
import ClientesContatosForm from '../../View/Crud/ClientesContatosForm';
import { CabecalhoContatos, ResetDadosContato } from '../../View/Crud/ClientesContatosFormCls';
import CooperbomClientesPesquisaERP from './CooperbomClientesPesquisaERP';
import { CustomCooperbom_ConsultaClienteERPInterface } from '../../ImportBackend/Interfaces/CustomCooperbom_Interfaces';

interface PesquisaInterface {
  descricao: string
}

interface PropsInterface {
  onClose?: () => void
}

export default function CooperbomClientes ( { onClose }: PropsInterface ) {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )
  const clsApi = new BackEndAPI()

  const [pesquisandoERP, setPesquisandoERP] = useState<boolean>( false )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nomeRazaoSocial',
      cabecalho: 'Nome | Razão Social',
      alinhamento: 'left'
    },
    {
      campo: 'cpfCnpj',
      cabecalho: 'CPF | CNPJ',
      alinhamento: 'left'
    },
    {
      campo: 'cidade',
      cabecalho: 'Cidade',
      alinhamento: 'left'
    },
    {
      campo: 'uf',
      cabecalho: 'UF',
      alinhamento: 'left'
    }
  ]

  const ResetDados: ClienteInterface =
  {
    idCliente: 0,
    tipo: TipoClienteType.PF,
    nomeRazaoSocial: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    cpfCnpj: '',
    idCondicaoPagamento: 0,
    emailXMLNfe: '',
    codigoSiafi: '',
    ieRG: '',
    idTabelaPreco: 0,
    perDesconto: 0,
    contatos: []
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Cliente',
    [StatusForm.Excluindo]: 'Exclusão de Cliente Não Utilizado',
    [StatusForm.Pesquisando]: 'Clientes são utilizados para Cadastro do Sistema',
    [StatusForm.Editando]: 'Alteração de Dados de Clientes',
    [StatusForm.Exibindo]: 'Dados do Cliente'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<ClienteInterface>( ResetDados )
  const [cpfCnpjJaCadastrado, setCpfCnpjJaCadastrado] = useState<boolean>( false )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ClienteInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()


    if ( dados.tipo === TipoClienteType.PF ) {
      retorno = clsValidacao.eCPF( 'cpfCnpj', dados, erros, retorno, true )
    } else {
      retorno = clsValidacao.eCNPJ( 'cpfCnpj', dados, erros, retorno, true )
    }

    if ( retorno ) {
      if ( cpfCnpjJaCadastrado ) {
        retorno = false
        erros = { ...erros, cpfCnpj: 'Já Cadastrado!' }
      }
    }

    retorno = clsValidacao.naoVazio( 'nomeRazaoSocial', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'cep', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'tipo', dados, erros, retorno )
    // 
    // retorno = clsValidacao.naoVazio( 'endereco', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'bairro', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    // retorno = clsValidacao.eUF( 'uf', dados, erros, retorno, false )
    retorno = clsValidacao.naoVazio( 'idCondicaoPagamento', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'codigoSiafi', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idTabelaPreco', dados, erros, retorno )
    // retorno = clsValidacao.eEmail( 'emailXMLNfe', dados, erros, retorno, true )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<ClienteInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateCliente',
      excluirMutation: 'delCliente',
      campoId: 'idCliente',
      camposPesquisa: '{idCliente nomeRazaoSocial cpfCnpj cidade uf}',
      pesquisaQuery: 'getClientes',
      pesquisaPorId: 'getClientePorId',
      camposPesquisaPorId: '{idCliente tipo nomeRazaoSocial cep endereco bairro cidade uf cpfCnpj idCondicaoPagamento emailXMLNfe codigoSiafi ieRG idTabelaPreco perDesconto contatos { tipo numeroEmail nome observacao notificarFaturamento enviarResultado notificarRecebimentoAmostra }}'
    },
    {
      confirmando: 'Atualizando Cliente',
      erroCadastro: 'Erro ao Cadastrar Cliente',
      erroExclusao: 'Erro ao Excluir Cliente',
      erroPesquisa: 'Erro ao Pesquisar Cliente',
      pesquisando: 'Pesquisando Dados de Clientes...',
      sucessoCadastro: 'Cliente Cadastrado com sucesso!',
      atualizacaoSucesso: 'Cliente Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Cliente Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Cliente...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const alterarCep = ( novoCep: any ): void => {
    if ( novoCep.length === 10 ) {

      let clsCep: ClsCep = new ClsCep()

      clsCep.buscarCep( setMensagemState, novoCep ).then( rs => {
        if ( rs ) {

          setDados( {
            ...dados,
            cep: ( novoCep ).substring( 0, 10 ),
            bairro: ( rs.bairro ).substring( 0, 50 ),
            cidade: ( rs.localidade ).substring( 0, 35 ),
            endereco: ( rs.logradouro ).substring( 0, 50 ),
            uf: ( rs.uf ).substring( 0, 2 ),
            codigoSiafi: ( rs.siafi ).substring( 0, 11 )
          } )

        } else {
          setDados( { ...dados, cep: novoCep } )
        }
      } )

    }
  }

  const pesquisarCpfCnpj = ( valor: string ): Promise<boolean> => {

    return new Promise( ( resolve, _reject ) => {

      setDados( { ...dados, cpfCnpj: valor } )

      const query: string = `
        consutarClientePorCpfCnpj(cpfCnpj: "${valor}") {
          idCliente
        }
      `

      if ( valor.length > 0 && ( valor.length === 14 || valor.length === 18 ) ) {
        clsApi.query<ClienteInterface>( query, 'consutarClientePorCpfCnpj', 'Pesquisando documento...', contexto ).then( rs => {
          if ( rs && rs.idCliente && rs.idCliente !== dados.idCliente ) {
            setErros( { ...erros, cpfCnpj: 'Já Cadastrado!' } )
            setCpfCnpjJaCadastrado( true )
            resolve( false )
          } else {
            setErros( { ...erros, cpfCnpj: '' } )
            setCpfCnpjJaCadastrado( false )
            resolve( true )
          }
        } )
      } else {
        resolve( false )
      }

    } )
  }

  const onChangeContato = ( contatos: Array<ClienteContatoInterface> ) => {
    setDados( { ...dados, contatos: contatos } )
  }

  const incluirNovoCadastro = ( rs: CustomCooperbom_ConsultaClienteERPInterface ) => {

    let tmpDados = ResetDados

    tmpDados.cpfCnpj = rs.cnpjCPF.trim()

    if ( tmpDados.cpfCnpj.length === 14 ) {
      tmpDados.tipo = TipoClienteType.PF
    } else {
      tmpDados.tipo = TipoClienteType.PJ
    }

    tmpDados.nomeRazaoSocial = ( rs.nome ? rs.nome : '' ).substring( 0, 50 )
    tmpDados.cep = ( rs.cep ? rs.cep : '' ).substring( 0, 10 )
    tmpDados.endereco = ( rs.endereco ? rs.endereco : '' ).substring( 0, 50 )
    tmpDados.bairro = ( rs.bairro ? rs.bairro : '' ).substring( 0, 50 )
    tmpDados.cidade = ( rs.cidade ? rs.cidade : '' ).substring( 0, 35 )
    tmpDados.uf = ( rs.uf ? rs.uf : '' ).substring( 0, 2 )

    setDados( tmpDados )

    setStatusForm( StatusForm.Incluindo )
    setPesquisandoERP( false )

  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Clientes
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => onClose ? onClose() : clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={pesquisandoERP}>
              <Grid item xs={12}>
                <CooperbomClientesPesquisaERP onIncluir={( rs: CustomCooperbom_ConsultaClienteERPInterface ) => incluirNovoCadastro( rs )} />
              </Grid>
            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando && !pesquisandoERP}>

              <Grid item xs={12} sm={10}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar Cadastro do Laboratório'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => setPesquisandoERP( true )}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando && !pesquisandoERP}>

              <Grid item xs={12}>
                <RadioButton
                  campoID='idTipoCliente'
                  campoDescricao='descricao'
                  opcoes={TipoClienteTypes}
                  dados={dados}
                  disabled={true}
                  field='tipo'
                  setState={setDados}
                  label='Tipo do Cliente'
                  row
                />

              </Grid>
              <Grid item xs={12} sm={4} md={3}>

                <InputText
                  dados={dados}
                  field='cpfCnpj'
                  label={dados.tipo === TipoClienteType.PJ ? 'CNPJ' : 'CPF'}
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={18}
                  mask={dados.tipo === TipoClienteType.PJ ? 'cnpj' : 'cpf'}
                  onChange={( v: string ) => pesquisarCpfCnpj( v )}
                />

              </Grid>

              <Grid item xs={12} sm={8} md={9} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='nomeRazaoSocial'
                  label={dados.tipo === TipoClienteType.PJ ? 'Razão Social' : 'Nome'}
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sm={3} md={2}>

                <InputText
                  dados={dados}
                  field='cep'
                  label='Cep'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  mask='cep'
                  onChange={( novoCep ) => alterarCep( novoCep )}
                />

              </Grid>

              <Grid item xs={12} sm={9} md={10} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='endereco'
                  label='Endereço'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} md={5}>

                <InputText
                  dados={dados}
                  field='bairro'
                  label='Bairro'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={12} sm={9} md={5} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='cidade'
                  label='Cidade'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={35}
                />

              </Grid>

              <Grid item xs={12} sm={3} md={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='uf'
                  label='UF'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  mask='uf'
                  tipo='uppercase'
                />

              </Grid>

              <Grid item xs={12} sm={4}>

                <InputText
                  dados={dados}
                  field='codigoSiafi'
                  label='Siafi'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={11}
                  mask='0000'
                />

              </Grid>

              <Grid item xs={12} sm={8} sx={{ pl: { sm: 1 } }}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idCondicaoPagamento'
                  fieldSet='idCondicaoPagamento'
                  label='Condição de Pagamento'
                  dados={dados}
                  campoQueryPesquisaID='idCondicaoPagamento'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idCondicaoPagamento, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getCondicoesPagamentos'
                  nomeQueryPesquisaID='getCondicaoPagamentoPorId'
                  mensagemPesquisa='Procurando Condição de Pagamento...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={12} sm={8}>

                <PesquisarTabela<TabelaPrecoInterface>
                  setState={setDados}
                  field='idTabelaPreco'
                  fieldSet='idTabelaPreco'
                  label='Tabela de Preços'
                  dados={dados}
                  campoQueryPesquisaID='idTabelaPreco'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idTabelaPreco, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getTabelaPrecos'
                  nomeQueryPesquisaID='getTabelaPrecoPorId'
                  mensagemPesquisa='Procurando Tabela de Preços...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='perDesconto'
                  label='% Desconto'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  tipo='currency'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} >

                <InputText
                  dados={dados}
                  field='emailXMLNfe'
                  label='Email XML'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={255}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTableCrudJSON<ClienteContatoInterface>
                  dadosIniciaisRegistro={ResetDadosContato}
                  cabecalho={CabecalhoContatos}
                  dados={dados.contatos}
                  ComponenteInputCrud={ClientesContatosForm}
                  tituloCrud='Contatos do Cliente'
                  onChange={onChangeContato}
                /*
                acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: ( _rs: ClienteContatoInterface, index: number ) => btExcluirClienteContato( index ) },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: ( _rs: ClienteContatoInterface, index: number ) => btAlterarClienteContato( index ) }
                ]}
                */
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando && !pesquisandoERP}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}