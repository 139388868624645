import React, { useState } from 'react'

import { Button, Container, Dialog, Grid, IconButton, Paper } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useNavigate } from 'react-router-dom'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import OrdensCrud from './OrdensCrud'
import { StatusForm } from '../../../Utils/ClsCrud'
import Condicional from '../../../Layout/Condicional'
import OrdensLotes from './OrdensLotes'
import ViewOrdemServicoAtual from './ViewOrdemServicoAtual'
import OrdensValores from './OrdensValores'
import { ClienteContatoInterface } from '../../../ImportBackend/Interfaces/ClienteInterfaces'
import OrdensNotificacoes from './OrdensNotificacoes'
import Clientes from '../Clientes'
import Propriedades from '../Propriedades'
import { APP_CLIENTE } from '../../../ImportBackend/Config/emDesenvolvimento'
import CooperbomClientes from '../../../Customizacao/Cooperbom/CooperbomClientes'

export enum TabValue {
  ORDEMSERVICO = 'OS',
  NOTIFICACOES = 'NOT',
  LOTES = 'LOT',
  VALORES = 'VAL'
}

export interface PropsInterfaceOrdensServicos {
  rsOrdemServicoAtual: RsOrdemServicoAtualInterface
  statusForm: StatusForm
  tipoNotificacao: 'FATURAMENTO' | 'AMOSTRA'
}

export interface RsOrdemServicoAtualInterface {
  idOrdemServico: number
  remetente: string
  filial: string
  qtdAmostras: number
  idClienteFaturamento: number
  notificacoes: Array<ClienteContatoInterface>
}

export default function OrdensServicos () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )
  const [tab, setTab] = useState<string>( TabValue.ORDEMSERVICO )

  const ResetDados: RsOrdemServicoAtualInterface = {
    idOrdemServico: 0,
    remetente: '',
    filial: '',
    qtdAmostras: 0,
    idClienteFaturamento: 0,
    notificacoes: []
  }

  const [rsOrdemServicoAtual, setRsOrdemServicoAtual] = useState<RsOrdemServicoAtualInterface>( ResetDados )

  const navigate = useNavigate()

  const changeTab = ( _event: React.SyntheticEvent, newTab: string ) => {
    setTab( newTab );
  };

  const btFechar = () => {
    navigate( '/' )
  }

  const onCloseViewOrdemServicoAtual = () => {
    setStatusForm( StatusForm.Pesquisando )

    setTab( TabValue.ORDEMSERVICO )

    setRsOrdemServicoAtual( {
      idOrdemServico: 0,
      filial: '',
      remetente: '',
      qtdAmostras: 0,
      idClienteFaturamento: 0,
      notificacoes: []
    } )

  }

  const [exibirCadastroCliente, setExibirCadastroCliente] = useState<boolean>( false )
  const [exibirCadastroPropriedade, setExibirCadastroPropriedade] = useState<boolean>( false )

  return (
    <>

      <Container maxWidth={false} sx={{ mt: 5 }}>

        <Dialog
          fullWidth
          fullScreen
          open={exibirCadastroCliente}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Condicional condicao={APP_CLIENTE !== 'COOPERBOM'}>
            <Clientes onClose={() => setExibirCadastroCliente( false )} />
          </Condicional>

          <Condicional condicao={APP_CLIENTE === 'COOPERBOM'}>
            <CooperbomClientes onClose={() => setExibirCadastroCliente( false )} />
          </Condicional>

        </Dialog>

        <Dialog
          fullWidth
          fullScreen
          open={exibirCadastroPropriedade}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Propriedades onClose={() => setExibirCadastroPropriedade( false )} />
        </Dialog>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
            {/*

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Ordens de Serviços
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>
  */}

            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mb: 3 }}>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tabs
                    value={tab}
                    onChange={changeTab}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="Entrada de Amostras"
                  >
                    <Tab value={TabValue.ORDEMSERVICO} label="Ordem de Serviços" />
                    <Tab disabled={statusForm !== StatusForm.Exibindo} value={TabValue.NOTIFICACOES} label="Notificacoes" />
                    <Tab disabled={statusForm !== StatusForm.Exibindo} value={TabValue.LOTES} label="Lotes" />
                    <Tab disabled={statusForm !== StatusForm.Exibindo} value={TabValue.VALORES} label="Valores" />
                  </Tabs>
                  <Condicional condicao={tab === TabValue.ORDEMSERVICO}>
                    <Button
                      onClick={() => setExibirCadastroCliente( true )}
                    >Clientes</Button>
                    <Button
                      onClick={() => setExibirCadastroPropriedade( true )}
                    >Propriedades</Button>
                  </Condicional>
                  <IconButton onClick={() => btFechar()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            <Condicional condicao={statusForm === StatusForm.Exibindo}>
              <ViewOrdemServicoAtual
                rsOrdemServicoAtual={rsOrdemServicoAtual}
                onClose={() => onCloseViewOrdemServicoAtual()}
              />
            </Condicional>

            <Condicional condicao={tab === TabValue.ORDEMSERVICO && statusForm !== StatusForm.Exibindo}>
              <OrdensCrud
                rsOrdemServicoAtual={rsOrdemServicoAtual}
                statusForm={statusForm}
                onChangeTab={( proximaTab: TabValue ) => setTab( proximaTab )}
                onChange={( rsOrdemServico, statusForm ) => {
                  setRsOrdemServicoAtual( rsOrdemServico )
                  setStatusForm( statusForm )
                }
                } />
            </Condicional>

            <Condicional condicao={tab === TabValue.NOTIFICACOES}>
              <OrdensNotificacoes
                idOrdemServico={rsOrdemServicoAtual.idOrdemServico}
                notificacoes={rsOrdemServicoAtual.notificacoes}
              />
            </Condicional>

            <Condicional condicao={tab === TabValue.LOTES}>
              <OrdensLotes
                idOrdemServico={rsOrdemServicoAtual.idOrdemServico}
                statusForm={statusForm}
                idCliente={rsOrdemServicoAtual.idClienteFaturamento}
                onChangeTab={( proximaTab: TabValue ) => setTab( proximaTab )}
              />
            </Condicional>

            <Condicional condicao={tab === TabValue.VALORES}>
              <OrdensValores
                rsOrdemServicoAtual={rsOrdemServicoAtual}
                statusForm={statusForm}
                onChangeTab={( proximaTab: TabValue ) => setTab( proximaTab )}
              />
            </Condicional>

          </Grid>
        </Paper>
      </Container>

    </>
  )

}