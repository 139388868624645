import React, { useEffect, useState, useContext } from 'react'
import { Divider, Grid, Typography } from '@mui/material';

import { AnaliseDeterminacaoInterface } from '../../ImportBackend/Interfaces/AnaliseInterfaces';
import { DeterminacaoInterface } from '../../ImportBackend/Interfaces/DeterminacaoInterfaces';
import BackEndAPI from '../../Services/BackEndAPI';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import { MensagemTipo } from '../../GlobalStates/MensagemState';
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';
import Condicional from '../../Layout/Condicional';
import { StatusForm } from '../../Utils/ClsCrud';

interface DeterminacoesSelecaoInterface {
  idDeterminacao: number
  nome: string
  simbolo: string
  selecionado: boolean
}

export interface PropsInterface {
  idMaterial: number,
  Determinacoes: Array<AnaliseDeterminacaoInterface>
  refresh: number
  onChange: ( Determinacoes: Array<AnaliseDeterminacaoInterface> ) => void
  statusForm: StatusForm
}

export default function AnaliseDeterminacoes ( { idMaterial, Determinacoes, refresh, onChange, statusForm }: PropsInterface ) {

  const [rsDeterminacoesDisponiveis, setRsDeterminacoesDisponiveis] = useState<Array<DeterminacaoInterface>>( [] )

  const [rsDeterminacoesSelecao, setRsDeterminacoesSelecao] = useState<Array<DeterminacoesSelecaoInterface>>( [] )

  const abortController: AbortController = new AbortController()

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  const { mensagemState, setMensagemState } = contexto

  const clsApi: BackEndAPI = new BackEndAPI()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'selecionado',
      cabecalho: 'Seleção',
      format: ( v ) => v ? 'S' : 'N'
    },
    {
      campo: 'nome',
      cabecalho: 'Determinacao'
    },
    {
      campo: 'simbolo',
      cabecalho: 'Símbolo',
    }
  ]

  const searchDeterminacoesPorMaterial = () => {
    const query = `
      getDeterminacoesPorMaterial(idMaterial: ${idMaterial}) {
        idDeterminacao
        nome
        simbolo
      }
    `

    clsApi.query<Array<DeterminacaoInterface>>( query, 'getDeterminacoesPorMaterial', 'Recebendo Materiais...', contexto, abortController ).then( rsDeterminacoesDisponiveis => {

      setRsDeterminacoesDisponiveis( rsDeterminacoesDisponiveis )
      refreshSelecao( rsDeterminacoesDisponiveis )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Determinacoes de Materiais!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  const refreshSelecao = ( rsDeterminacoesDisponiveis: Array<DeterminacaoInterface> ) => {
    let tmpSelecao: Array<DeterminacoesSelecaoInterface> = []

    rsDeterminacoesDisponiveis.forEach( ( v ) => {
      if ( v.idDeterminacao )
        tmpSelecao.push( {
          idDeterminacao: v.idDeterminacao,
          nome: v.nome,
          selecionado: Determinacoes.findIndex( ( s ) => s.idDeterminacao === v.idDeterminacao ) >= 0,
          simbolo: v.simbolo
        } )
    } )

    setRsDeterminacoesSelecao( tmpSelecao )

  }

  useEffect( () => {

    if ( idMaterial > 0 )

      searchDeterminacoesPorMaterial()

    // eslint-disable-next-line
  }, [idMaterial] )

  const btSelecionarDeterminacao = ( rsDeterminacao: DeterminacaoInterface ) => {
    if ( statusForm !== StatusForm.Excluindo ) {

      let retorno: Array<AnaliseDeterminacaoInterface> = []

      rsDeterminacoesSelecao.forEach( v => {

        // Se está selecionado e não foi clicado ou se não está selecionado e foi clicado... coloca como selecionado...

        if (
          ( v.idDeterminacao !== rsDeterminacao.idDeterminacao && v.selecionado ) ||
          ( v.idDeterminacao === rsDeterminacao.idDeterminacao && !v.selecionado )
        ) {
          retorno.push( {
            idDeterminacao: v.idDeterminacao
          } )
        }
      } )

      onChange( retorno )

    }

  }

  useEffect( () => {

    refreshSelecao( rsDeterminacoesDisponiveis )

    // eslint-disable-next-line
  }, [refresh] )

  return (
    <>
      <Condicional condicao={idMaterial > 0}>
        <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Typography component="h6" variant="h6" align="left">
              Determinações da Análise
              <Typography variant="body2" gutterBottom>
                Determinações Químicos disponibilizados para a Análise
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DataTable
              onSelecionarLinha={( rs, _linha ) => btSelecionarDeterminacao( rs as DeterminacaoInterface )}
              dados={rsDeterminacoesSelecao}
              cabecalho={Cabecalho}
            />
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

      </Condicional>

    </>
  )

}