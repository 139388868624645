import React, { useContext, useState, useEffect } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Typography } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import ComboBox from '../../../DevComponents/ComboBox'
import { StatusForm } from '../../../Utils/ClsCrud'
import InputText from '../../../DevComponents/InputText'
import ClsValidacao from '../../../Utils/ClsValidacao'
import Condicional from '../../../Layout/Condicional'
import { GetDeterminacoesEspeciaisTabelaPrecosTypeInterface, TabelaPrecoDeterminacaoEspecialInterface } from '../../../ImportBackend/Interfaces/TabelaPrecoInterfaces'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import { MaterialInterface } from '../../../ImportBackend/Interfaces/MaterialInterfaces'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

enum StatusFormDeterminacao {
  Inicial,
  Editando
}

export interface PropsInterface {
  idTabelaPreco: number | undefined
  statusForm: StatusForm
  onChange: ( Determinacoes: Array<TabelaPrecoDeterminacaoEspecialInterface> ) => void
}

export default function TabelaPrecosDeterminacoesEspeciais ( { idTabelaPreco, statusForm, onChange }: PropsInterface ) {

  const [statusFormDeterminacao, setStatusFormDeterminacao] = useState<StatusFormDeterminacao>( StatusFormDeterminacao.Inicial )

  const clsFormatcao: ClsFormatacao = new ClsFormatacao()
  const [rsDeterminacoes, setRsDeterminacoes] = useState<Array<GetDeterminacoesEspeciaisTabelaPrecosTypeInterface>>( [] )
  const [rsDeterminacoesMaterial, setRsDeterminacoesMaterial] = useState<Array<DeterminacaoInterface>>( [] )

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  // const { mensagemState, setMensagemState } = contexto

  const clsApi: BackEndAPI = new BackEndAPI()

  const pesquisarDeterminacoesDoMaterial = ( rsMaterial: MaterialInterface ) => {

    if ( rsMaterial.idMaterial && rsMaterial.idMaterial > 0 ) {

      setDados( { ...dados, idMaterial: rsMaterial.idMaterial, descricao: rsMaterial.descricao } )

      const query = `
      getDeterminacoesPorMaterial(idMaterial: ${rsMaterial.idMaterial}) {
        idDeterminacao
        nome
        simbolo
      }
    `

      clsApi.query<Array<DeterminacaoInterface>>( query, 'getDeterminacoesPorMaterial', 'Pesquisando Determinacoes...', contexto ).then( rsDeterminacoes => {

        setRsDeterminacoesMaterial( rsDeterminacoes )

      } )

    } else {
      setDados( { ...dados, idMaterial: 0, descricao: '' } )
      setRsDeterminacoesMaterial( [] )
    }


  }

  const pesquisarDeterminacoesDaTabelaPrecos = () => {

    if ( idTabelaPreco && idTabelaPreco > 0 ) {

      const query = `
      getDeterminacoesEspeciaisTabelaPrecos(idTabelaPreco: ${idTabelaPreco}) {
        idDeterminacao
        idMaterial
        nome
        simbolo
        descricao
        valor
      }
    `

      clsApi.query<Array<GetDeterminacoesEspeciaisTabelaPrecosTypeInterface>>( query, 'getDeterminacoesEspeciaisTabelaPrecos', 'Pesquisando Determinacoes Especiais...', contexto ).then( rsDeterminacoes => {

        setRsDeterminacoes( rsDeterminacoes )

      } )

    } else {
      setRsDeterminacoes( [] )
    }


  }

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Material',
      campo: 'descricao'
    },
    {
      cabecalho: 'Determinacao',
      campo: 'nome'
    },
    {
      cabecalho: 'Valor',
      campo: 'valor',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatcao.currency( v )
    }
  ]

  const btExcluirDeterminacao = ( oque: GetDeterminacoesEspeciaisTabelaPrecosTypeInterface ) => {

    const newDeterminacoes: Array<TabelaPrecoDeterminacaoEspecialInterface> = []
    const newRsDeterminacoes = rsDeterminacoes.filter( v => !( oque.idDeterminacao === v.idDeterminacao ) )

    newRsDeterminacoes.forEach( el => {
      newDeterminacoes.push( {
        idDeterminacao: el.idDeterminacao,
        valor: el.valor,
        idTabelaPreco: 0
      } )
    } )

    onChange( newDeterminacoes )
    setRsDeterminacoes( newRsDeterminacoes )

    setDados( ResetDados )

  }

  const btEditarDeterminacao = ( oque: GetDeterminacoesEspeciaisTabelaPrecosTypeInterface ) => {
    pesquisarDeterminacoesDoMaterial( { idMaterial: oque.idMaterial, descricao: oque.descricao } as MaterialInterface )
    setDados( oque )
    setStatusFormDeterminacao( StatusFormDeterminacao.Editando )
  }

  const btCancelarEdicaoDeterminacao = () => {
    setStatusFormDeterminacao( StatusFormDeterminacao.Inicial )
    setDados( ResetDados )
  }

  const btConfirmarInclusaoEdicaoDeterminacao = () => {

    if ( validarDados() ) {

      const indexEdicao: number = rsDeterminacoes.findIndex( v => ( dados.idDeterminacao === v.idDeterminacao ) )

      const newDeterminacoes: Array<TabelaPrecoDeterminacaoEspecialInterface> = []
      const newRsDeterminacoes = [...rsDeterminacoes]

      newRsDeterminacoes.forEach( el => {
        newDeterminacoes.push( {
          idDeterminacao: el.idDeterminacao,
          valor: el.valor,
          idTabelaPreco: 0
        } )
      } )

      if ( indexEdicao < 0 ) {


        newDeterminacoes.push( {
          idDeterminacao: dados.idDeterminacao,
          valor: dados.valor,
          idTabelaPreco: 0
        } )

        const rsMaterialDeterminacao: DeterminacaoInterface = rsDeterminacoesMaterial.find( ( v ) => v.idDeterminacao === dados.idDeterminacao ) as DeterminacaoInterface

        newRsDeterminacoes.push( {
          descricao: dados.descricao,
          idDeterminacao: dados.idDeterminacao,
          idMaterial: dados.idMaterial,
          nome: rsMaterialDeterminacao.nome,
          simbolo: rsMaterialDeterminacao.simbolo,
          valor: dados.valor
        } )

      } else {

        newRsDeterminacoes[indexEdicao].valor = dados.valor
        newDeterminacoes[indexEdicao].valor = dados.valor
        setStatusFormDeterminacao( StatusFormDeterminacao.Inicial )

      }

      onChange( newDeterminacoes )
      setRsDeterminacoes( newRsDeterminacoes )

      setDados( ResetDados )

    }

  }

  const ResetDados: GetDeterminacoesEspeciaisTabelaPrecosTypeInterface = {
    descricao: '',
    idMaterial: 0,
    nome: '',
    simbolo: '',
    idDeterminacao: 0,
    valor: 0
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idDeterminacao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valor', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const [dados, setDados] = useState<GetDeterminacoesEspeciaisTabelaPrecosTypeInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  useEffect( () => {
    pesquisarDeterminacoesDaTabelaPrecos()
    //eslint-disable-next-line
  }, [idTabelaPreco] )

  const fecharEdicao = () => setStatusFormDeterminacao( StatusFormDeterminacao.Inicial )

  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography component="h6" variant="h6" align="left">
            Determinacoes Especiais
            <Typography variant="body2" gutterBottom>
              Valores unitários por Determinação Especial
            </Typography>
          </Typography>
        </Grid>

        <Dialog
          fullWidth
          open={statusFormDeterminacao === StatusFormDeterminacao.Editando}
          onClose={fecharEdicao}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>Altere o Valor</DialogTitle>
          <DialogContent>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='descricao'
                label='Material'
                setState={setDados}
                disabled
                erros={erros}
              />
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='nome'
                label='Nome'
                setState={setDados}
                disabled
                erros={erros}
              />
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='valor'
                label='Valor'
                setState={setDados}
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12} alignSelf='center' sx={{ pl: 1, textAlign: 'center', mt: 3 }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarInclusaoEdicaoDeterminacao()}>Confirmar</Button>
              <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelarEdicaoDeterminacao()}>Cancelar</Button>
            </Grid>

          </DialogContent>

        </Dialog>

        <Condicional condicao={statusForm !== StatusForm.Excluindo}>

          <Grid item xs={4}>

            <PesquisarTabela<MaterialInterface>
              onChange={( v ) => pesquisarDeterminacoesDoMaterial( v )}
              setState={setDados}
              field='idMaterial'
              fieldSet='idMaterial'
              label='Material'
              dados={dados}
              campoQueryPesquisaID='idMaterial'
              campoQueryPesquisa='pesquisa'
              camposRetornoQueryPesquisa='{idMaterial, descricao}'
              campoLabelQueryPesquisa='descricao'
              nomeQueryPesquisa='getMateriais'
              nomeQueryPesquisaID='getMaterialPorId'
              mensagemPesquisa='Procurando Materiais...'
              disabled={statusForm === StatusForm.Excluindo}
              erros={erros}
              pesquisarTudoAoIniciar

            />

          </Grid>

          <Grid item xs={4} sx={{ pl: 1 }}>

            <ComboBox
              campoDescricao='nome'
              campoID='idDeterminacao'
              opcoes={rsDeterminacoesMaterial}
              dados={dados}
              erros={erros}
              field='idDeterminacao'
              label='Determinacao'
              setState={setDados}
              disabled={statusForm === StatusForm.Excluindo}
            />

          </Grid>

          <Grid item xs={3} sx={{ pl: 1 }}>

            <InputText
              dados={dados}
              field='valor'
              label='Valor'
              setState={setDados}
              disabled={statusForm === StatusForm.Excluindo}
              erros={erros}
              tipo='currency'
              textAlign="right"
            />

          </Grid>

          <Grid item xs={1} alignSelf='center' sx={{ pl: 1, textAlign: 'center', mt: 3 }}>
            <IconButton onClick={() => btConfirmarInclusaoEdicaoDeterminacao()}>
              <Icon>add</Icon>
            </IconButton>
          </Grid>

        </Condicional>

      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <DataTable dados={rsDeterminacoes} cabecalho={cabecalho}
          acoes={statusForm !== StatusForm.Excluindo ? [
            { icone: 'delete', toolTip: 'Excluir', onAcionador: btExcluirDeterminacao },
            { icone: 'create', toolTip: 'Editar', onAcionador: btEditarDeterminacao },
          ] : []}
        />
      </Grid>

      {/*
      <Grid item xs={12}>
        <Divider />
        <h4>Dados</h4>
        {JSON.stringify( dados )}
        <h4>rsDeterminacoes</h4>
        {JSON.stringify( rsDeterminacoes )}
      </Grid>
      */}

    </>
  )

}