import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import InputText from '../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import Condicional from '../../Layout/Condicional';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';
import { useNavigate } from 'react-router-dom';
import { FilialInterface } from '../../ImportBackend/Interfaces/FilialInterfaces';
import ClsCrud from '../../Utils/ClsCrud';

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao';
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento';
import ClsCep from '../../Utils/ClsCep';

interface PesquisaInterface {
  descricao: string
}

export default function Filiais () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'cidade',
      cabecalho: 'Cidade',
      alinhamento: 'left'
    },
    {
      campo: 'bairro',
      cabecalho: 'Bairro',
      alinhamento: 'left'
    },
    {
      campo: 'endereco',
      cabecalho: 'Endereço',
      alinhamento: 'left'
    },
    {
      campo: 'proximaAmostraOutros',
      cabecalho: 'Outros',
      alinhamento: 'right'
    },
    {
      campo: 'proximaAmostraSolo',
      cabecalho: 'Solo',
      alinhamento: 'right'
    }
  ]

  const ResetDados: FilialInterface = {
    idFilial: 0,
    descricao: '',
    bairro: '',
    cep: '',
    cidade: '',
    cnpj: '',
    endereco: '',
    uf: '',
    proximaAmostraOutros: 0,
    proximaAmostraSolo: 0,
    codigoSiafi: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Filial',
    [StatusForm.Excluindo]: 'Exclusão de Filial Não Utilizado',
    [StatusForm.Pesquisando]: 'Filiais são utilizadas Cadastro de Boletos',
    [StatusForm.Editando]: 'Alteração de Dados de Filiais',
    [StatusForm.Exibindo]: 'Dados da Filial'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<FilialInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<FilialInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'bairro', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cep', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    retorno = clsValidacao.eCNPJ( 'cnpj', dados, erros, retorno, false )
    retorno = clsValidacao.naoVazio( 'endereco', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'proximaAmostraOutros', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'proximaAmostraSolo', dados, erros, retorno )
    retorno = clsValidacao.eUF( 'uf', dados, erros, retorno, false )
    retorno = clsValidacao.naoVazio( 'codigoSiafi', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<FilialInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateFilial',
      excluirMutation: 'delFilial',
      campoId: 'idFilial',
      camposPesquisa: '{idFilial descricao bairro cidade endereco proximaAmostraOutros proximaAmostraSolo}',
      pesquisaQuery: 'getFiliais',
      pesquisaPorId: 'getFilialPorId',
      camposPesquisaPorId: '{idFilial descricao bairro cep cidade cnpj endereco uf proximaAmostraOutros proximaAmostraSolo codigoSiafi}'
    },
    {
      confirmando: 'Atualizando Filial',
      erroCadastro: 'Erro ao Cadastrar Filial',
      erroExclusao: 'Erro ao Excluir Filial',
      erroPesquisa: 'Erro ao Pesquisar Filial',
      pesquisando: 'Pesquisando Dados de Filiais...',
      sucessoCadastro: 'Filial Cadastrada com sucesso!',
      atualizacaoSucesso: 'Filial Atualizada com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Filial Excluída com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Filial...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const alterarCep = ( novoCep: any ): void => {
    if ( novoCep.length === 10 ) {

      let clsCep: ClsCep = new ClsCep()

      clsCep.buscarCep( setMensagemState, novoCep ).then( rs => {
        if ( rs ) {

          setDados( {
            ...dados,
            cep: novoCep,
            bairro: rs.bairro,
            cidade: rs.localidade,
            endereco: rs.logradouro,
            uf: rs.uf,
            codigoSiafi: rs.siafi
          } )

        } else {
          setDados( { ...dados, cep: novoCep } )
        }
      } )

    }
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Filiais
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12} sm={4} md={3}>

                <InputText
                  dados={dados}
                  field='cnpj'
                  label='CNPJ'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={18}
                  mask='cnpj'
                />

              </Grid>

              <Grid item xs={12} sm={8} md={9} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={12} sm={3} md={2}>

                <InputText
                  dados={dados}
                  field='cep'
                  label='Cep'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  mask='cep'
                  onChange={( novoCep ) => alterarCep( novoCep )}
                />

              </Grid>

              <Grid item xs={12} sm={9} md={10} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='endereco'
                  label='Endereço'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} md={5}>

                <InputText
                  dados={dados}
                  field='bairro'
                  label='Bairro'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={12} sm={9} md={5} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='cidade'
                  label='Cidade'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={35}
                />

              </Grid>

              <Grid item xs={12} sm={3} md={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='uf'
                  label='UF'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  mask='uf'
                  tipo='uppercase'
                />

              </Grid>

              <Grid item xs={12} sm={4}>

                <InputText
                  dados={dados}
                  field='codigoSiafi'
                  label='Siafi'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={11}
                  mask='0000'
                />

              </Grid>
              <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='proximaAmostraOutros'
                  label='Outros'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={11}
                  tipo='number'
                  mask='00-00.00000'
                  unmask
                />

              </Grid>

              <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='proximaAmostraSolo'
                  label='Solo'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={11}
                  tipo='number'
                  mask='00-00.00000'
                  unmask
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              {JSON.stringify( dados )}
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}