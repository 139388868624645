import React from 'react'
import Condicional from '../../../Layout/Condicional'
import { StatusForm } from './Usuarios'
import { Button, Grid } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { UsuarioInterface } from '../../../ImportBackend/Interfaces/UsuarioInterfaces'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface PropsInterface {
  statusForm: StatusForm
  dados: UsuarioInterface
  setDados: React.Dispatch<React.SetStateAction<UsuarioInterface>>
  erros: { [key: string]: string }
  btCancelar: ( arg?: any ) => void
  btConfirmar: ( arg?: any ) => void
  btConfirmarExclusao: ( arg?: any ) => void
}

export default function UsuariosForm ( {
  statusForm,
  dados,
  setDados,
  erros,
  btCancelar,
  btConfirmar,
  btConfirmarExclusao
}: PropsInterface ) {

  return (
    <Condicional condicao={[StatusForm.Incluindo, StatusForm.Editando, StatusForm.Excluindo].includes( statusForm )}>

      <Grid item xs={12} sm={10}>
        <InputText
          dados={dados}
          field='nome'
          label='Nome'
          setState={setDados}
          disabled={statusForm === StatusForm.Excluindo}
          erros={erros}
          maxLength={40}
        />
      </Grid>

      <Grid item xs={12} sm={2} sx={{ pl: { sm: 2 } }}>
        <InputText
          dados={dados}
          field='ativo'
          label='Ativo'
          setState={setDados}
          tipo='checkbox'
          disabled={statusForm === StatusForm.Excluindo}
          erros={erros}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputText
          dados={dados}
          field='login'
          label='Login'
          setState={setDados}
          disabled={statusForm === StatusForm.Excluindo}
          erros={erros}
          maxLength={30}
          mask='llllllllllllllllllllllllllllll'
        />
      </Grid>

      <Grid item xs={12} sm={6} sx={{ pl: { sm: 2 } }}>
        <InputText
          dados={dados}
          field='whatsApp'
          label='Whats App'
          setState={setDados}
          disabled={statusForm === StatusForm.Excluindo}
          erros={erros}
          maxLength={15}
          mask='tel'
        />
      </Grid>

      <Grid item xs={12} sm={10}>
        <InputText
          dados={dados}
          field='email'
          label='e-mail'
          setState={setDados}
          disabled={statusForm === StatusForm.Excluindo}
          erros={erros}
          maxLength={255}
        />
      </Grid>

      <Grid item xs={12} sm={2} sx={{ pl: { sm: 2 } }}>
        <InputText
          dados={dados}
          field='usuarioMatriz'
          label='Matriz'
          setState={setDados}
          tipo='checkbox'
          disabled={statusForm === StatusForm.Excluindo}
          erros={erros}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>

        <Condicional condicao={statusForm === StatusForm.Excluindo}>
          <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarExclusao()}>Excluir</Button>
        </Condicional>

        <Condicional condicao={statusForm !== StatusForm.Excluindo}>
          <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
        </Condicional>

        <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelar()}>Cancelar</Button>

      </Grid>

    </Condicional>

  )

}