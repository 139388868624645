import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { UsuarioInterface } from '../../ImportBackend/Interfaces/UsuarioInterfaces'
import Condicional from '../../Layout/Condicional'
import { StatusForm } from './Sistema/Usuarios'
import CloseIcon from '@mui/icons-material/Close'
import ShowText from '../../DevComponents/ShowText'
import { FilialInterface } from '../../ImportBackend/Interfaces/FilialInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import SearchText from '../../DevComponents/SearchText'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import Typography from '@mui/material/Typography'

interface PropsInterface {
  statusForm: StatusForm
  dados: UsuarioInterface
  clsApi: BackEndAPI
  onCancelar: () => void
}

export default function UsuariosFiliais ( { clsApi, statusForm, dados, onCancelar }: PropsInterface ) {

  const [rsFiliais, setRsFiliais] = useState<Array<FilialInterface>>( [] )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    }
  ]

  const btExcluirFilial = ( grupo: FilialInterface ) => {

    const mutation = `
        excluirFilialUsuario(idUsuario: ${dados.idUsuario}, idFilial: ${grupo.idFilial}) {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirFilialUsuario', 'Excluindo Filial...', contexto ).then( rs => {

      if ( rs.ok ) {

        pesquisarUsuarioFiliais()

      } else {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const pesquisarUsuarioFiliais = () => {
    const query = `
      getUsuarioFilialsPermitidasPorIdUsuario(idUsuario: ${dados.idUsuario}) {
        idFilial
        descricao
      }
    `

    clsApi.query<Array<FilialInterface>>( query, 'getUsuarioFilialsPermitidasPorIdUsuario', 'Recebendo Filiais...', contexto ).then( rsFiliais => {

      setRsFiliais( rsFiliais )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Filiais de Usuários!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {

    if ( [StatusForm.EditandoFiliais].includes( statusForm ) ) {

      pesquisarUsuarioFiliais()

    }

    //eslint-disable-next-line
  }, [dados, statusForm] )

  const [novoFilial, setNovoFilial] = useState<FilialInterface | null>( null )

  const novoFiliaiselecionado = ( v: FilialInterface | null ) => {

    setNovoFilial( v )

    if ( v ) {

      const mutation = `
        incluirFilialUsuario(idUsuario: ${dados.idUsuario}, idFilial: ${v.idFilial}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirFilialUsuario', 'Incluindo Filial...', contexto ).then( rs => {

        if ( rs.ok ) {

          pesquisarUsuarioFiliais()

        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    }

  }

  return (
    <>
      <Condicional condicao={[StatusForm.EditandoFiliais].includes( statusForm )}>
        <Grid item xs={12}>
          <ShowText dados={dados} field='nome' label='Nome' />
        </Grid>

        <Grid item xs={12}>

          <SearchText
            onChange={novoFiliaiselecionado}
            objQuery='{idFilial, descricao}'
            campoPesquisa='descricao'
            campoID='idFilial'
            label='Adicionar Filial'
            value={novoFilial}
            mensagemQuery='Procurando Filiais...'
            campoQuery='pesquisa'
            nomeQuery='getFiliais'
          />

        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>

          <Typography component="h6" variant="h6" align="left">
            Filiais Ativos Para Este Usuário
          </Typography>

          <DataTable
            dados={rsFiliais}
            cabecalho={Cabecalho}
            acoes={[{ icone: 'delete', toolTip: 'Cancelar Acesso', onAcionador: btExcluirFilial }]}
          />

        </Grid>

        <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>

          <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

        </Grid>

      </Condicional>
    </>
  )

}