import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { AnaliseDeterminacaoInterface, AnaliseInterface } from '../../ImportBackend/Interfaces/AnaliseInterfaces'
import ClsCrud from '../../Utils/ClsCrud'

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { MaterialInterface } from '../../ImportBackend/Interfaces/MaterialInterfaces'
import AnalisesDeterminacoes from './AnalisesDeterminacoes'

interface PesquisaInterface {
  descricao: string
}

export default function Analises () {

  const [keyRefreshSelecao, setKeyRefreshSelecao] = useState( 0 )

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'Material',
      cabecalho: 'Material',
      alinhamento: 'left',
      format: ( v: MaterialInterface ) => v.descricao
    },
    {
      campo: 'ativo',
      cabecalho: 'Ativo',
      alinhamento: 'left',
      format: ( v: boolean ) => { return v ? 'Sim' : 'Não' }
    }
  ]

  const ResetDados: AnaliseInterface = {
    idAnalise: 0,
    descricao: '',
    ativo: false,
    idMaterial: 0,
    Determinacoes: []
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Análise',
    [StatusForm.Excluindo]: 'Exclusão de Análise Não Utilizada',
    [StatusForm.Pesquisando]: 'Análises são utilizadas para Tabela de Preços',
    [StatusForm.Editando]: 'Alteração de Dados de Análise',
    [StatusForm.Exibindo]: 'Dados da Análise',
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<AnaliseInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<AnaliseInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idMaterial', dados, erros, retorno )

    setErros( erros )

    return retorno
  }

  const clsCrud: ClsCrud<AnaliseInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateAnalise',
      excluirMutation: 'delAnalise',
      campoId: 'idAnalise',
      camposPesquisa: '{ idAnalise idMaterial descricao ativo Material { idMaterial, descricao } }',
      pesquisaQuery: 'getAnalises',
      pesquisaPorId: 'getAnalisePorId',
      camposPesquisaPorId: '{idAnalise descricao ativo idMaterial Determinacoes { idDeterminacao }}'
    },

    {
      confirmando: 'Atualizando Análise',
      erroCadastro: 'Erro ao Cadastrar Análise',
      erroExclusao: 'Erro ao Excluir Análise',
      erroPesquisa: 'Erro ao Pesquisar Análise',
      pesquisando: 'Pesquisando Dados de Análises...',
      sucessoCadastro: 'Análise Cadastrada com sucesso!',
      atualizacaoSucesso: 'Análise Atualizada com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Análise Excluída com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Análise...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const resetDeterminacoesPorAlteracaoMaterial = ( v: MaterialInterface ) => {
    // console.log('Dentro do Reset...')
    if ( !dados || !v || dados.idMaterial !== v.idMaterial ) {
      const idMaterial: number = v && v.idMaterial ? v.idMaterial : 0
      setDados( { ...dados, Determinacoes: [], idMaterial: idMaterial } )
    }
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Análises
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>
              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={6}>

                <PesquisarTabela<MaterialInterface>
                  onChange={( v ) => resetDeterminacoesPorAlteracaoMaterial( v )}
                  setState={setDados}
                  field='idMaterial'
                  fieldSet='idMaterial'
                  label='Material'
                  dados={dados}
                  campoQueryPesquisaID='idMaterial'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idMaterial, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getMateriais'
                  nomeQueryPesquisaID='getMaterialPorId'
                  mensagemPesquisa='Procurando Materiais...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={6} sx={{ pl: 2, mt: 4 }}>

                <InputText
                  dados={dados}
                  field='ativo'
                  label='Ativo'
                  setState={setDados}
                  tipo='checkbox'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                />

              </Grid>

              <Condicional condicao={[StatusForm.Incluindo, StatusForm.Editando, StatusForm.Excluindo].includes( statusForm )}>
                <AnalisesDeterminacoes
                  refresh={keyRefreshSelecao}
                  idMaterial={dados.idMaterial}
                  Determinacoes={dados.Determinacoes}
                  onChange={( Determinacoes: Array<AnaliseDeterminacaoInterface> ) => { setDados( { ...dados, Determinacoes: Determinacoes } ); setKeyRefreshSelecao( keyRefreshSelecao + 1 ) }}
                  statusForm={statusForm}
                />
              </Condicional>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              {JSON.stringify( dados )}
              {JSON.stringify( erros )}
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}