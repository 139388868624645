import React from 'react'
import { RsOrdemServicoAtualInterface } from './OrdensServicos'
import { Box, Grid, IconButton, OutlinedInput, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material'

interface PropsInterface {
  rsOrdemServicoAtual: RsOrdemServicoAtualInterface
  onClose: () => void
}

export default function ViewOrdemServicoAtual ( { rsOrdemServicoAtual, onClose }: PropsInterface ) {

  const theme = useTheme()

  const DisplayField = ( { label, campo }: { label: string, campo: string | number } ) => <>
    <Box component="span" >
      <Typography
        variant='body2'
        textAlign='left'
        sx={{ mt: theme && theme.inputs && theme.inputs.marginTop ? theme.inputs.marginTop : 0 }}
      >
        {label}
      </Typography>

      <OutlinedInput
        disabled
        value={campo}
        size="small"
        sx={{ textAlign: 'left', width: '100%' }}
      />

    </Box>
  </>

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'right', padding: 0, margin: 0, marginBottom: -3 }}>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
            <Grid item xs={4} sm={2} md={2}>
              <DisplayField label='OS' campo={rsOrdemServicoAtual.idOrdemServico} />
            </Grid>
            <Grid item xs={8} sm={3} md={3} sx={{ pl: 1 }}>
              <DisplayField label='Filial' campo={rsOrdemServicoAtual.filial} />
            </Grid>
            <Grid item xs={9} sm={5} md={6} sx={{ pl: { sm: 1 } }}>
              <DisplayField label='Remetente' campo={rsOrdemServicoAtual.remetente} />
            </Grid>
            <Grid item xs={3} sm={2} md={1} sx={{ pl: 1 }}>
              <DisplayField label='Qtd' campo={rsOrdemServicoAtual.qtdAmostras} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

}